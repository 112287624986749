import React, { Component } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Button,
  UncontrolledAlert,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable } from "mdbreact";
import Switch from "react-switch";
import SweetAlert from "react-bootstrap-sweetalert";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import HttpClient from "./../../utils/HttpClient";
class FormElements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Admin", link: "#" },
        { title: "Add & Manage Admin", link: "#" },
      ],
      customchk: true,
      toggleSwitch: true,
      // page states
      name: "",
      email: "",
      mobile: "",
      password: "",
      data: [],
      alert: false,
      message: "",
      type: "",
      result: [],
      edit_enable: false,
      edit_item_id: "",
      add_report: false,
      edit_report: false,
      delete_report: false,
      switch1: true,
      confirm_both: false,
      delete_item: {},
      delete_index: "",
    };
  }

  componentDidMount = async () => {
    this.fetchData();
  };

  fetchData = async () => {
    let result = await HttpClient.requestData("admin", "GET");
    if (result && result.status > 0) {
      let data = [];
      let i = 1;
      this.setState({ result: result.data });
      this.state.result.forEach((element, index) => {
        let rows = {
          sl: i,
          name: element.name,
          email: element.email,
          mobile: element.mobile,
          role: (
            <>
              {element.add_report && element.add_report ? (
                <button title="Add Report" className="btn btn-success mr-2">
                  Add
                </button>
              ) : null}
              {element.edit_report && element.edit_report ? (
                <button title="Edit Report" className="btn btn-info mr-2">
                  Edit
                </button>
              ) : null}
              {element.delete_report && element.delete_report ? (
                <button title="Delete Report" className="btn btn-danger mr-2">
                  Delete
                </button>
              ) : null}
            </>
          ),
          status: (
            <Switch
              uncheckedIcon={this.Offsymbol("Off")}
              checkedIcon={this.OnSymbol("On")}
              onColor="#626ed4"
              onChange={() => {
                this.status(element, index);
              }}
              checked={element.status}
              className="mr-1 mt-1"
            />
          ),
          action: (
            <>
              <button
                title="Are you sure you want to delete?"
                className="btn btn-danger mr-2"
                onClick={() => {
                  this.setState({
                    confirm_both: true,
                    delete_item: element,
                    delete_index: index,
                  });
                }}
              >
                <i className="fa fa-trash" />
              </button>
              <button
                title="Edit"
                className="btn btn-primary"
                onClick={() => {
                  this.edit(element, index);
                }}
              >
                <i className="fa fa-edit" />
              </button>
            </>
          ),
        };
        i++;
        data.push(rows);
      });
      this.setState({
        data: data,
      });
    } else {
      this.setState({
        data: [],
      });
    }
  };
  delete = async (item, index) => {
    let sendData = {
      id: item._id,
    };
    let result = await HttpClient.requestData("admin/delete", "POST", sendData);
    if (result && result.status) {
      let index = this.state.result.indexOf(item);
      if (index > -1) {
        this.state.result.splice(index, 1);
        this.setState({
          alert: true,
          message: "Deleted Successfully",
          type: "success",
          confirm_both: false,
          delete_item: {},
          delete_index: "",
        });
        setTimeout(() => {
          this.setState({
            alert: false,
            message: "",
            type: "",
          });
        }, 3000);
        this.fetchData();
      }
    }
  };
  edit = async (item, index) => {
    this.setState({
      edit_enable: true,
      name: item.name,
      email: item.email,
      mobile: item.mobile,
      password: "",
      edit_item_id: item._id,
      add_report: item.add_report,
      edit_report: item.edit_report,
      delete_report: item.delete_report,
    });
  };
  status = async (item, index) => {
    let sendData = {
      id: item._id,
      status: !item.status,
    };
    let result = await HttpClient.requestData("admin/status", "POST", sendData);
    if (result && result.status) {
      this.state.result.splice(index, 1);
      this.setState({
        alert: true,
        message: "Status Updated Succfully",
        type: "success",
      });
      setTimeout(() => {
        this.setState({
          alert: false,
          message: "",
          type: "",
        });
      }, 3000);
      this.fetchData();
    }
  };
  submit = async () => {
    if (this.state.edit_enable) {
    } else {
      if (this.state.password != "") {
      } else {
        this.setState({
          alert: true,
          message: "Please Enter Password",
          type: "warning",
        });
        return;
      }
    }

    if (
      this.state.name != "" &&
      this.state.email != "" &&
      this.state.mobile != ""
    ) {
      let data = null;
      let result = null;

      if (this.state.edit_enable == false) {
        data = {
          name: this.state.name,
          email: this.state.email,
          mobile: this.state.mobile,
          password: this.state.password,
          add_report: this.state.add_report,
          edit_report: this.state.edit_report,
          delete_report: this.state.delete_report,
        };
        result = await HttpClient.requestData("admin/register", "POST", data);
      } else {
        data = {
          name: this.state.name,
          email: this.state.email,
          mobile: this.state.mobile,
          password: this.state.password,
          add_report: this.state.add_report,
          edit_report: this.state.edit_report,
          delete_report: this.state.delete_report,
          id: this.state.edit_item_id,
        };
        result = await HttpClient.requestData("admin/update", "POST", data);
      }
      console.log("result", result);
      if (result && result.status) {
        this.setState({
          alert: true,
          message: this.state.edit_enable
            ? "Sub Admin Updated Successfully"
            : "Sub Admin Added Successfully",
          type: "success",
          name: "",
          email: "",
          mobile: "",
          password: "",
          edit_enable: false,
          edit_item_id: "",
          add_report: false,
          edit_report: false,
          delete_report: false,
        });

        this.fetchData();
      } else {
        this.setState({
          alert: true,
          message:
            typeof result.error == "string"
              ? result.error
              : result.error[0].msg,
          type: "danger",
        });
      }
    } else {
      this.setState({
        alert: true,
        message: "Please Fill Up All Details.",
        type: "warning",
      });
    }
    setTimeout(() => {
      this.setState({
        alert: false,
        message: "",
        type: "",
      });
    }, 3000);
  };

  Offsymbol(text) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        {text}
      </div>
    );
  }

  OnSymbol(text) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        {text}
      </div>
    );
  }

  render() {
    const data = {
      columns: [
        {
          label: "Sl.",
          field: "sl",
          sort: "asc",
          width: 150,
        },
        {
          label: "Name",
          field: "name",
          sort: "asc",
          width: 270,
        },
        {
          label: "Email",
          field: "email",
          sort: "asc",
          width: 270,
        },
        {
          label: "Mobile",
          field: "mobile",
          sort: "asc",
          width: 150,
        },
        {
          label: "Privilege/Role",
          field: "role",
          sort: "asc",
          width: 150,
        },
        {
          label: "Status",
          field: "status",
          sort: "asc",
          width: 100,
        },
        {
          label: "Action",
          field: "action",
          sort: "asc",
          width: 100,
        },
      ],
      rows: this.state.data,
    };

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.state.breadcrumbItems[0].title}
              breadcrumbItems={this.state.breadcrumbItems}
            />

            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    {this.state.alert ? (
                      <UncontrolledAlert
                        color={this.state.type}
                        className="alert-dismissible fade show"
                        role="alert"
                      >
                        {this.state.type == "warning" ? (
                          <i className="mdi mdi-alert-outline mr-2"></i>
                        ) : this.state.type == "success" ? (
                          <i className="mdi mdi-check-all mr-2"></i>
                        ) : this.state.type == "danger" ? (
                          <i className="mdi mdi-block-helper mr-2"></i>
                        ) : null}
                        {this.state.message}
                      </UncontrolledAlert>
                    ) : null}
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Name
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="text"
                          value={this.state.name}
                          id="example-text-input"
                          onChange={(val) => {
                            this.setState({ name: val.target.value });
                          }}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Email
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="text"
                          value={this.state.email}
                          id="example-text-input"
                          onChange={(val) => {
                            this.setState({ email: val.target.value });
                          }}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Mobile
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="text"
                          value={this.state.mobile}
                          id="example-text-input"
                          onChange={(val) => {
                            this.setState({ mobile: val.target.value });
                          }}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-search-input"
                        className="col-md-2 col-form-label"
                      >
                        Password
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="text"
                          value={this.state.password}
                          id="example-text-input"
                          onChange={(val) => {
                            this.setState({ password: val.target.value });
                          }}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Col md={5}>
                        <div>
                          <h5 className="font-size-14 mb-4">Privilege/Role</h5>
                          <div className="form-check mb-3">
                            <Input
                              className="form-check-input"
                              type="checkbox"
                              value={this.state.add_report}
                              id="defaultCheck1"
                              onChange={() => {
                                this.setState({
                                  add_report: !this.state.add_report,
                                });
                              }}
                              checked={this.state.add_report}
                            />
                            <Label
                              className="form-check-label"
                              htmlFor="defaultCheck1"
                            >
                              Add Report
                            </Label>
                          </div>
                          <div className="form-check mb-3">
                            <Input
                              className="form-check-input"
                              type="checkbox"
                              value={this.state.edit_report}
                              id="defaultCheck1"
                              onChange={() => {
                                this.setState({
                                  edit_report: !this.state.edit_report,
                                });
                              }}
                              checked={this.state.edit_report}
                            />
                            <Label
                              className="form-check-label"
                              htmlFor="defaultCheck1"
                            >
                              Edit Report
                            </Label>
                          </div>
                          <div className="form-check mb-3">
                            <Input
                              className="form-check-input"
                              type="checkbox"
                              value={this.state.delete_report}
                              id="defaultCheck1"
                              onChange={() => {
                                this.setState({
                                  delete_report: !this.state.delete_report,
                                });
                              }}
                              checked={this.state.delete_report}
                            />
                            <Label
                              className="form-check-label"
                              htmlFor="defaultCheck1"
                            >
                              Delete Report
                            </Label>
                          </div>
                        </div>
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-0">
                      <div className="button-items pt-4">
                        <Button
                          color="primary"
                          type="button"
                          className="waves-effect waves-light mr-1"
                          onClick={() => {
                            this.submit();
                          }}
                        >
                          {this.state.edit_enable ? "Update" : "Submit"}{" "}
                          <i className="ri-arrow-right-line align-middle ml-1"></i>
                        </Button>
                      </div>
                    </FormGroup>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col xl={12}>
                {this.state.confirm_both ? (
                  <SweetAlert
                    title="Are you sure?"
                    warning
                    showCancel
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    onConfirm={() =>
                      this.delete(
                        this.state.delete_item,
                        this.state.delete_index
                      )
                    }
                    onCancel={() =>
                      this.setState({
                        confirm_both: false,

                      })
                    }
                  >
                    You won't be able to revert this!
                  </SweetAlert>
                ) : null}
                <div className="card p-3">
                  <MDBDataTable responsive bordered data={data} />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default FormElements;
