import React, { useState } from 'react'
import HttpClient from '../../utils/HttpClient'

function TextFeild({defaultvalue="",id=""}) {
    const [value, setValue] = useState(defaultvalue)
    const updateAddbY = async () => {
        let data = {
            add_by: value,
            admin_id: 2103041846164616,
            edit_id:id ,
        }
        console.log(data);
        let result = await HttpClient.requestData("admin/report/update",
            "POST", data);
            console.log(result);
    }
    return (
        <input onBlur={updateAddbY} value={value} onChange={(val) => setValue(val.target.value)} type="text" />)
}

export default TextFeild