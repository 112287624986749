import  firebase1 from 'firebase/app';
import firestore from 'firebase/firestore'
import database from 'firebase/database';
const settings = {timestampsInSnapshots: true};

const config = {
    apiKey: "AIzaSyCZVWKqLwcquClZVkNJ-C1sxjTznq-fSUA",
    authDomain: "tesdata-bbd09.firebaseapp.com",
    databaseURL: "https://tesdata-bbd09-default-rtdb.firebaseio.com",
    projectId: "tesdata-bbd09",
    storageBucket: "tesdata-bbd09.appspot.com",
    messagingSenderId: "854381275204",
    appId: "1:854381275204:web:985d8a344cec33b6567411",
    measurementId: "G-P094QESQ54"
  };
 
firebase1.initializeApp(config);
 

firebase1.database()

firebase1.firestore().settings(settings);

export default firebase1;