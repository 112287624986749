import React, { Component } from "react";
import "./transaction.css";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Button,
  UncontrolledAlert,
  Table,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable } from "mdbreact";

import SweetAlert from "react-bootstrap-sweetalert";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import HttpClient from "./../../utils/HttpClient";
import { Link } from "react-router-dom";


class FormElements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Transaction", link: "#" },
        { title: "Manage Transaction", link: "#" },
      ],
      modal: false,
      customchk: true,
      toggleSwitch: true,
      id: 0,
      userimage: "",
      username: "",
      chatlist: [],
      totalpage: 0,
      page_num: [],
      currentpage: this.props.match.params.id,
      alldata: [],
      dataperPage: 10,
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  noOfPages = async (pagecount) => {
    for (let i = 1; i <= Math.ceil(pagecount); i++) {
      console.log("Pages", i);
      this.state.page_num.push(i);
      this.setState({});
      console.log("read", this.state.page_num);
    }
  };

  fetchData = async () => {
    let cur_data = [];

    let result = await HttpClient.payStack(
      "/transaction?perPage=" +
      this.state.dataperPage +
      "&page=" +
      parseInt(this.state.currentpage),
      "GET"
    );
    console.log(result);
    if (result && result.status) {
      // let tr = result.data.filter(
      //   (it) => it.metadata.custom_fields[0].value == ""
      // );
      // console.log(tr);

      this.setState({
        totalpage: result.meta.total,
        alldata: result.data,
      });
      console.log("36", this.state.totalpage);
      console.log(this.state.dataperPage);
      let pagecount = this.state.totalpage / this.state.dataperPage;
      console.log(pagecount);
      this.noOfPages(pagecount);
    }
  };

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.state.breadcrumbItems[0].title}
              breadcrumbItems={this.state.breadcrumbItems}
            />

            <Row>
              <Col xs={12}>
                {this.state.confirm_both ? (
                  <SweetAlert
                    title="Are you sure?"
                    warning
                    showCancel
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    onConfirm={() =>
                      this.delete(
                        this.state.delete_item,
                        this.state.delete_index
                      )
                    }
                    onCancel={() =>
                      this.setState({
                        confirm_both: false,
                      })
                    }
                  >
                    You won't be able to revert this!
                  </SweetAlert>
                ) : null}
                {this.state.alert ? (
                  <UncontrolledAlert
                    color={this.state.type}
                    className="alert-dismissible fade show"
                    role="alert"
                  >
                    {this.state.type == "warning" ? (
                      <i className="mdi mdi-alert-outline mr-2"></i>
                    ) : this.state.type == "success" ? (
                      <i className="mdi mdi-check-all mr-2"></i>
                    ) : this.state.type == "danger" ? (
                      <i className="mdi mdi-block-helper mr-2"></i>
                    ) : null}
                    {this.state.message}
                  </UncontrolledAlert>
                ) : null}
              </Col>
            </Row>
            <Row>
              <Col xl={12}>
                <div className="card p-3">
                  <Table bordered responsive>
                    <thead>
                      <tr>
                        <th>Sl no</th>
                        <th>Currency</th>
                        <th>Payment type</th>
                        <th>Amount</th>
                        <th>First name</th>
                        <th>Last name</th>
                        <th>Email</th>
                        <th>Date Stamp</th>
                      </tr>
                    </thead>

                    <tbody>
                      {this.state.alldata.map((item, index) => (
                        <tr>
                          <th scope="row">
                            {this.state.currentpage}.{++index}
                          </th>
                          <td>USD</td>
                          <td>{item.channel}</td>
                          <td>${item.amount / 100}</td>
                          
                          <td>
                            {item.metadata.custom_fields[0].display_fname}
                          </td>
                          <td>
                            {item.metadata.custom_fields[0].display_lname}
                          </td>
                          <td>{item.customer.email}</td>
                          <td>{moment(item.created_at).format("MM-DD-YYYY")}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>

                <div
                  className="myPegination text-center
           "
                >
                  <div className="d-flex " style={{overflow:"scroll"}}>
                    {parseInt(this.state.currentpage) > 1 ? (
                      <button
                        type="button"
                        className="peg_prev"
                        onClick={() => {
                          let page = parseInt(this.state.currentpage) - 1;
                          this.state.currentpage = page.toString();
                          this.setState({});
                          window.location.href =
                            "/transaction" + this.state.currentpage;
                        }}
                      >
                        Previous
                      </button>
                    ) : null}
                    {this.state.page_num.map((item, index) => (
                      <button
                        className={
                          this.state.currentpage == item
                            ? "page_number"
                            : "peg_number"
                        }
                        key={index}
                        onClick={() => {
                          window.location.href = "/transaction" + item;
                        }}
                      >
                        {item}
                      </button>
                    ))}
                    {/* <button className="peg_number active">2</button>
              <button className="peg_number">--</button>
              <button className="peg_number">10</button> */}
                    {parseInt(this.state.currentpage) ==
                      this.state.page_num.length ? null : (
                      <button
                        type="button"
                        className="peg_next"
                        onClick={() => {
                          let page = parseInt(this.state.currentpage) + 1;
                          this.state.currentpage = page.toString();
                          this.setState({});
                          window.location.href =
                            "/transaction" + this.state.currentpage;
                        }}
                      >
                        Next
                      </button>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default FormElements;
