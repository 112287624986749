import React, { useState } from 'react'
import moment from 'moment'
import HttpClient from '../../utils/HttpClient';

function StartDate({ defaultValue = "", id = "" }) {
    console.log("dd", defaultValue);
    const [value, setValue] = useState(defaultValue ? defaultValue: "")

    const updateDate = async () => {
        let date = {
            start_date: value,
            id:parseInt(id)
        }
        console.log(date);
        let result = await HttpClient.requestData("admin/subscription/update-sub", "POST", date)
        console.log(result)
    }
    return (
        <input type="date" onBlur={updateDate} value={value} onChange={(val) => setValue(val.target.value)} />
    )
}

export default StartDate