import React, { Component } from "react";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { reactLocalStorage } from "reactjs-localstorage";

//i18n
import { withNamespaces } from "react-i18next";

import { connect } from "react-redux";
import {
  changeLayout,
  changeLayoutWidth,
  changeSidebarTheme,
  changeSidebarType,
  changePreloader,
} from "../../store/actions";
import HttpClient from "./../../utils/HttpClient";

class SidebarContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subadminMenu: [],
    };
  }

  componentDidMount() {
    let a = reactLocalStorage.getObject("adminData");
    console.log("sidebar", a);

    this.initMenu();
    this.fetchData();
  }
  fetchData = async () => {
    let data = {};
    let result = await HttpClient.requestData("admin/getsubadmin", "GET", data);
    console.log("result", result);
    if (result && result.status) {
      this.setState({
        subadminMenu: result.data,
      });
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      if (this.props.type !== prevProps.type) {
        this.initMenu();
      }
    }
  }

  initMenu() {
    new MetisMenu("#side-menu");

    var matchingMenuItem = null;
    var ul = document.getElementById("side-menu");
    var items = ul.getElementsByTagName("a");
    for (var i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem);
    }
  }

  activateParentDropdown = (item) => {
    item.classList.add("active");
    const parent = item.parentElement;

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show");

        const parent3 = parent2.parentElement;

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement;
          if (parent4) {
            parent4.classList.add("mm-active");
          }
        }
      }
      return false;
    }
    return false;
  };

  render() {
    return (
      <React.Fragment>
        {reactLocalStorage.getObject("adminData").type == "super" ? (
          <div id="sidebar-menu">
            <ul className="metismenu list-unstyled" id="side-menu">
              <li className="menu-title">{this.props.t("Menu")}</li>

              <li>
                <Link to="/dashboard" className="waves-effect">
                  <i className="ri-dashboard-line"></i>
                  {/* <span className="badge badge-pill badge-success float-right">
                  3
                </span> */}
                  <span className="ml-1">{this.props.t("Dashboard")}</span>
                </Link>
              </li>

              {/* <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="ri-mail-send-line"></i>
                  <span className="ml-1">{this.props.t("Admin")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/admin">
                      {this.props.t("Add & Manage Admin")}
                    </Link>
                  </li>
                </ul>
              </li> */}
              {/* <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="ri-mail-send-line"></i>
                  <span className="ml-1">{this.props.t("Sub Admin")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/sub-admin">
                      {this.props.t("Add & Manage Sub Admin")}
                    </Link>
                  </li>
                </ul>
              </li> */}

              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="ri-mail-send-line"></i>
                  {/* <span className="ml-1">{this.props.t("Price")}</span> */}
                  <span className="ml-1">Report Price</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/add-price">{this.props.t("Add Price")}</Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="ri-mail-send-line"></i>
                  <span className="ml-1">{this.props.t("Chats")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/chatlist">{this.props.t("ChatList")}</Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="ri-mail-send-line"></i>
                  <span className="ml-1">{this.props.t("Transaction History")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/transaction-history">
                      {this.props.t("Report History")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/subscription-history">
                      {this.props.t("Subscription History")}
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="ri-mail-send-line"></i>
                  <span className="ml-1">{this.props.t("Report")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/add-report">{this.props.t("Add Report")}</Link>
                  </li>
                  <li>
                    <Link to="/manage-report">
                      {this.props.t("Manage Report")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/update-image">
                      {this.props.t("Upload Image")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/update-video">
                      {this.props.t("Upload Video")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/csv-add-report">
                      {this.props.t("CSV Report Adding")}
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="ri-mail-send-line"></i>
                  <span className="ml-1">
                    {this.props.t("Subscription Plan")}
                  </span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/add-subscription">
                      {this.props.t("Manage Subscription Plan")}
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="ri-mail-send-line"></i>
                  <span className="ml-1">{this.props.t("UserDetails")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/user-details">
                      {this.props.t("Manage UserList")}
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="ri-mail-send-line"></i>
                  <span className="ml-1">
                    {this.props.t("Subscription History")}
                  </span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/subscription-list">
                      {this.props.t("Manage Subscription History")}
                    </Link>
                  </li>
                </ul>
              </li>
                <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="ri-mail-send-line"></i>
                  <span className="ml-1">
                    {this.props.t("Promo Code")}
                  </span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/promo">
                      {this.props.t("Manage Promo")}
                    </Link>
                  </li>
                </ul>
              </li>

              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="ri-mail-send-line"></i>
                  <span className="ml-1">
                    {this.props.t("Terms And Condition")}
                  </span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/terms&cond">
                      {this.props.t("Add Terms And Condition")}
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        ) : reactLocalStorage.getObject("adminData").type == "admin" ? (
          <div id="sidebar-menu">
            <ul className="metismenu list-unstyled" id="side-menu">
              <li className="menu-title">{this.props.t("Menu")}</li>

              <li>
                <Link to="/dashboard" className="waves-effect">
                  <i className="ri-dashboard-line"></i>

                  <span className="ml-1">{this.props.t("Dashboard")}</span>
                </Link>
              </li>

              {reactLocalStorage.getObject("adminData").admin_data ? (
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="ri-mail-send-line"></i>
                    <span className="ml-1">{this.props.t("Admin")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/admin">
                        {this.props.t("Add & Manage Admin")}
                      </Link>
                    </li>
                  </ul>
                </li>
              ) : null}
              {reactLocalStorage.getObject("adminData").sub_admin ? (
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="ri-mail-send-line"></i>
                    <span className="ml-1">{this.props.t("Sub Admin")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/sub-admin">
                        {this.props.t("Add & Manage Sub Admin")}
                      </Link>
                    </li>
                  </ul>
                </li>
              ) : null}
              {reactLocalStorage.getObject("adminData").add_price ? (
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="ri-mail-send-line"></i>
                    <span className="ml-1">{this.props.t("Price")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/add-price">{this.props.t("Add Price")}</Link>
                    </li>
                  </ul>
                </li>
              ) : null}
              {reactLocalStorage.getObject("adminData").chats ? (
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="ri-mail-send-line"></i>
                    <span className="ml-1">{this.props.t("Chats")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/chatlist">{this.props.t("ChatList")}</Link>
                    </li>
                  </ul>
                </li>
              ) : null}

              {reactLocalStorage.getObject("adminData").trans_hist ? (
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="ri-mail-send-line"></i>
                    <span className="ml-1">{this.props.t("Transaction")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/transaction-history">
                        {this.props.t("Transaction History")}
                      </Link>
                    </li>
                  </ul>
                </li>
              ) : null}
              {reactLocalStorage.getObject("adminData").report ? (
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="ri-mail-send-line"></i>
                    <span className="ml-1">{this.props.t("Report")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/add-report">{this.props.t("Add Report")}</Link>
                    </li>
                    <li>
                      <Link to="/manage-report">
                        {this.props.t("Manage Report")}
                      </Link>
                    </li>
                  </ul>
                </li>
              ) : null}
              {reactLocalStorage.getObject("adminData").subscription ? (
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="ri-mail-send-line"></i>
                    <span className="ml-1">
                      {this.props.t("Subscription Plan")}
                    </span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/add-subscription">
                        {this.props.t("Manage Subscription Plan")}
                      </Link>
                    </li>
                  </ul>
                </li>
              ) : null}
              {reactLocalStorage.getObject("adminData").userdetails ? (
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="ri-mail-send-line"></i>
                    <span className="ml-1">{this.props.t("UserDetails")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/user-details">
                        {this.props.t("Manage UserList")}
                      </Link>
                    </li>
                  </ul>
                </li>
              ) : null}
              {reactLocalStorage.getObject("adminData").sub_hist ? (
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="ri-mail-send-line"></i>
                    <span className="ml-1">
                      {this.props.t("Subscription History")}
                    </span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/subscription-list">
                        {this.props.t("Manage Subscription History")}
                      </Link>
                    </li>
                  </ul>
                </li>
              ) : null}
              {reactLocalStorage.getObject("adminData").terms ? (
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="ri-mail-send-line"></i>
                    <span className="ml-1">
                      {this.props.t("Terms And Condition")}
                    </span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/terms&cond">
                        {this.props.t("Add Terms And Condition")}
                      </Link>
                    </li>
                  </ul>
                </li>
              ) : null}
            </ul>
          </div>
        ) : (
          <div id="sidebar-menu">
            <ul className="metismenu list-unstyled" id="side-menu">
              <li className="menu-title">{this.props.t("Menu")}</li>

              <li>
                <Link to="/dashboard" className="waves-effect">
                  <i className="ri-dashboard-line"></i>

                  <span className="ml-1">{this.props.t("Dashboard")}</span>
                </Link>
              </li>

              {reactLocalStorage.getObject("adminData").admin_data ? (
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="ri-mail-send-line"></i>
                    <span className="ml-1">{this.props.t("Admin")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/admin">
                        {this.props.t("Add & Manage Admin")}
                      </Link>
                    </li>
                  </ul>
                </li>
              ) : null}
              {reactLocalStorage.getObject("adminData").sub_admin ? (
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="ri-mail-send-line"></i>
                    <span className="ml-1">{this.props.t("Sub Admin")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/sub-admin">
                        {this.props.t("Add & Manage Sub Admin")}
                      </Link>
                    </li>
                  </ul>
                </li>
              ) : null}
              {reactLocalStorage.getObject("adminData").add_price ? (
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="ri-mail-send-line"></i>
                    <span className="ml-1">{this.props.t("Price")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/add-price">{this.props.t("Add Price")}</Link>
                    </li>
                  </ul>
                </li>
              ) : null}
              {reactLocalStorage.getObject("adminData").chats ? (
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="ri-mail-send-line"></i>
                    <span className="ml-1">{this.props.t("Chats")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/chatlist">{this.props.t("ChatList")}</Link>
                    </li>
                  </ul>
                </li>
              ) : null}

              {reactLocalStorage.getObject("adminData").trans_hist ? (
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="ri-mail-send-line"></i>
                    <span className="ml-1">{this.props.t("Transaction")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/transaction-history">
                        {this.props.t("Transaction History")}
                      </Link>
                    </li>
                  </ul>
                </li>
              ) : null}
              {reactLocalStorage.getObject("adminData").report ? (
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="ri-mail-send-line"></i>
                    <span className="ml-1">{this.props.t("Report")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/add-report">{this.props.t("Add Report")}</Link>
                    </li>
                    <li>
                      <Link to="/manage-report">
                        {this.props.t("Manage Report")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/update-image">
                        {this.props.t("Upload Image")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/update-video">
                        {this.props.t("Upload Video")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/csv-add-report">
                        {this.props.t("CSV Report Adding")}
                      </Link>
                    </li>
                  </ul>
                </li>
              ) : null}
              {reactLocalStorage.getObject("adminData").subscription ? (
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="ri-mail-send-line"></i>
                    <span className="ml-1">
                      {this.props.t("Subscription Plan")}
                    </span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/add-subscription">
                        {this.props.t("Manage Subscription Plan")}
                      </Link>
                    </li>
                  </ul>
                </li>
              ) : null}
              {reactLocalStorage.getObject("adminData").userdetails ? (
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="ri-mail-send-line"></i>
                    <span className="ml-1">{this.props.t("UserDetails")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/user-details">
                        {this.props.t("Manage UserList")}
                      </Link>
                    </li>
                  </ul>
                </li>
              ) : null}
              {reactLocalStorage.getObject("adminData").sub_hist ? (
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="ri-mail-send-line"></i>
                    <span className="ml-1">
                      {this.props.t("Subscription History")}
                    </span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/subscription-list">
                        {this.props.t("Manage Subscription History")}
                      </Link>
                    </li>
                  </ul>
                </li>
              ) : null}
              {reactLocalStorage.getObject("adminData").terms ? (
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="ri-mail-send-line"></i>
                    <span className="ml-1">
                      {this.props.t("Terms And Condition")}
                    </span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/terms&cond">
                        {this.props.t("Add Terms And Condition")}
                      </Link>
                    </li>
                  </ul>
                </li>
                  ) : null}
                  
                 
            </ul>
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  return { ...state.Layout };
};

export default withRouter(
  connect(mapStatetoProps, {
    changeLayout,
    changeSidebarTheme,
    changeSidebarType,
    changeLayoutWidth,
    changePreloader,
  })(withNamespaces()(SidebarContent))
);
