import React, { Component } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Button,
  UncontrolledAlert,
} from "reactstrap";
import "./transaction.css";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable, MDBBtn } from "mdbreact";
import SweetAlert from "react-bootstrap-sweetalert";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import HttpClient from "./../../utils/HttpClient";
import { Link } from "react-router-dom";
import Paginator from "react-hooks-paginator";


export class Transaction2 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      breadcrumbItems: [
        { title: "Transaction", link: "#" },
        { title: "Manage Transaction", link: "#" },
      ],
      customchk: true,
      toggleSwitch: true,
      // page states
      name: "",
      desc: "",
      duration: "",
      price: "",
      data: [],
      alert: false,
      message: "",
      type: "",
      result: [],
      edit_enable: false,
      edit_item_id: "",
      image_select: false,
      img_url: "",
      category_type: "",
      confirm_both: false,
      delete_item: {},
      delete_index: "",
      new: [],
      totalpage: 0,
      page_num: [],
      currentpage: 1,
      alldata: [],
      dataperPage: 10,
      offset: 0,

    };
  }
  componentDidMount = async () => {
    // this.fetchData();
    this.fetchData2()
  };

  noOfPages = async (pagecount) => {
    for (let i = 1; i <= Math.ceil(pagecount); i++) {
      console.log("Pages", i);
      this.state.page_num.push(i);
      this.setState({});
      console.log("read", this.state.page_num);
    }
  };

  fetchData2 = async (page=1) => {
    // alert("k")
    let cur_data = [];

    let result = await HttpClient.payStack(
      "/transaction?perPage=" +
      this.state.dataperPage +
      "&page=" +
      parseInt(page),
      "GET"
    );
    console.log(result, "Ressss");
    if (result && result.status) {
      let data = [];
      let i = 1;
      this.setState({ result: result.data });
      this.setState({
        totalpage: result.meta.pageCount,
      });
      let pagecount = this.state.totalpage / this.state.dataperPage;
      console.log(pagecount);
      // this.noOfPages(pagecount);

      this.state.result.forEach((element, index) => {
        let rows = {
          sl: i,
          currency: "USD",
          Payment_type: element.channel,
          Amount: "$" + element.amount / 100,
          First_name: element.metadata.custom_fields[0].display_fname,

          Last_name: element.metadata.custom_fields[0].display_lname,
          Email: element.customer.email,
          Date_Stamp: moment(element.created_at).format("MM-DD-YYYY"),
        };
        i++;
        data.push(rows);
      });
      this.setState({
        data:[...this.state.data, ...data],
      });
      // let tr = result.data.filter(
      //   (it) => it.metadata.custom_fields[0].value == ""
      // );
      // console.log(tr);


    }
  }
  //   fetchData = async () => {
  //     let result = await HttpClient.requestData(
  //       "admin/subscription/subs-history",
  //       "GET"
  //     );
  //     console.log(result);

  //     if (result && result.status > 0) {
  //       console.log("SubscriptionResponse", result.data);
  //       let data = [];
  //       let i = 1;
  //       this.setState({ result: result.data });

  //       this.state.result.forEach((element, index) => {
  //         let rows = {
  //           sl: i,
  //           startDate:moment(element.created_on).format("MM/DD/YYYY"),
  //           endDate:element.expiredate ? element.expiredate!=="Invalid date"?moment(new Date(element.expiredate)).format("MM/DD/YYYY"):"Lifetime":"",
  //           fname: element.userData ? element.userData.firstname : "",
  //           lname: element.userData ? element.userData.lastname : "",

  //           email: element.userData ? element.userData.email : "",
  //           subplan_id: element.plan_id,
  //           name: element.planData ? element.planData.plan_name : "",
  //           subplan_price: "$" + element.price,
  //         };
  //         i++;
  //         data.push(rows);
  //       });
  //       this.setState({
  //         data: data,
  //       });
  //     } else {
  //       this.setState({
  //         data: [],
  //       });
  //     }
  //   };
  pageChange =(activepage)=>{
    // this.setState({cu})
    // alert("l")
    if(activepage<this.state.totalpage){
      this.fetchData2(activepage+1)
    }

  }
  render() {
    const data = {
      columns: [
        {
          label: "Sl.",
          field: "sl",
          sort: "asc",
          width: 150,
        },
        {
          label: "Currency",
          field: "currency",
          sort: "asc",
          width: 150,
        },
        {
          label: "Payment type",
          field: "Payment_type",
          sort: "asc",
          width: 150,
        },
        {
          label: "Amount",
          field: "Amount",
          sort: "asc",
          width: 270,
        },
        {
          label: "First name",
          field: "First_name",
          sort: "asc",
          width: 270,
        },

        {
          label: "Last name",
          field: "Last_name",
          sort: "asc",
          width: 270,
        },
        {
          label: "Email",
          field: "Email",
          sort: "asc",
          width: 270,
        },

        {
          label: "Date Stamp",
          field: "Date_Stamp",
          sort: "asc",
          width: 270,
        },
        // bank_ac
        // bvn
        // cert
        // dob
        // driver_license
        // email
        // emp_id
        // {
        //   label: "Action",
        //   field: "action",
        //   sort: "asc",
        //   width: 100,
        // },
      ],
      rows: this.state.data,
    };

  
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.state.breadcrumbItems[0].title}
              breadcrumbItems={this.state.breadcrumbItems}
            />

            <Row>
              <Col xs={12}>
                {this.state.confirm_both ? (
                  <SweetAlert
                    title="Are you sure?"
                    warning
                    showCancel
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    onConfirm={() =>
                      this.delete(
                        this.state.delete_item,
                        this.state.delete_index
                      )
                    }
                    onCancel={() =>
                      this.setState({
                        confirm_both: false,
                      })
                    }
                  >
                    You won't be able to revert this!
                  </SweetAlert>
                ) : null}
                {this.state.alert ? (
                  <UncontrolledAlert
                    color={this.state.type}
                    className="alert-dismissible fade show"
                    role="alert"
                  >
                    {this.state.type == "warning" ? (
                      <i className="mdi mdi-alert-outline mr-2"></i>
                    ) : this.state.type == "success" ? (
                      <i className="mdi mdi-check-all mr-2"></i>
                    ) : this.state.type == "danger" ? (
                      <i className="mdi mdi-block-helper mr-2"></i>
                    ) : null}
                    {this.state.message}
                  </UncontrolledAlert>
                ) : null}
              </Col>
            </Row>
            <Row>
              <Col xl={12}>
                <div className="card p-3">
                  <MDBDataTable
                    entriesOptions={[10]}
                    // entries={10}
                    paging={true}
                    // pagesAmount={100}
                    onPageChange={(e) =>this.pageChange(e.pagesAmount)}
                    responsive bordered data={data}
                    entries={9}
                    
                    />
                  
                

                </div>

              </Col>
            </Row>
          </Container>
          {/* <h2>hello</h2> */}
        </div>
      </React.Fragment>
    );
  }
}

export default Transaction2;
