import { MDBDataTable } from "mdbreact";
import React from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Button,
  UncontrolledAlert,
} from "reactstrap";
import HttpClient from "../../utils/HttpClient";
import SweetAlert from "react-bootstrap-sweetalert";
import Breadcrumbs from "../../components/Common/Breadcrumb";


class PromoCreate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        breadcrumbItems: [
        { title: "Promo Code", link: "#" },
        { title: "Manage Promocode", link: "#" },
      ],
      name: "",
      percentage: "",
      startDate: "",
      endDate: "",
      editEnabled: false,
      editId: "",
      allCoupons: [],
      alert: false,
      message: "",
      type: "",
      confirm_both: false,
      deleteId: "",
      minDate:
        new Date().getFullYear() +
        "-" +
        parseInt(new Date().getMonth() + 1) +
        "-" +
        new Date().getDate(),
    };
  }

  handleSubmit = async () => {
    if (
      this.state.name != "" &&
      this.state.percentage != "" &&
      this.state.startDate != "" &&
      this.state.endDate != ""
    ) {
      if (this.state.startDate > this.state.endDate) {
        this.setState({
          alert: true,
          message: "Start Date should be less than End Date.",
          type: "warning",
        });
        return
      }

      let data = {
        name: this.state.name,
        percent: Number(this.state.percentage),
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        id: this.state.editId != "" ? this.state.editId : undefined,
      };

      let result;

      if (this.state.editEnabled === true) {
        result = await HttpClient.requestData(
          "admin/updateCoupon",
          "POST",
          data
        );
      } else {
        result = await HttpClient.requestData("admin/addcoupon", "POST", data);
        console.log(result);
      }

      if (result && result.status) {
        this.setState({
          alert: true,
          message: this.state.editEnabled
            ? "Coupon Updated Successfully"
            : "Coupon Updated Successfully",
          type: "success",
          editEnabled: false,
          name: "",
          percentage: "",
          startDate: "",
          endDate: "",
          editId: "",
        });

        this.fetchPromo();
      }
      // console.log("addcoupon", result);
    } else {
      this.setState({
        alert: true,
        message: "Please Fill Up All Details.",
        type: "warning",
      });
    }

    setTimeout(() => {
      this.setState({
        alert: false,
        message: "",
        type: "",
      });
    }, 3000);
  };

  componentDidMount() {
    this.fetchPromo();
  }

  fetchPromo = async () => {
    let result = await HttpClient.requestData("admin/getCoupon", "POST", {});
    if (result && result.status) {
      let arr = result.data.map((item, i) => {
        return {
          ...item,
          sl: i + 1,
          name: item.name,
          percent: item.percent,
          action: (
            <>
              <button
                title="Edit"
                className="btn btn-primary"
                onClick={() => {
                  window.scroll(0, 0);

                  this.setState({
                    editEnabled: true,
                    editId: item.id,
                    name: item.name,
                    percentage: item.percent,
                    startDate: item.startDate,
                    endDate: item.endDate,
                  });
                }}
              >
                <i className="fa fa-edit" />
              </button>
              <button
                title="Are you sure you want to delete?"
                className="btn btn-danger mr-2"
                onClick={() => {
                  this.setState({
                    confirm_both: true,
                    deleteId: item.id,
                    // delete_item: element,
                    // delete_index: index,
                  });
                }}
              >
                <i className="fa fa-trash" />
              </button>
            </>
          ),
        };
      });

      this.setState({ allCoupons: arr });
    } else {
      this.setState({ allCoupons: [] });
    }

    console.log("coupon fetched", result);
  };

  delete = async () => {
    let sendData = {
      id: this.state.deleteId,
    };
    let result = await HttpClient.requestData(
      "admin/deleteCoupon",
      "POST",
      sendData
    );
    if (result && result.status) {
      this.fetchPromo();

      this.setState({
        alert: true,
        message: "Deleted Successfully.",
        type: "success",
        confirm_both: false,
      });

      setTimeout(() => {
        this.setState({
          alert: false,
          message: "",
          type: "",
          deleteId: "",
        });
      }, 3000);
    }
  };

  render() {
    const data = {
      columns: [
        {
          label: "Sl.",
          field: "sl",
          sort: "asc",
          width: 150,
        },
        {
          label: "Name",
          field: "name",
          sort: "asc",
          width: 270,
        },

        {
          label: "Percent",
          field: "percent",
          sort: "asc",
          width: 270,
        },
        {
          label: "Start Date",
          field: "startDate",
          sort: "asc",
          width: 270,
        },
        {
          label: "End Date",
          field: "endDate",
          sort: "asc",
          width: 270,
        },
        {
          label: "Action",
          field: "action",
          sort: "asc",
          width: 270,
        },
      ],
      rows: this.state.allCoupons,
    };

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.state.breadcrumbItems[0].title}
              breadcrumbItems={this.state.breadcrumbItems}
            />
            <Row>
              <Col xs={12}>
                {this.state.confirm_both ? (
                  <SweetAlert
                    title="Are you sure?"
                    warning
                    showCancel
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => this.delete(this.state.deleteId)}
                    onCancel={() =>
                      this.setState({
                        confirm_both: false,
                      })
                    }
                  >
                    You won't be able to revert this!
                  </SweetAlert>
                ) : null}
                {this.state.alert ? (
                  <UncontrolledAlert
                    color={this.state.type}
                    className="alert-dismissible fade show"
                    role="alert"
                  >
                    {this.state.type == "warning" ? (
                      <i className="mdi mdi-alert-outline mr-2"></i>
                    ) : this.state.type == "success" ? (
                      <i className="mdi mdi-check-all mr-2"></i>
                    ) : this.state.type == "danger" ? (
                      <i className="mdi mdi-block-helper mr-2"></i>
                    ) : null}
                    {this.state.message}
                  </UncontrolledAlert>
                ) : null}
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Promotion Code
                      </Label>
                      <Col md={5}>
                        <Input
                          className="form-control"
                          type="text"
                          value={this.state.name}
                          id="example-text-input"
                          onChange={(val) => {
                            this.setState({ name: val.target.value });
                          }}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Percentage Discount
                      </Label>
                      <Col md={5}>
                        <Input
                          className="form-control"
                          type="number"
                          value={this.state.percentage}
                          id="example-text-input"
                          onChange={(val) => {
                            this.setState({ percentage: val.target.value });
                          }}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Beginning Date
                      </Label>
                      <Col md={5}>
                        <Input
                          className="form-control"
                          type="date"
                          value={this.state.startDate}
                          id="example-text-input"
                          onChange={(val) => {
                            this.setState({ startDate: val.target.value });
                          }}
                          min={this.state.minDate}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Expiration Date{" "}
                      </Label>
                      <Col md={5}>
                        <Input
                          className="form-control"
                          type="date"
                          value={this.state.endDate}
                          id="example-text-input"
                          onChange={(val) => {
                            this.setState({ endDate: val.target.value });
                          }}
                          min={this.state.minDate}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup className="mb-0">
                      <div className="button-items pt-4">
                        <Button
                          color="primary"
                          type="button"
                          className="waves-effect waves-light mr-1"
                          onClick={this.handleSubmit}
                        >
                          {this.state.editEnabled ? "Update" : "Submit"}
                        </Button>
                      </div>
                    </FormGroup>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col xl={12}>
                <div className="card p-3">
                  <MDBDataTable responsive bordered data={data} />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default PromoCreate;
