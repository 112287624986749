import React, { Component } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Button,
  UncontrolledAlert,
} from "reactstrap";
import BellIcon from "react-bell-icon";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable } from "mdbreact";
import Firebase from "./firebase1";
import SweetAlert from "react-bootstrap-sweetalert";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import HttpClient from "./../../utils/HttpClient";
import { Link } from "react-router-dom";
import Modal from "./modal";
import img from "../../assets/images/pro.jpeg";

class FormElements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Chatlist", link: "#" },
        { title: "Manage Chatlist", link: "#" },
      ],
      modal: false,
      customchk: true,
      toggleSwitch: true,
      id: 0,
      userimage: "",
      username: "",
      chatlist: [],
      userCount: 0,
      usersidecount: 0
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  closeCallBack = (val, status, msg) => {
    console.log("ModalRes", val, status);
    if (status == "success") {
      console.log("jkjk");
      this.setState({
        modal: val,
        type: "success",
        status: true,
        title: "your mobile no is  verified",
      });
      //window.alert("your mobile no is verified");
    } else if (status == "error") {
      this.setState({
        modal: val,
        type: "error",
        status: true,
        title: msg,
      });
      //window.alert(msg);
    } else {
      {
        this.setState({
          modal: val,
        });
      }
    }
  };
  fetchData = async () => {
    const dbRef = Firebase.database().ref();
    dbRef.child("chats").on("value", (snapshot) => {
      console.log("ResponseChat", snapshot.val());
      let sl = 1;
      let arr = [];
      snapshot.forEach((ele) => {
        let dom = {
          sl: sl,
          username: ele.val().username,
          useremail: ele.val().useremail,
          userunReadCount: ele.val().userCount,
          bell: (
            <BellIcon
              active={ele.val().lastMsgBy == "User" ? true : false}
              animate={ele.val().lastMsgBy == "User" ? true : false}
              color={ele.val().lastMsgBy == "User" ? "#FF3333" : "#0c89e9"}
              width={20}
              height={20}
            />
          ),

          userprofileimage: (
            <img
              src={img}
              className="img-fluid rounded-circle"
              style={{ height: "30px" }}
            />
          ),
          reply: (
            <Button
              className="info"
              outline
              onClick={() => {
                this.setState({
                  modal: true,
                  id: ele.val().id,
                  userimage: ele.val().userprofileimage,
                  username: ele.val().username,
                  usersidecount: ele.val().userCount,
                 
                });
              }}
            >
              Reply
            </Button>
          ),
        };
        arr.push(dom);
        this.setState({
          chatlist: arr,
        });
        sl++;
      });
      console.log(this.state.chatlist);
    });
  };

  render() {
    const data = {
      columns: [
        {
          label: "Sl.",
          field: "sl",
          sort: "asc",
          width: 150,
        },

        {
          label: "Name",
          field: "username",
          sort: "asc",
          width: 270,
        },

        {
          label: "Email",
          field: "useremail",
          sort: "asc",
          width: 270,
        },
        {
          label: "Image",
          field: "userprofileimage",
          sort: "asc",
          width: 270,
        },
        {
          label: "Unread Message Count",
          field: "userunReadCount",
          sort: "asc",
          width: 270,
        },
        {
          label: "Notification Bell",
          field: "bell",
          sort: "asc",
          width: 270,
        },

        {
          label: "Action",
          field: "reply",
          sort: "asc",
          width: 100,
        },
      ],
      rows: this.state.chatlist,
    };
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.state.breadcrumbItems[0].title}
              breadcrumbItems={this.state.breadcrumbItems}
            />

            <Row>
              <Col xs={12}>
                {this.state.confirm_both ? (
                  <SweetAlert
                    title="Are you sure?"
                    warning
                    showCancel
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    onConfirm={() =>
                      this.delete(
                        this.state.delete_item,
                        this.state.delete_index
                      )
                    }
                    onCancel={() =>
                      this.setState({
                        confirm_both: false,
                      })
                    }
                  >
                    You won't be able to revert this!
                  </SweetAlert>
                ) : null}
                {this.state.alert ? (
                  <UncontrolledAlert
                    color={this.state.type}
                    className="alert-dismissible fade show"
                    role="alert"
                  >
                    {this.state.type == "warning" ? (
                      <i className="mdi mdi-alert-outline mr-2"></i>
                    ) : this.state.type == "success" ? (
                      <i className="mdi mdi-check-all mr-2"></i>
                    ) : this.state.type == "danger" ? (
                      <i className="mdi mdi-block-helper mr-2"></i>
                    ) : null}
                    {this.state.message}
                  </UncontrolledAlert>
                ) : null}
              </Col>
            </Row>
            <Row>
              <Col xl={12}>
                <div className="card p-3">
                  <MDBDataTable responsive bordered data={data} />
                </div>
              </Col>
            </Row>
          </Container>
          {this.state.modal ? (
            <Modal
              id={this.state.id}
              image={this.state.userimage}
              name={this.state.username}
              status={true}
              onCloseCallBack={this.closeCallBack}
              usercount={this.state.usersidecount}
            />
          ) : null}
        </div>
      </React.Fragment>
    );
  }
}

export default FormElements;
