import React, { Component } from "react";
import {
    Card,
    CardBody,
    Col,
    Row,
    Container,
    FormGroup,
    Label,
    Input,
    CustomInput,
    Button,
    UncontrolledAlert,
} from "reactstrap";

//Import Breadcrumb
import Select from "react-select";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable } from "mdbreact";
import Switch from "react-switch";
import SweetAlert from "react-bootstrap-sweetalert";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import HttpClient from "./../../utils/HttpClient";
class FormElements extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems: [
                { title: "Terms & Condition", link: "#" },
                { title: "Manage Terms & Condition", link: "#" },
            ],
            customchk: true,
            toggleSwitch: true,
            // page states
            terms: "",

            alert: false,
            message: "",
            type: "",
            result: [],
            edit_enable: false,
            edit_id: "",

            delete_item: "",
        };
    }

    componentDidMount = async () => {
        this.fetchData();
    };

    fetchData = async () => {
        let result = await HttpClient.requestData("admin/getterms ", "GET");
        console.log(result);
        if (result && result.status) {
            this.setState({
                terms: result.data[0].content,
                edit_enable: true,
            })
        }

    };

    submit = async () => {
        if (

            this.state.terms != ""
        ) {
            if (this.state.edit_enable) {
                let sendData = {
                    id: this.state.edit_id,
                    content: this.state.terms,

                };
                console.log(sendData);

                let result = await HttpClient.requestData(
                    "admin/addeditterms",
                    "POST",
                    sendData
                );
                if (result && result.status) {
                    this.setState({
                        alert: true,
                        message: "Terms & Condition Updated Successfully",
                        type: "success",
                        selecteduser: [],
                        selectedSub_Cat: [],
                        planname: "",
                        edit_enable: false,
                        duration: "",
                        employee: "",
                        price: "",
                    });
                    setTimeout(() => {
                        this.setState({
                            alert: false,
                            message: "",
                            type: "",
                        });
                    }, 3000);
                    this.fetchData();
                }
            } else {
                let sendData = {
                    content: this.state.terms
                };
                console.log(sendData);
                let result = await HttpClient.requestData(
                    "admin/addeditterms",
                    "POST",
                    sendData
                );
                console.log("RESULT", result);
                if (result && result.status) {
                    this.setState({
                        alert: true,
                        message: "Terms & Condition added Successfully",
                        type: "success",
                        terms: "",
                        edit_enable: false,

                    });
                    setTimeout(() => {
                        this.setState({
                            alert: false,
                            message: "",
                            type: "",
                        });
                    }, 3000);
                    this.fetchData();
                }
            }
        } else {
            this.setState({
                alert: true,
                message: "Please fill up all the fields",
                type: "warning",
            });
            setTimeout(() => {
                this.setState({
                    alert: false,
                    message: "",
                    type: "",
                });
            }, 3000);
            this.fetchData();
        }
    };



    render() {


        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs
                            title={this.state.breadcrumbItems[0].title}
                            breadcrumbItems={this.state.breadcrumbItems}
                        />
                        {/* {this.state.edit_enable ? ( */}
                        <Row>
                            <Col xs={12}>
                                <Card>
                                    <CardBody>
                                        {this.state.alert ? (
                                            <UncontrolledAlert
                                                color={this.state.type}
                                                className="alert-dismissible fade show"
                                                role="alert"
                                            >
                                                {this.state.type == "warning" ? (
                                                    <i className="mdi mdi-alert-outline mr-2"></i>
                                                ) : this.state.type == "success" ? (
                                                    <i className="mdi mdi-check-all mr-2"></i>
                                                ) : this.state.type == "danger" ? (
                                                    <i className="mdi mdi-block-helper mr-2"></i>
                                                ) : null}
                                                {this.state.message}
                                            </UncontrolledAlert>
                                        ) : null}


                                        <FormGroup row>
                                            <Label
                                                htmlFor="example-text-input"
                                                className="col-md-2 col-form-label"
                                            >
                                                Add Terms & Condition
                                            </Label>
                                            <Col md={10}>
                                                <textarea
                                                    className="form-control"
                                                    type="text"
                                                    placeholder="Write here..."
                                                    value={this.state.terms}
                                                    id="example-text-input"
                                                    onChange={(val) => {
                                                        this.setState({ terms: val.target.value });
                                                    }}
                                                />
                                            </Col>
                                        </FormGroup>



                                        <FormGroup className="mb-0">
                                            <div className="button-items pt-4">
                                                <Button
                                                    color="primary"
                                                    type="button"
                                                    className="waves-effect waves-light mr-1"
                                                    onClick={() => {
                                                        this.submit();
                                                    }}
                                                >
                                                    {this.state.edit_enable ? "Update" : "Submit"}{" "}
                                                    <i className="ri-arrow-right-line align-middle ml-1"></i>
                                                </Button>
                                            </div>
                                        </FormGroup>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        {/* ) : null} */}

                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default FormElements;
