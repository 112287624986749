import React, { Component } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Button,
  UncontrolledAlert,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable } from "mdbreact";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import Dropzone from "react-dropzone";

import HttpClient from "./../../utils/HttpClient";
class FormElements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Product", link: "#" },
        { title: "Manage Product", link: "#" },
      ],
      customchk: true,
      toggleSwitch: true,
      // page states
      name: "",
      email: "",
      mobile: "",
      password: "",
      data: [],
      alert: false,
      message: "",
      type: "",
      result: [],
      edit_enable: false,
      edit_item_id: "",
      image_select: false,
      img_url: "",
      userId: "",
      userDetails: {},
      //   this page states
      category: [],
      categoryAll: [],
      subCategory: [],
      subCategoryAll: [],

      cat_id: "",
      pruduct_type: "",
      sub_cat_id: "",

      selectedFiles: [],
      images: [],
      uploaded_images: [],

      sell_price: "",
      purchase_price: "",
      discount: "",
    };
  }

  componentDidMount = async () => {
    this.checkUser();
    this.fetchCat();
  };

  fetchCat = async () => {
    let result = await HttpClient.requestData("app-owner/category", "GET");
    if (result && result.status > 0) {
      this.setState({ categoryAll: result.data });
    }
    let resultsub = await HttpClient.requestData(
      "app-owner/category/sub-category",
      "GET"
    );
    console.log("resultsub", resultsub);
    if (resultsub && resultsub.status > 0) {
      this.setState({ subCategoryAll: resultsub.data });
    }
  };

  fetchData = async () => {
    let result = await HttpClient.requestData("admin/product", "GET");
    console.log("result", result);
    if (result && result.status > 0) {
      let data = [];
      let i = 1;
      this.setState({ result: result.data });
      this.state.result.forEach((element, index) => {
        let rows = {
          sl: i,
          appowner: element.appOwnerData ? (
            <div>
              <p>{element.appOwnerData.name}</p>
              <p>{element.appOwnerData.email}</p>
              <p>{element.appOwnerData.mobile}</p>
            </div>
          ) : null,
          name: element.name,
          img:
            element.img.length > 0 ? (
              <div>
                <img
                  src={element.img[0].preview}
                  alt="images"
                  className="rounded avatar-md card-img"
                />
              </div>
            ) : null,
          product_type: element.pruduct_type ? element.pruduct_type : "",
          category: element.categoryData ? element.categoryData.name : "",

          subcategory: element.subcategoryData
            ? element.subcategoryData.name
            : "",
          sell_price: element.sell_price ? element.sell_price : "",
          purchase_price: element.purchase_price ? element.purchase_price : "",
          discount: element.discount ? element.discount : "",
          status: (
            <button
              title="Delete"
              className={
                element.status ? "btn btn-success mr-2" : "btn btn-danger mr-2"
              }
              onClick={() => {
                this.status(element, index);
              }}
            >
              {element.status ? "Active" : "Not Active"}
            </button>
          ),
          action: (
            <>
              <button
                title="Delete"
                className="btn btn-danger mr-2"
                onClick={() => {
                  this.delete(element, index);
                }}
              >
                <i className="fa fa-trash" />
              </button>
              {/* <button
                title="Edit"
                className="btn btn-primary"
                onClick={() => {
                  this.edit(element, index);
                }}
              >
                <i className="fa fa-edit" />
              </button> */}
            </>
          ),
        };
        i++;
        data.push(rows);
      });
      this.setState({
        data: data,
      });
    } else {
      this.setState({
        data: [],
      });
    }
  };
  delete = async (item, index) => {
    let sendData = {
      id: item._id,
    };
    let result = await HttpClient.requestData(
      "app-owner/product/delete",
      "POST",
      sendData
    );
    if (result && result.status) {
      let index = this.state.result.indexOf(item);
      if (index > -1) {
        this.state.result.splice(index, 1);
        this.setState({
          alert: true,
          message: "Deleted Successfully",
          type: "success",
        });
        setTimeout(() => {
          this.setState({
            alert: false,
            message: "",
            type: "",
          });
        }, 3000);
        this.fetchData();
      }
    }
  };
  edit = async (item, index) => {
    this.setState({
      edit_enable: true,
      name: item.name,
      img_url: item.img,
      image_select: true,
      edit_item_id: item._id,
    });
  };
  status = async (item, index) => {
    let sendData = {
      id: item._id,
      status: !item.status,
    };
    let result = await HttpClient.requestData(
      "app-owner/product/status",
      "POST",
      sendData
    );
    if (result && result.status) {
      this.state.result.splice(index, 1);
      this.setState({
        alert: true,
        message: "Status Updated Successfully",
        type: "success",
      });
      setTimeout(() => {
        this.setState({
          alert: false,
          message: "",
          type: "",
        });
      }, 3000);
      this.fetchData();
    }
  };
  submit = async () => {
    if (
      this.state.name != "" &&
      this.state.cat_id != "" &&
      this.state.sub_cat_id != "" &&
      this.state.pruduct_type != "" &&
      this.state.sell_price != "" &&
      this.state.purchase_price != "" &&
      this.state.discount != ""
    ) {
      if (this.state.images.length > 0) {
        this.setState({ loading: true });
        this.state.images.map(async (item, index) => {
          this.imageUpload(item, index);
        });

        setTimeout(async () => {
          let data = null;
          let result = null;
          // return false;
          data = {
            name: this.state.name,
            cat_id: this.state.cat_id,
            sub_cat_id: this.state.sub_cat_id,
            desc: this.state.desc,
            pruduct_type: this.state.pruduct_type,
            sell_price: Number(this.state.sell_price),
            purchase_price: Number(this.state.purchase_price),
            discount: Number(this.state.discount),
            owner_id: this.state.userId,
            img: JSON.stringify(this.state.uploaded_images),
          };
          result = await HttpClient.requestData(
            "app-owner/product",
            "POST",
            data
          );

          if (result && result.status) {
            this.setState({
              alert: true,
              message: this.state.edit_enable
                ? "Product Updated Successfully"
                : "Product Added Successfully",
              type: "success",
              name: "",
              cat_id: "",
              sub_cat_id: "",
              desc: "",
              pruduct_type: "",
              sell_price: "",
              purchase_price: "",
              discount: "",
              uploaded_images: [],
              images: [],
              selectedFiles: [],
              edit_enable: false,
              edit_item_id: "",
            });

            this.fetchData();
          } else {
            this.setState({
              alert: true,
              message:
                typeof result.error == "string"
                  ? result.error
                  : result.error[0].msg,
              type: "danger",
            });
          }
        }, 2000);
      } else {
        this.setState({
          alert: true,
          message: "Please Select Atleast One Image.",
          type: "warning",
        });
      }
    } else {
      this.setState({
        alert: true,
        message: "Please Fill Up All Details.",
        type: "warning",
      });
    }
    setTimeout(() => {
      this.setState({
        alert: false,
        message: "",
        type: "",
      });
    }, 3000);
  };

  imageUpload = async (file, index) => {
    console.log("e", file);

    let dataSend = { buckate: false };

    let result = await HttpClient.newFileUpload(
      "image-upload/product",
      file,
      dataSend
    );
    if (result && result.status) {
      this.state.uploaded_images.push({ preview: result.url });
      if (this.state.images.length - 1 == index) {
        setTimeout(() => {
          this.setState({ loading: false });
        }, 200);
      }
      this.setState({});
    }
  };

  handleAcceptedFiles = (files) => {
    let data = [];
    let file = {
      preview: URL.createObjectURL(files[0]),
      formattedSize: this.formatBytes(files[0].size),
      // image:files,
    };
    let file2 = {
      image: files,
    };

    let file3 = files;
    console.log("data", file);
    this.state.selectedFiles.push(file);
    this.state.images.push(files[0]);
    // console.log("data", this.state.images);
    this.setState({});

    // console.log('files',files)
    // files.map(file =>
    //   Object.assign(file, {
    //     preview: URL.createObjectURL(file),
    //     formattedSize: this.formatBytes(file.size)
    //   })
    // );

    // this.setState({ selectedFiles: files });
  };

  async remove_image(item, index) {
    this.state.selectedFiles.pop(index);
    this.state.images.pop(index);
    this.setState({});
  }

  /**
   * Formats the size
   */
  formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  checkUser = async () => {
    let user = await reactLocalStorage.getObject("userData");
    if (user != null && Object.keys(user).length > 0) {
      this.setState({
        userId: user.id,
        userDetails: user,
      });
    } else {
    }
    this.fetchData();
  };

  handleChange = async (event) => {
    let filterSub = this.state.subCategoryAll.filter(
      (item) => item.category_id == event.target.value
    );
    this.setState({ cat_id: event.target.value, subCategory: filterSub });
  };

  render() {
    const data = {
      columns: [
        {
          label: "Sl.",
          field: "sl",
          sort: "asc",
          width: 150,
        },
        
        {
          label: "App Owner",
          field: "appowner",
          sort: "asc",
          width: 270,
        },
        {
          label: "App Name",
          field: "name",
          sort: "asc",
          width: 270,
        },
        {
          label: "Image",
          field: "img",
          sort: "asc",
          width: 270,
        },
        {
          label: "Product Type",
          field: "product_type",
          sort: "asc",
          width: 270,
        },

        {
          label: "Category",
          field: "category",
          sort: "asc",
          width: 270,
        },
        {
          label: "Sub Category",
          field: "subcategory",
          sort: "asc",
          width: 270,
        },
        {
          label: "Sell Price",
          field: "sell_price",
          sort: "asc",
          width: 270,
        },
        {
          label: "Purchase Price",
          field: "purchase_price",
          sort: "asc",
          width: 270,
        },
        {
          label: "Discount",
          field: "discount",
          sort: "asc",
          width: 270,
        },
        {
          label: "Status",
          field: "status",
          sort: "asc",
          width: 100,
        },
        {
          label: "Action",
          field: "action",
          sort: "asc",
          width: 100,
        },
      ],
      rows: this.state.data,
    };
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.state.breadcrumbItems[0].title}
              breadcrumbItems={this.state.breadcrumbItems}
            />

            {/* <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    {this.state.alert ? (
                      <UncontrolledAlert
                        color={this.state.type}
                        className="alert-dismissible fade show"
                        role="alert"
                      >
                        {this.state.type == "warning" ? (
                          <i className="mdi mdi-alert-outline mr-2"></i>
                        ) : this.state.type == "success" ? (
                          <i className="mdi mdi-check-all mr-2"></i>
                        ) : this.state.type == "danger" ? (
                          <i className="mdi mdi-block-helper mr-2"></i>
                        ) : null}
                        {this.state.message}
                      </UncontrolledAlert>
                    ) : null}
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Product Name
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="text"
                          value={this.state.name}
                          id="example-text-input"
                          onChange={(val) => {
                            this.setState({ name: val.target.value });
                          }}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-search-input"
                        className="col-md-2 col-form-label"
                      >
                        Description
                      </Label>
                      <Col md={10}>
                        <textarea
                          id="question-input1"
                          className="form-control"
                          rows="2"
                          placeholder="Enter your question here..."
                          onChange={(val) => {
                            this.setState({ desc: val.target.value });
                          }}
                          value={this.state.desc}
                        ></textarea>
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Product Type
                      </Label>
                      <Col md={10}>
                        <select
                          className="form-control"
                          onChange={(event) => {
                            let cat = this.state.categoryAll.filter(
                              (item, index) =>
                                item.category_type == event.target.value
                            );

                            this.setState({
                              pruduct_type: event.target.value,
                              category: cat,
                            });
                          }}
                          value={this.state.pruduct_type}
                        >
                          <option value="">Select Product Type</option>
                          <option value="Product Type">Product Based</option>
                          <option value="Service Type">Service Based</option>
                        </select>
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Category
                      </Label>
                      <Col md={10}>
                        <select
                          className="form-control"
                          onChange={this.handleChange}
                          value={this.state.cat_id}
                        >
                          <option value="">Select Category</option>
                          {this.state.category.map((item, index) => {
                            return (
                              <option value={item.id} key={index}>
                                {item.name}
                              </option>
                            );
                          })}
                        </select>
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Sub Category
                      </Label>
                      <Col md={10}>
                        <select
                          className="form-control"
                          onChange={(event) => {
                            this.setState({ sub_cat_id: event.target.value });
                          }}
                          value={this.state.sub_cat_id}
                        >
                          <option value="">Select Sub Category</option>

                          {this.state.subCategory.map((item, index) => {
                            return (
                              <option value={item.id} key={index}>
                                {item.name}
                              </option>
                            );
                          })}
                        </select>
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Sell Price
                      </Label>
                      <Col md={4}>
                        <Input
                          className="form-control"
                          type="number"
                          value={this.state.sell_price}
                          id="example-text-input"
                          onChange={(val) => {
                            this.setState({ sell_price: val.target.value });
                          }}
                          min="0"
                        />
                      </Col>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Purchase Price
                      </Label>
                      <Col md={4}>
                        <Input
                          className="form-control"
                          type="number"
                          value={this.state.purchase_price}
                          id="example-text-input"
                          onChange={(val) => {
                            this.setState({ purchase_price: val.target.value });
                          }}
                          min="0"
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Discount
                      </Label>
                      <Col md={4}>
                        <Input
                          className="form-control"
                          type="number"
                          value={this.state.discount}
                          id="example-text-input"
                          onChange={(val) => {
                            this.setState({ discount: val.target.value });
                          }}
                          min="0"
                        />
                      </Col>
                      
                    </FormGroup>

                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Image
                      </Label>
                      <Col md={10}>
                        <Dropzone
                          onDrop={(acceptedFiles) =>
                            this.handleAcceptedFiles(acceptedFiles)
                          }
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div className="dropzone">
                              <div
                                className="dz-message needsclick mt-2"
                                {...getRootProps()}
                              >
                                <input {...getInputProps()} />
                                <div className="mb-3">
                                  <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                                </div>
                                <h4>Drop files here or click to upload.</h4>
                              </div>
                            </div>
                          )}
                        </Dropzone>
                        <div
                          className="dropzone-previews mt-3"
                          id="file-previews"
                        >
                          {this.state.selectedFiles.map((f, i) => {
                            return (
                              <Card
                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                key={i + "-file"}
                              >
                                <div className="p-2">
                                  <Row className="align-items-center">
                                    <Col className="col-auto">
                                      <img
                                        data-dz-thumbnail=""
                                        height="80"
                                        className="avatar-sm rounded bg-light"
                                        alt={f.name}
                                        src={f.preview}
                                      />
                                    </Col>
                                    <Col>
                                      <Button
                                        className="btn-icon"
                                        color="danger"
                                        style={{ marginRight: 25 }}
                                        onClick={(e) => this.remove_image(f, i)}
                                      >
                                        {" "}
                                        <span className="btn-icon-label">
                                          <i className="mdi mdi-alpha-x-box-outline mr-2"></i>
                                        </span>{" "}
                                        Remove
                                      </Button>
                                    </Col>
                                  </Row>
                                </div>
                              </Card>
                            );
                          })}
                        </div>
                      </Col>
                    </FormGroup>

                    <FormGroup className="mb-0">
                      <div className="button-items pt-4">
                        <Button
                          color="primary"
                          type="button"
                          className="waves-effect waves-light mr-1"
                          onClick={() => {
                            this.submit();
                          }}
                        >
                          {this.state.edit_enable ? "Update" : "Submit"}{" "}
                          <i className="ri-arrow-right-line align-middle ml-1"></i>
                        </Button>
                      </div>
                    </FormGroup>
                  </CardBody>
                </Card>
              </Col>
            </Row> */}
            <Row>
              <Col xl={12}>
                <div className="card p-3">
                  <MDBDataTable responsive bordered data={data} />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default FormElements;
