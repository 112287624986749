import React, { Component } from "react";

import {
  Row,
  Col,
  Input,
  Button,
  Alert,
  Container,
  Label,
  FormGroup,
} from "reactstrap";

// Redux
import { connect } from "react-redux";
import { withRouter, Link, useParams } from "react-router-dom";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// actions
import { checkLogin, apiError } from "../../store/actions";

// import images
import logodark from "../../assets/images/logo-dark.png";

import HttpClient from "./../../utils/HttpClient";
import { reactLocalStorage } from "reactjs-localstorage";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      error: false,
      msg: "",
      success: false,
      type: "",
      id: props.match.params.id,
    };
  }

  async componentDidMount() {
    console.log("as", this.state.id);
    let data = {
      id: Number(this.state.id),
    };
    let result = await HttpClient.requestData(
      "admin/checkIdFront",
      "POST",
      data
    );
    console.log("result", data);
    // return false;
    if (result && result.status) {
      // if (result.data.admin) {
      reactLocalStorage.setObject("adminData", result.data);
      this.setState({ type: result.data.type });
      this.setState({
        error: false,
        success: true,
        msg: "Login Successfull.",
      });
      setTimeout(() => {
        this.setState({ success: false });
        this.props.history.push("/dashboard");
      }, 100);
      // } else {
      //   this.setState({ error: true, msg: "Invalid Details" });
      // }
    } else {
      window.location.href = "http://13.233.19.76:3023/login";
    }
    this.props.apiError("");
    document.body.classList.add("auth-body-bg");
  }

  componentWillUnmount() {
    document.body.classList.remove("auth-body-bg");
  }

  render() {
    return (
      <React.Fragment>
        <div className="home-btn d-none d-sm-block">
          {/* <Link to="/">
            <i className="mdi mdi-home-variant h2 text-white"></i>
          </Link> */}
        </div>

        <div></div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { loginError } = state.Login;
  return { loginError };
};

export default withRouter(
  connect(mapStatetoProps, { checkLogin, apiError })(Login)
);
