import React, { Component } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Button,
  UncontrolledAlert,
} from "reactstrap";

//Import Breadcrumb
import Select from "react-select";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable } from "mdbreact";
import Switch from "react-switch";
import SweetAlert from "react-bootstrap-sweetalert";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import HttpClient from "./../../utils/HttpClient";
class FormElements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Subscription Plan", link: "#" },
        { title: "Manage Subscription Plan", link: "#" },
      ],
      customchk: true,
      toggleSwitch: true,
      // page states
      name: "",
      price: "",
      desc: "",
      data: [],
      alert: false,
      message: "",
      type: "",
      result: [],
      edit_enable: false,
      edit_id: "",
      usertype: [
        {
          label: "Business (For Profit)",
          value: "Business",
        },
        {
          label: "Education",
          value: "Education",
        },
        {
          label: "Foreign Mission",
          value: "Foreign Missions",
        },
        {
          label: "Government Organization",
          value: "Public",
        },
        {
          label: "Individual Entity",
          value: "Individual",
        },
        {
          label: "Law enforcements",
          value: "Law enforcements",
        },
        {
          label: "Non-profit Organization",
          value: "Non Profit",
        },
        {
          label: "Political Party (Affiliation)",
          value: "Political",
        },
        {
          label: "Religion",
          value: "Religion",
        },
      ],
      business: [
        //Business(For Profit)
        {
          label: "Incorporated trustee",
          value: "Incorporated trustee",
        },
        {
          label: "Limited Liability Company",
          value: "Limited Liability Company",
        },
        {
          label: "Sole Proprietorship",
          value: "Sole Proprietorship",
        },
      ],
      govt: [
        {
          label: "Authority",
          value: "Authority",
        },
        {
          label: "ECOWAS",
          value: "ECOWAS",
        },
        {
          label: "FGN",
          value: "FGN",
        },
        {
          label: "Local Goverment",
          value: "Local Goverment",
        },
        {
          label: "Ministry",
          value: "Ministry",
        },
        {
          label: "OAU",
          value: "OAU",
        },
        {
          label: "State Government",
          value: "State Government",
        },
        {
          label: "UN",
          value: "UN",
        },
      ],
      selectgovt: [],
      selectgovValue: "",
      indivudual: [
        {
          label: "Estate",
          value: "Estate",
        },
        {
          label: "Family",
          value: "Family",
        },
        {
          label: "Female",
          value: "Female",
        },
        {
          label: "Joint",
          value: "Joint",
        },
        {
          label: "Male",
          value: "Male",
        },
        {
          label: "Transgender",
          value: "Transgender",
        },
      ],
      selectIndi: [],
      selectIndiValue: "",

      law: [
        {
          label: "Air Force",
          value: "Air Force",
        },
        {
          label: "Army",
          value: "Army",
        },
        {
          label: "Custom",
          value: "Custom",
        },
        {
          label: "DSS",
          value: "DSS",
        },
        {
          label: "Federal Road Safety Corp",
          value: "Federal Road Safety Corp",
        },
        {
          label: "Immigration",
          value: "Immigration",
        },
        {
          label: "LASTMA",
          value: "LASTMA",
        },
        {
          label: "NAFDAC",
          value: "NAFDAC",
        },
        {
          label: "Navy",
          value: "Navy",
        },
        {
          label: "NDLEA",
          value: "NDLEA",
        },
        {
          label: "Police",
          value: "Police",
        },
      ],
      selectLaw: [],
      selectLawValue: "",

      non_profit: [
        {
          label: "NGO",
          value: "NGO",
        },
        {
          label: "Old People's Home",
          value: "Old People's Home",
        },
        {
          label: "Orphanage",
          value: "Orphanage",
        },
        {
          label: "Social Clubs",
          value: "Social Clubs",
        },
        {
          label: "Welfare",
          value: "Welfare",
        },
      ],
      selectNonProfit: [],
      selectNonProfitValue: "",
      political: [
        {
          label: "Area Dons",
          value: "Area Dons",
        },
        {
          label: "Associations",
          value: "Associations",
        },
        {
          label: "Lobbyists",
          value: "Lobbyists",
        },
        {
          label: "Unions",
          value: "Unions",
        },
      ],
      selectPolitical: [],
      slectPoliticalValue: "",

      religion: [
        {
          label: "Agnostic",
          value: "Agnostic",
        },
        {
          label: "Atheist",
          value: "Atheist",
        },
        {
          label: "Christianity",
          value: "Christianity",
        },
        {
          label: "Confucianism",
          value: "Confucianism",
        },
        {
          label: "Daoism",
          value: "Daoism",
        },
        {
          label: "Hinduism",
          value: "Hinduism",
        },
        {
          label: "Islam",
          value: "Islam",
        },
        {
          label: "Judaism",
          value: "Judaism",
        },
        {
          label: "Myth",
          value: "Myth",
        },
        {
          label: "Pagan",
          value: "Pagan",
        },
        {
          label: "Rastafarianism",
          value: "Rastafarianism",
        },
        {
          label: "Other",
          value: "Other",
        },
      ],
      selectReligion: [],
      selectReligionValue: "",
      foreign: [
        {
          label: "Embassy",
          value: "Embassy",
        },
        {
          label: "Commonwealth",
          value: "Commonwealth",
        },
        {
          label: "Foreign Commission",
          value: "Foreign Commission",
        },
      ],
      selectForeign: [],
      selectForeignValue: "",
      selectedBusiness: [],
      selectedBussvalue: "",
      education: [
        {
          label: "High School",
          value: "High School",
        },
        {
          label: "Non Formal",
          value: "Non Formal",
        },
        {
          label: "Primary School",
          value: "Primary School",
        },
        {
          label: "Professional Certification Body",
          value: "Professional Certification Body",
        },
        {
          label: "University",
          value: "University",
        },
      ],

      selectedEdu: [],
      selectedEduValue: "",
      selecteduser: [],
      selectedSub_Cat: [],
      selecteduservalue: "",
      selectarrpush: [],
      employee: "",
      duration: "",
      planname: "",
      delete_index: "",
      delete_item: "",
    };
  }

  componentDidMount = async () => {
    this.fetchData();
  };

  fetchData = async () => {
    let result = await HttpClient.requestData("admin/subscription ", "GET");
    console.log(result);
    if (result && result.status) {
      // let arr1 = result.data.sort((a, b) => {
      //   return (
      //     parseFloat(a.price.replace(/,/g, "")) -
      //     parseFloat(b.price.replace(/,/g, ""))
      //   );
      // });
      // console.log(arr1);

      let data = [];
      let i = 1;
      this.setState({ result: result.data });
      this.state.result.forEach((element, index) => {
        let rows = {
          sl: i,
          title: element.name,
          type: element.usertype ? element.usertype.label : "",
          sub_cat: element.sub_cat ? element.sub_cat.label : "",
          duration: element.duration ? element.duration : "No Duration",
          employee: element.no_of_employee ? element.no_of_employee : "No Users",
          price: "$" + element.price,
          desc: element.description ? element.description : "",

          status: (
            <button
              title={element.status ? "Active" : "Deactive"}
              className={
                element.status ? "btn btn-success mr-2" : "btn btn-danger mr-2"
              }
              onClick={() => {
                this.status(element, index);
              }}
            >
              {element.status ? "Active" : "Deactive"}
            </button>
          ),
          action: (
            <>
              <button
                title="Are you sure you want to delete?"
                className="btn btn-danger mr-2"
                onClick={() => {
                  this.setState({
                    confirm_both: true,
                    delete_item: element._id,
                    delete_index: index,
                  });
                }}
              >
                <i className="fa fa-trash" />
              </button>{" "}
              <button
                title="Edit"
                className="btn btn-primary"
                onClick={() => {
                  this.edit(element);
                  this.scrollTop();
                }}
              >
                <i className="fa fa-edit" />
              </button>
            </>
          ),
        };
        i++;
        data.push(rows);
      });
      this.setState({
        data: data,
      });
    } else {
      this.setState({
        data: [],
      });
    }
  };

  scrollTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
  delete = async () => {
    let sendData = {
      id: this.state.delete_item,
    };
    console.log(sendData);
    let result = await HttpClient.requestData(
      "admin/subscription/delete",
      "POST",
      sendData
    );
    if (result && result.status) {
      this.scrollTop();

      this.setState({
        alert: true,
        message: "Deleted Successfully",
        type: "success",
        confirm_both: false,
        delete_item: "",
      });
      setTimeout(() => {
        this.setState({
          alert: false,
          message: "",
          type: "",
        });
      }, 3000);
      this.fetchData();
    }
  };
  edit = async (item, index) => {
    console.log(item);
    this.state.duration = item.duration;
    this.state.employee = item.no_of_employee;
    this.setState({
      edit_enable: true,
      name: item.name,
      price: item.price,
      desc: item.description,
      edit_id: item.id,
      selecteduser: item.usertype,
      selecteduservalue: item.usertype.value,
      selectedSub_Cat: item.sub_cat,
      planname: item.plan_name,
    });
    window.scrollTo(0, 0)
    console.log(this.state.duration);
    console.log(this.state.employee);
  };
  status = async (item, index) => {
    let sendData = {
      id: item._id,
      status: !item.status,
    };
    console.log(sendData);
    let result = await HttpClient.requestData(
      "admin/subscription/status",
      "POST",
      sendData
    );
    if (result && result.status) {
      this.fetchData();
    }
  };
  submit = async () => {
    if (
      Object.keys(this.state.usertype).length > 0 &&
      Object.keys(this.state.selectedSub_Cat).length > 0 &&
      (this.state.duration != "" || this.state.employee != "") &&
      this.state.price != ""
    ) {
      if (this.state.edit_enable) {
        let sendData = {
          id: this.state.edit_id,
          usertype: this.state.selecteduser,
          duration: this.state.duration,
          no_of_employee: this.state.employee,
          price: this.state.price,
          plan_name: this.state.planname,
          sub_cat: this.state.selectedSub_Cat,
        };
        console.log(sendData);

        let result = await HttpClient.requestData(
          "admin/subscription/update",
          "POST",
          sendData
        );
        if (result && result.status) {
          this.setState({
            alert: true,
            message: "Subscription Updated Successfully",
            type: "success",
            selecteduser: [],
            selectedSub_Cat: [],
            planname: "",
            edit_enable: false,
            duration: "",
            employee: "",
            price: "",
          });
          setTimeout(() => {
            this.setState({
              alert: false,
              message: "",
              type: "",
            });
          }, 3000);
          this.fetchData();
        }
      } else {
        let sendData = {
          usertype: this.state.selecteduser,
          duration: this.state.duration,
          no_of_employee: this.state.employee,
          price: this.state.price,
          plan_name: this.state.planname,
          sub_cat: this.state.selectedSub_Cat,
        };
        console.log(sendData);
        let result = await HttpClient.requestData(
          "admin/subscription",
          "POST",
          sendData
        );
        console.log("RESULT", result);
        if (result && result.status) {
          this.setState({
            alert: true,
            message: "Subscription added Successfully",
            type: "success",
            selecteduser: [],
            selectedSub_Cat: [],
            planname: "",
            edit_enable: false,

            duration: "",
            employee: "",
            price: "",
          });
          setTimeout(() => {
            this.setState({
              alert: false,
              message: "",
              type: "",
            });
          }, 3000);
          this.fetchData();
        }
      }
    } else {
      this.setState({
        alert: true,
        message: "Please fill up all the fields",
        type: "warning",
      });
      setTimeout(() => {
        this.setState({
          alert: false,
          message: "",
          type: "",
        });
      }, 3000);
      this.fetchData();
    }
  };

  Offsymbol(text) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        {text}
      </div>
    );
  }

  OnSymbol(text) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        {text}
      </div>
    );
  }

  handleuserType = (selected) => {
    console.log(selected);
    this.state.selecteduser = selected;
    this.state.selecteduservalue = selected.value;
    this.state.duration = "";
    this.state.employee = "";
    this.state.selectedSub_Cat = [];
    this.setState({});
    console.log(this.state.selecteduservalue);
  };
  handleBusiness = (selected) => {
    console.log(selected);
    this.state.selectedSub_Cat = selected;
    this.state.selectedBussvalue = selected.value;

    this.setState({});
    console.log(this.state.selectedBussvalue);
  };
  handleEdu = (selected) => {
    console.log(selected);
    this.state.selectedSub_Cat = selected;
    this.state.selectedEduValue = selected.value;

    this.setState({});
    console.log(this.state.selectedEduValue);
  };
  handleForeign = (selected) => {
    console.log(selected);
    this.state.selectedSub_Cat = selected;
    this.state.selectForeignValue = selected.value;

    this.setState({});
    console.log(this.state.selectForeignValue);
  };
  handleGovt = (selected) => {
    console.log(selected);
    this.state.selectedSub_Cat = selected;
    this.state.selectgovValue = selected.value;

    this.setState({});
    console.log(this.state.selectgovValue);
  };
  handleIndi = (selected) => {
    console.log(selected);
    this.state.selectedSub_Cat = selected;
    this.state.selectIndiValue = selected.value;

    this.setState({});
    console.log(this.state.selectIndiValue);
  };
  handleLawt = (selected) => {
    console.log(selected);
    this.state.selectedSub_Cat = selected;
    this.state.selectLawValue = selected.value;

    this.setState({});
    console.log(this.state.selectLawValue);
  };
  handleNonProfit = (selected) => {
    console.log(selected);
    this.state.selectedSub_Cat = selected;
    this.state.selectNonProfitValue = selected.value;

    this.setState({});
    console.log(this.state.selectNonProfitValue);
  };
  handlePolitical = (selected) => {
    console.log(selected);
    this.state.selectedSub_Cat = selected;
    this.state.slectPoliticalValue = selected.value;

    this.setState({});
    console.log(this.state.slectPoliticalValue);
  };
  handleReligion = (selected) => {
    console.log(selected);
    this.state.selectedSub_Cat = selected;
    this.state.selectReligionValue = selected.value;

    this.setState({});
    console.log(this.state.selectReligionValue);
  };

  render() {
    const data = {
      columns: [
        {
          label: "Sl.",
          field: "sl",
          sort: "asc",
          width: 150,
        },

        // {
        //   label: "Name",
        //   field: "title",
        //   sort: "asc",
        //   width: 270,
        // },

        {
          label: "Category",
          field: "type",
          sort: "asc",
          width: 270,
        },
        {
          label: "Sub Category",
          field: "sub_cat",
          sort: "asc",
          width: 270,
        },
        {
          label: "No of Users",
          field: "employee",
          sort: "asc",
          width: 270,
        },
        {
          label: "Duration",
          field: "duration",
          sort: "asc",
          width: 270,
        },
        {
          label: "Price",
          field: "price",
          sort: "asc",
          width: 270,
        },

        // {
        //   label: "Plan Name",
        //   field: "planname",
        //   sort: "asc",
        //   width: 270,
        // },

        {
          label: "Status",
          field: "status",
          sort: "asc",
          width: 100,
        },
        {
          label: "Action",
          field: "action",
          sort: "asc",
          width: 100,
        },
      ],
      rows: this.state.data,
    };

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.state.breadcrumbItems[0].title}
              breadcrumbItems={this.state.breadcrumbItems}
            />
            {/* {this.state.edit_enable ? ( */}
            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    {this.state.alert ? (
                      <UncontrolledAlert
                        color={this.state.type}
                        className="alert-dismissible fade show"
                        role="alert"
                      >
                        {this.state.type == "warning" ? (
                          <i className="mdi mdi-alert-outline mr-2"></i>
                        ) : this.state.type == "success" ? (
                          <i className="mdi mdi-check-all mr-2"></i>
                        ) : this.state.type == "danger" ? (
                          <i className="mdi mdi-block-helper mr-2"></i>
                        ) : null}
                        {this.state.message}
                      </UncontrolledAlert>
                    ) : null}

                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Category
                      </Label>
                      <Col md={10}>
                        <Select
                          options={this.state.usertype}
                          onChange={this.handleuserType}
                          value={this.state.selecteduser}
                        />
                      </Col>
                    </FormGroup>
                    {this.state.selecteduservalue == "Business" ? (
                      <FormGroup row>
                        <Label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          Sub Category
                        </Label>
                        <Col md={10}>
                          <Select
                            options={this.state.business}
                            onChange={this.handleBusiness}
                            value={this.state.selectedSub_Cat}
                          />
                        </Col>
                      </FormGroup>
                    ) : this.state.selecteduservalue == "Education" ? (
                      <FormGroup row>
                        <Label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          Sub Category
                        </Label>
                        <Col md={10}>
                          <Select
                            options={this.state.education}
                            onChange={this.handleEdu}
                            value={this.state.selectedSub_Cat}
                          />
                        </Col>
                      </FormGroup>
                    ) : this.state.selecteduservalue == "Public" ? (
                      <FormGroup row>
                        <Label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          Sub Category
                        </Label>
                        <Col md={10}>
                          <Select
                            options={this.state.govt}
                            onChange={this.handleGovt}
                            value={this.state.selectedSub_Cat}
                          />
                        </Col>
                      </FormGroup>
                    ) : this.state.selecteduservalue == "Individual" ? (
                      <FormGroup row>
                        <Label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          Sub Category
                        </Label>
                        <Col md={10}>
                          <Select
                            options={this.state.indivudual}
                            onChange={this.handleIndi}
                            value={this.state.selectedSub_Cat}
                          />
                        </Col>
                      </FormGroup>
                    ) : this.state.selecteduservalue == "Law enforcements" ? (
                      <FormGroup row>
                        <Label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          Sub Category
                        </Label>
                        <Col md={10}>
                          <Select
                            options={this.state.law}
                            onChange={this.handleLawt}
                            value={this.state.selectedSub_Cat}
                          />
                        </Col>
                      </FormGroup>
                    ) : this.state.selecteduservalue == "Non Profit" ? (
                      <FormGroup row>
                        <Label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          Sub Category
                        </Label>
                        <Col md={10}>
                          <Select
                            options={this.state.non_profit}
                            onChange={this.handleNonProfit}
                            value={this.state.selectedSub_Cat}
                          />
                        </Col>
                      </FormGroup>
                    ) : this.state.selecteduservalue == "Political" ? (
                      <FormGroup row>
                        <Label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          Sub Category
                        </Label>
                        <Col md={10}>
                          <Select
                            options={this.state.political}
                            onChange={this.handlePolitical}
                            value={this.state.selectedSub_Cat}
                          />
                        </Col>
                      </FormGroup>
                    ) : this.state.selecteduservalue == "Religion" ? (
                      <FormGroup row>
                        <Label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          Sub Category
                        </Label>
                        <Col md={10}>
                          <Select
                            options={this.state.religion}
                            onChange={this.handleReligion}
                            value={this.state.selectedSub_Cat}
                          />
                        </Col>
                      </FormGroup>
                    ) : this.state.selecteduservalue == "Foreign Missions" ? (
                      <FormGroup row>
                        <Label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          Sub Category
                        </Label>
                        <Col md={10}>
                          <Select
                            options={this.state.foreign}
                            onChange={this.handleForeign}
                            value={this.state.selectedSub_Cat}
                          />
                        </Col>
                      </FormGroup>
                    ) : this.state.edit_enable ? (
                      <FormGroup row>
                        <Label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          Sub Category
                        </Label>
                        <Col md={10}>
                          <Select
                            // options={this.state.religion}
                            // onChange={this.handleReligion}
                            value={this.state.selectedSub_Cat}
                          />
                        </Col>
                      </FormGroup>
                    ) : null}

                    {/* <FormGroup row>
                        <Label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          Name
                        </Label>
                        <Col md={10}>
                          <Input
                            className="form-control"
                            type="text"
                            value={this.state.name}
                            id="example-text-input"
                            onChange={(val) => {
                              this.setState({ name: val.target.value });
                            }}
                          />
                        </Col>
                      </FormGroup> */}
                    {this.state.selecteduservalue == "Individual" ||
                      this.state.selecteduservalue == "Foreign Missions" ||
                      this.state.selecteduservalue == "Education" ||
                      this.state.selecteduservalue == "Law enforcements" ||
                      this.state.duration != "" ? (
                      <FormGroup row>
                        <Label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          Duration
                        </Label>
                        <Col md={10}>
                          <Input
                            className="form-control"
                            placeholder="In Year"
                            type="text"
                            value={this.state.duration}
                            id="example-text-input"
                            onChange={(val) => {
                              this.setState({ duration: val.target.value });
                            }}
                          />
                        </Col>
                      </FormGroup>
                    ) : (
                      <FormGroup row>
                        <Label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          No of Employees
                        </Label>
                        <Col md={10}>
                          <Input
                            className="form-control"
                            placeholder="No of Employee"
                            type="text"
                            value={this.state.employee}
                            id="example-text-input"
                            onChange={(val) => {
                              this.setState({ employee: val.target.value });
                            }}
                          />
                        </Col>
                      </FormGroup>
                    )}

                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Plan Price(USD)
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="text"
                          value={this.state.price}
                          id="example-text-input"
                          onChange={(val) => {
                            this.setState({ price: val.target.value });
                          }}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Plan Name
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="text"
                          value={this.state.planname}
                          id="example-text-input"
                          onChange={(val) => {
                            this.setState({ planname: val.target.value });
                          }}
                        />
                      </Col>
                    </FormGroup>

                    {/* <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Description
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="text"
                          value={this.state.desc}
                          id="example-text-input"
                          onChange={(val) => {
                            this.setState({ desc: val.target.value });
                          }}
                        />
                      </Col>
                    </FormGroup> */}

                    <FormGroup className="mb-0">
                      <div className="button-items pt-4">
                        <Button
                          color="primary"
                          type="button"
                          className="waves-effect waves-light mr-1"
                          onClick={() => {
                            this.submit();
                          }}
                        >
                          {this.state.edit_enable ? "Update" : "Submit"}{" "}
                          <i className="ri-arrow-right-line align-middle ml-1"></i>
                        </Button>
                      </div>
                    </FormGroup>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {/* ) : null} */}
            <Row>
              <Col xl={12}>
                {this.state.confirm_both ? (
                  <SweetAlert
                    title="Are you sure?"
                    warning
                    showCancel
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    onConfirm={this.delete}
                    onCancel={() =>
                      this.setState({
                        confirm_both: false,
                      })
                    }
                  >
                    You won't be able to revert this!
                  </SweetAlert>
                ) : null}
                <div className="card p-3">
                  <MDBDataTable
                    responsive
                    bordered
                    data={data}
                    disableRetreatAfterSorting={true}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default FormElements;
