import React, { Component } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Button,
  UncontrolledAlert,
} from "reactstrap";

//Import Breadcrumb
import Select from "react-select";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable } from "mdbreact";
import Switch from "react-switch";
import SweetAlert from "react-bootstrap-sweetalert";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import HttpClient from "./../../utils/HttpClient";
class FormElements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Price", link: "#" },
        { title: "Manage Price", link: "#" },
      ],
      customchk: true,
      toggleSwitch: true,
      // page states
      name: "",
      price: "",
      desc: "",
      data: [],
      alert: false,
      message: "",
      type: "",
      result: [],
      edit_enable: false,
      edit_id: "",
      userId: "",
      priceid: "",
    };
  }

  componentDidMount = async () => {
    this.checkUser();
    this.fetchData();
  };

  checkUser = async () => {
    let user = await reactLocalStorage.getObject("adminData");
    console.log(user);
    if (user != null && Object.keys(user).length > 0) {
      this.setState({
        userId: user.id,
        userDetails: user,
        name: user.name,
        email: user.email,
      });
    }
  };

  fetchData = async () => {
    let data = {};
    let result = await HttpClient.requestData(
      "admin/getpricing/",
      "POST",
      data
    );
    console.log(result);
    if (result && result.status > 0) {
      let data = [];
      let i = 1;
      this.setState({ result: result.data });
      this.state.result.forEach((element, index) => {
        let rows = {
          sl: i,
          title: element.name,
          type: element.usertype ? element.usertype.label : "",
          duration: element.duration,
          employee: element.no_of_employee,
          price: "$" + element.price,
          desc: element.description ? element.description : "",

          status: (
            <button
              title="Delete"
              className={
                element.status ? "btn btn-success mr-2" : "btn btn-danger mr-2"
              }
              onClick={() => {
                this.status(element, index);
              }}
            >
              {element.status ? "Active" : "Deactive"}
            </button>
          ),
          action: (
            <>
              {/* <button
                title="Delete"
                className="btn btn-danger mr-2"
                onClick={() => {
                  this.delete(element, index);
                }}
              >
                <i className="fa fa-trash" />
              </button>{" "} */}
              <button
                title="Edit"
                className="btn btn-primary"
                onClick={() => {
                  this.edit(element);
                }}
              >
                <i className="fa fa-edit" />
              </button>
            </>
          ),
        };
        i++;
        data.push(rows);
      });
      this.setState({
        data: data,
      });
    } else {
      this.setState({
        data: [],
      });
    }
  };

  scrollTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
  // delete = async (item, index) => {
  //   let sendData = {
  //     id: item._id,
  //   };
  //   let result = await HttpClient.requestData(
  //     "admin/subscription/delete",
  //     "POST",
  //     sendData
  //   );
  //   if (result && result.status) {
  //     this.scrollTop();

  //     this.setState({
  //       alert: true,
  //       message: "Deleted Succfully",
  //       type: "success",
  //     });
  //     setTimeout(() => {
  //       this.setState({
  //         alert: false,
  //         message: "",
  //         type: "",
  //       });
  //     }, 3000);
  //     this.fetchData();
  //   }
  // };
  edit = async (item, index) => {
    this.state.duration = item.duration;
    this.setState({
      edit_enable: true,
      name: item.name,
      price: item.price,
      desc: item.description,
      edit_id: item._id,
      selecteduser: item.usertype,
      employee: item.no_of_employee,
    });
    console.log(this.state.duration);
  };
  status = async (item, index) => {
    let sendData = {
      id: item._id,
      status: !item.status,
    };
    console.log(sendData);
    let result = await HttpClient.requestData(
      "admin/subscription/status",
      "POST",
      sendData
    );
    if (result && result.status) {
      this.fetchData();
    }
  };
  submit = async () => {
    let sendData = {
      price: this.state.price,
      id: 2106241644144414,
    };
    console.log(sendData);
    let result = await HttpClient.requestData(
      "admin/addpricing/",
      "POST",
      sendData
    );
    console.log("RESULT", result);
    if (result && result.status) {
      this.setState({
        alert: true,
        message: "price updated successfully",

        type: "success",
        priceid: this.state.id,
        selecteduser: [],
        duration: "",
        employee: "",
        price: "",
      });
      setTimeout(() => {
        this.setState({
          alert: false,
          message: "",
          type: "",
        });
      }, 3000);
      this.fetchData();
    }
  };

  Offsymbol(text) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        {text}
      </div>
    );
  }

  OnSymbol(text) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        {text}
      </div>
    );
  }

  handleuserType = (selected) => {
    console.log(selected);
    this.state.selecteduser = selected;
    this.state.selecteduservalue = selected.value;
    this.state.duration = "";
    this.state.no_of_employee = "";

    this.setState({});
    console.log(this.state.selecteduservalue);
  };

  render() {
    const data = {
      columns: [
        {
          label: "Sl.",
          field: "sl",
          sort: "asc",
          width: 150,
        },

        // {
        //   label: "Name",
        //   field: "title",
        //   sort: "asc",
        //   width: 270,
        // },

        // {
        //   label: "UserType",
        //   field: "type",
        //   sort: "asc",
        //   width: 270,
        // },
        {
          label: "Price",
          field: "price",
          sort: "asc",
          width: 270,
        },

        // {
        //   label: "Status",
        //   field: "status",
        //   sort: "asc",
        //   width: 100,
        // },
        {
          label: "Action",
          field: "action",
          sort: "asc",
          width: 100,
        },
      ],
      rows: this.state.data,
    };

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.state.breadcrumbItems[0].title}
              breadcrumbItems={this.state.breadcrumbItems}
            />
            {/* {this.state.edit_enable ? ( */}
            {this.state.edit_enable == true ? (
              <Row>
                <Col xs={12}>
                  <Card>
                    <CardBody>
                      {this.state.alert ? (
                        <UncontrolledAlert
                          color={this.state.type}
                          className="alert-dismissible fade show"
                          role="alert"
                        >
                          {this.state.type == "warning" ? (
                            <i className="mdi mdi-alert-outline mr-2"></i>
                          ) : this.state.type == "success" ? (
                            <i className="mdi mdi-check-all mr-2"></i>
                          ) : this.state.type == "danger" ? (
                            <i className="mdi mdi-block-helper mr-2"></i>
                          ) : null}
                          {this.state.message}
                        </UncontrolledAlert>
                      ) : null}

                      <FormGroup row>
                        <Label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          Plan Price
                        </Label>
                        <Col md={10}>
                          <Input
                            className="form-control"
                            type="text"
                            value={this.state.price}
                            id="example-text-input"
                            onChange={(val) => {
                              this.setState({ price: val.target.value });
                            }}
                          />
                        </Col>
                      </FormGroup>

                      {/* <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Description
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="text"
                          value={this.state.desc}
                          id="example-text-input"
                          onChange={(val) => {
                            this.setState({ desc: val.target.value });
                          }}
                        />
                      </Col>
                    </FormGroup> */}

                      <FormGroup className="mb-0">
                        <div className="button-items pt-4">
                          <Button
                            color="primary"
                            type="button"
                            className="waves-effect waves-light mr-1"
                            onClick={() => {
                              this.submit();
                            }}
                          >
                            {this.state.edit_enable ? "Update" : "Submit"}{" "}
                            <i className="ri-arrow-right-line align-middle ml-1"></i>
                          </Button>
                        </div>
                      </FormGroup>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            ) : null}
            {/* ) : null} */}
            <Row>
              <Col xl={12}>
                {this.state.confirm_both ? (
                  <SweetAlert
                    title="Are you sure?"
                    warning
                    showCancel
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    onConfirm={() =>
                      this.delete(
                        this.state.delete_item,
                        this.state.delete_index
                      )
                    }
                    onCancel={() =>
                      this.setState({
                        confirm_both: false,
                      })
                    }
                  >
                    You won't be able to revert this!
                  </SweetAlert>
                ) : null}
                <div className="card p-3">
                  <MDBDataTable responsive bordered data={data} />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default FormElements;
