import React, { Component } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Button,
  UncontrolledAlert,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import loaderImg from "../../assets/images/loader.gif"
import { MDBDataTable } from "mdbreact";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import HttpClient from "./../../utils/HttpClient";
class FormElements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Update Image", link: "#" },
        { title: "Manage Update Image", link: "#" },
      ],
      customchk: true,
      toggleSwitch: true,
      // page states
      name: "",
      desc: "",
      duration: "",
      price: "",
      data: [],
      alert: false,
      message: "",
      type: "",
      result: [],
      fileCsv: null,
      userId: "",
      edit_enable: false,
      edit_item_id: "",
      image_select: false,
      img_url: "",
      loading: false,
    };
  }

  componentDidMount = async () => {
    this.fetchData();
    // this.data();
  };

  fetchData = async () => {
    let result = await HttpClient.requestData("admin/subscription", "GET");
    if (result && result.status > 0) {
      let data = [];
      let i = 1;
      this.setState({ result: result.data });
      this.state.result.forEach((element, index) => {
        let rows = {
          sl: i,
          name: element.name,
          price: element.price,
          duration: element.duration,
          desc: element.desc,
          action: (
            <>
              <button
                title="Delete"
                className="btn btn-danger mr-2"
                onClick={() => {
                  this.delete(element, index);
                }}
              >
                <i className="fa fa-trash" />
              </button>
              <button
                title="Edit"
                className="btn btn-primary"
                onClick={() => {
                  this.edit(element, index);
                }}
              >
                <i className="fa fa-edit" />
              </button>
            </>
          ),
        };
        i++;
        data.push(rows);
      });
      this.setState({
        data: data,
      });
    } else {
      this.setState({
        data: [],
      });
    }
  };
  delete = async (item, index) => {
    let sendData = {
      id: item._id,
    };
    let result = await HttpClient.requestData(
      "admin/subscription/delete",
      "POST",
      sendData
    );
    if (result && result.status) {
      let index = this.state.result.indexOf(item);
      if (index > -1) {
        this.state.result.splice(index, 1);
        this.setState({
          alert: true,
          message: "Deleted Successfully",
          type: "success",
        });
        setTimeout(() => {
          this.setState({
            alert: false,
            message: "",
            type: "",
          });
        }, 3000);
        this.fetchData();
      }
    }
  };
  edit = async (item, index) => {
    this.setState({
      edit_enable: true,
      name: item.name,
      price: item.price,
      desc: item.desc,
      duration: item.duration,
      edit_item_id: item._id,
    });
  };
  submitButton = async () => {
    let dataSend = {
      buckate: true,
      buckate_name: "appIcon",
      admin_id: this.state.userId,
    };
    let result = await HttpClient.customFileUpload(
      "image-upload/testfile",
      this.state.fileCsv,
      dataSend
    );
    console.log("result image", result);
    console.log("dataSend", dataSend);
    if (result && result.status) {
      this.setState({
        alert: true,
        message: "Upload and Adding Successfully",
        type: "success",
      });
    }
  };
  imageUpload = async (e) => {
   
    console.log("e", e.target.files);
    let file = e.target.files;
    //   if(file.lenght>0)
    //   {
    let dataSend = { buckate: true };
    // this.loaders()
    this.setState({ loading: true });
    
    let result = await HttpClient.newFileUpload(
      "image-upload/testfile",
      e.target.files[0],
      dataSend
    );
    console.log("result image", result);
    if (result && result.status) {
      this.setState({
        img_url: result.url,
        image_select: true,
        loading: false,
      });
    //   this.loaders()
    }

    //   }
  };

  checkUser = async () => {
    let user = await reactLocalStorage.getObject("adminData");
    console.log("user", user);
    if (user != null && Object.keys(user).length > 0) {
      this.setState({ userId: user.id });
    } else {
    }
  };
//   loaders = () => {
//     document.body.style.opacity = "0.2";
//     if (this.state.loading) {
//       document.body.style.opacity = "0.2";
//     } else {
//       document.body.style.opacity = "1";
//     }
//   };
 
  //

  render() {
    const data = {
      columns: [
        {
          label: "Sl.",
          field: "sl",
          sort: "asc",
          width: 150,
        },
        {
          label: "Image",
          field: "desc",
          sort: "asc",
          width: 150,
        },
        {
          label: "Action",
          field: "action",
          sort: "asc",
          width: 100,
        },
      ],
      rows: this.state.data,
    };
    return (
      <React.Fragment>
        <div className="page-content">
            {this.state.loading ? (
                <div className="isLoaded">
                    <img src={loaderImg} alt="img" />
                </div>
            ) : null}
          <Container fluid>
            <Breadcrumbs
              title={this.state.breadcrumbItems[0].title}
              breadcrumbItems={this.state.breadcrumbItems}
            />
 
            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    {this.state.alert ? (
                      <UncontrolledAlert
                        color={this.state.type}
                        className="alert-dismissible fade show"
                        role="alert"
                      >
                        {this.state.type == "warning" ? (
                          <i className="mdi mdi-alert-outline mr-2"></i>
                        ) : this.state.type == "success" ? (
                          <i className="mdi mdi-check-all mr-2"></i>
                        ) : this.state.type == "danger" ? (
                          <i className="mdi mdi-block-helper mr-2"></i>
                        ) : null}
                        {this.state.message}
                      </UncontrolledAlert>
                    ) : null}

                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Image
                      </Label>
                      <Col md={10}>
                        {/* {this.state.image_select ? (
                          <div>
                            <img
                              src={this.state.img_url}
                              alt="images"
                              className="rounded avatar-md card-img"
                            />
                          </div>
                        ) : null} */}
                        <Button
                          color="danger"
                          type="button"
                          className="waves-effect waves-light customUploadBtn mr-1"
                        >
                          <i className="ri-add-fill align-middle mr-1"></i> Add
                          image
                          <input
                            type="file"
                            className="upload_input"
                            onChange={(e) => {
                              this.imageUpload(e);
                            }}
                          />
                        </Button>
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Image Url
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="text"
                          value={this.state.img_url}
                          id="example-text-input"
                          readOnly
                        />
                      </Col>
                    </FormGroup>

                    {/* <FormGroup className="mb-0">
                      <div className="button-items pt-4">
                        <Button
                          color="primary"
                          type="button"
                          className="waves-effect waves-light mr-1"
                          onClick={() => {
                            this.submitButton();
                          }}
                        >
                          {this.state.edit_enable ? "Update" : "Submit"}{" "}
                          <i className="ri-arrow-right-line align-middle ml-1"></i>
                        </Button>
                      </div>
                    </FormGroup> */}
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {/* <Row>
              <Col xl={12}>
                <div className="card p-3">
                  <MDBDataTable responsive bordered data={data} />
                </div>
              </Col>
            </Row> */}
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default FormElements;
