import React, { Component } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Button,
  UncontrolledAlert,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable } from "mdbreact";
import SweetAlert from "react-bootstrap-sweetalert";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import HttpClient from "./../../utils/HttpClient";
import { Link } from "react-router-dom";
import TextFeild from "./TextFeild";
class FormElements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Report", link: "#" },
        { title: "Manage Report", link: "#" },
      ],
      customchk: true,
      toggleSwitch: true,
      // page states
      name: "",
      desc: "",
      duration: "",
      price: "",
      data: [],
      alert: false,
      message: "",
      type: "",
      result: [],
      edit_enable: false,
      edit_item_id: "",
      image_select: false,
      img_url: "",
      category_type: "",
      confirm_both: false,
      delete_item: {},
      default_date: new Date(),
      delete_index: "",
    };
    this.handleDefault = this.handleDefault.bind(this);
  }

  componentDidMount = async () => {
    this.fetchData();
  };

  handleDefault(date) {
    this.setState({ default_date: date });
  }

  fetchData = async () => {
    let result = await HttpClient.requestData("admin/report", "GET");
    if (result && result.status > 0) {
      console.log("ApiRESPONSE", result.data);
      let data = [];
      let i = 1;
      this.setState({ result: result.data });
      this.state.result.forEach((element, index) => {
        let rows = {
          sl: i,
          add_by:
            // element.adminData && element.adminData.admin == true
            //   ? element.fname+ " "+element.lname
            //   : typeof element.adminData != "undefined" &&
            //     typeof element.adminData.name != "undefined"
            //     ? element.adminData.name
            //     : null,
            <TextFeild defaultvalue={element.add_by?element.add_by:"Admin"} id={element._id}/>,
          fname: typeof element.fname != "undefined" ? element.fname : null,
          lname: typeof element.lname != "undefined" ? element.lname : null,
          // timing:
          //   moment(element.default_date).format("LL"),
          created_on: moment(element.created_on).format("MM-DD-YYYY"),
          image: (
            <img
              src={element.image}
              style={{ height: 100, width: 100, borderRadius: 10 }}
            />
          ),
          bank_ac: element.bank_ac,
          //   bvn: element.bvn,
          //   cert: element.cert,
          dob: element.dob,
          driver_license: element.driver_license,
          email: element.email,
          emp_id: element.emp_id,
          source:element.source,
          bvn:element.bvn,
          nationalId:element.national_id,
          passport:element.passport,
          vehicleplate:element.plate_reg,
          school:element.student_id,
          tin:element.tin,
          tel:element.tel,
          vin:element.vin,
          voter_reg:element.voter_reg,
          action: (
            <>
              <Link
                title="Edit"
                className="btn btn-primary"
                // onClick={() => {
                //   this.edit(element, index);
                // }}
                to={"/edit-report/" + element.id}
              >
                <i className="fa fa-edit" />
              </Link>
              <button
                title="Are you sure you want to delete?"
                className="btn btn-danger mr-2"
                onClick={() => {
                  this.setState({
                    confirm_both: true,
                    delete_item: element,
                    delete_index: index,
                  });
                }}
              >
                <i className="fa fa-trash" />
              </button>
            </>
          ),
        };
        i++;
        data.push(rows);
      });
      this.setState({
        data: data,
      });
    } else {
      this.setState({
        data: [],
      });
    }
  };
  delete = async (item, index) => {
    let sendData = {
      id: item._id,
    };
    let result = await HttpClient.requestData(
      "admin/report/delete",
      "POST",
      sendData
    );
    if (result && result.status) {
      let index = this.state.result.indexOf(item);
      if (index > -1) {
        this.state.result.splice(index, 1);
        this.setState({
          alert: true,
          message: "Deleted Successfully",
          type: "success",
          confirm_both: false,
          delete_item: {},
          delete_index: "",
        });
        setTimeout(() => {
          this.setState({
            alert: false,
            message: "",
            type: "",
          });
        }, 3000);
        this.fetchData();
      }
    }
  };
  edit = async (item, index) => {
    this.setState({
      edit_enable: true,
      name: item.name,
      category_type: item.category_type,
      edit_item_id: item._id,
      img_url: item.img,
      image_select: true,
      desc: item.desc,
    });
  };
  submit = async () => {
    if (this.state.name != "") {
      let data = null;
      let result = null;

      if (this.state.edit_enable == false) {
        data = {
          name: this.state.name,
        };
        result = await HttpClient.requestData(
          "admin/icon/category",
          "POST",
          data
        );
      } else {
        data = {
          name: this.state.name,
          id: this.state.edit_item_id,
        };
        result = await HttpClient.requestData(
          "admin/icon/category/update",
          "POST",
          data
        );
      }
      console.log("result", result);
      if (result && result.status) {
        this.setState({
          alert: true,
          message: this.state.edit_enable
            ? "Category Updated Successfully"
            : "Category Added Succfully",
          type: "success",
          name: "",
          image_select: false,
          edit_enable: false,
          edit_item_id: "",
        });

        this.fetchData();
      } else {
        this.setState({
          alert: true,
          message: result.error,
          type: "danger",
        });
      }
    } else {
      this.setState({
        alert: true,
        message: "Please Fill Up All Details.",
        type: "warning",
      });
    }
    setTimeout(() => {
      this.setState({
        alert: false,
        message: "",
        type: "",
      });
    }, 3000);
  };

  handleChange = (event) => {
    console.log(event.target.value);
    this.setState({ category_type: event.target.value });
  };

  imageUpload = async (e) => {
    console.log("e", e.target.files);
    let file = e.target.files;
    //   if(file.lenght>0)
    //   {
    let dataSend = { buckate: true, buckate_name: "appIcon" };

    let result = await HttpClient.newFileUpload(
      "image-upload/category",
      e.target.files[0],
      dataSend
    );
    // console.log("result", result);
    if (result && result.status) {
      this.setState({ img_url: result.url, image_select: true });
    }

    //   }
  };

  render() {
    const data = {
      columns: [
        {
          label: "Sl.",
          field: "sl",
          sort: "asc",
          width: 150,
        },
        {
          label: "Added By",
          field: "add_by",
          sort: "asc",
          width: 270,
        },
        {
          label: "Date Stamp",
          field: "created_on",
          sort: "asc",
          width: 270,
        
      },
        {
          label:"Source",
          field:"source",
          sort: "asc",
          width: 270,
        },
        {
          label: "First Name",
          field: "fname",
          sort: "asc",
          width: 270,
        },
        {
          label: "Last Name",
          field: "lname",
          sort: "asc",
          width: 270,
        },
      
        // {
        //   label: "Image",
        //   field: "image",
        //   sort: "asc",
        //   width: 270,
        // },
        // {
        //   label: "Bank Ac",
        //   field: "bank_ac",
        //   sort: "asc",
        //   width: 270,
        // },
        //   {
        //     label: "BVN",
        //     field: "bvn",
        //     sort: "asc",
        //     width: 270,
        //   },
        //   {
        //     label: "Cert",
        //     field: "cert",
        //     sort: "asc",
        //     width: 270,
        //   },
        {
          label: "Date of Birth",
          field: "dob",
          sort: "asc",
          width: 270,
        },
        {
          label: "Driver License ",
          field: "driver_license",
          sort: "asc",
          width: 270,
        },
        // {
        //   label: "Email",
        //   field: "email",
        //   sort: "asc",
        //   width: 270,
        // },
        {
          label: "Company Name / Employee ID ",
          field: "emp_id",
          sort: "asc",
          width: 270,
        },
        // bank_ac
        // bvn
        // cert
        // dob
        // driver_license
        // email
        // emp_id
        {
          label:"BVN ID",
          field:"bvn",
          sort:"asc",
          width:270
        },
        {
          label:"National ID",
          field:"nationalId",
          sort:"asc",
          width:270
        },
        {
          label:"Passport",
          field:"passport",
          sort:"asc",
          width:270
        },
        {
          label:"Vehicle Plate",
          field:"vehicleplate",
          sort:"asc",
          width:270
        },
        {
          label:"School Name / School ID",
          field:"school",
          sort:"asc",
          width:270
        },
        {
          label:"Tax ID",
          field:"tin",
          sort:"asc",
          width:270
        },
        {
          label:"Telephone",
          field:"tel",
          sort:"asc",
          width:270
        },
        {
          label:"VIN",
          field:"vin",
          sort:"asc",
          width:270
        },
        {
          label:"Voter registration",
          field:"voter_reg",
          sort:"asc",
          width:270
        },
        {
          label: "Action",
          field: "action",
          sort: "asc",
          width: 100,
        },
      ],
      rows: this.state.data,
    };
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.state.breadcrumbItems[0].title}
              breadcrumbItems={this.state.breadcrumbItems}
            />

            <Row>
              <Col xs={12}>
                {this.state.confirm_both ? (
                  <SweetAlert
                    title="Are you sure?"
                    warning
                    showCancel
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    onConfirm={() =>
                      this.delete(
                        this.state.delete_item,
                        this.state.delete_index
                      )
                    }
                    onCancel={() =>
                      this.setState({
                        confirm_both: false,
                      })
                    }
                  >
                    You won't be able to revert this!
                  </SweetAlert>
                ) : null}
                {this.state.alert ? (
                  <UncontrolledAlert
                    color={this.state.type}
                    className="alert-dismissible fade show"
                    role="alert"
                  >
                    {this.state.type == "warning" ? (
                      <i className="mdi mdi-alert-outline mr-2"></i>
                    ) : this.state.type == "success" ? (
                      <i className="mdi mdi-check-all mr-2"></i>
                    ) : this.state.type == "danger" ? (
                      <i className="mdi mdi-block-helper mr-2"></i>
                    ) : null}
                    {this.state.message}
                  </UncontrolledAlert>
                ) : null}
              </Col>
            </Row>
            <Row>
              <Col xl={12}>
                <div className="card p-3">
                  <MDBDataTable responsive bordered data={data} />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default FormElements;
