import React, { Component } from "react";
import { CSVLink } from "react-csv";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Button,
  UncontrolledAlert,
} from "reactstrap";
// import '../UserDetails/UserDetails.css';
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable } from "mdbreact";
import SweetAlert from "react-bootstrap-sweetalert";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import HttpClient from "./../../utils/HttpClient";
import { Link } from "react-router-dom";
import { createGlobalStyle } from "styled-components";
import { numberWithCommas } from "../../helpers/global";

export class TransactionHistory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      breadcrumbItems: [
        { title: "Transaction History", link: "#" },
        // { title: "Manage User", link: "#" },
      ],
      customchk: true,
      toggleSwitch: true,
      // page states
      name: "",
      desc: "",
      duration: "",
      price: "",
      data: [],
      alert: false,
      message: "",
      type: "",
      result: [],
      edit_enable: false,
      edit_item_id: "",
      image_select: false,
      img_url: "",
      category_type: "",
      confirm_both: false,
      delete_item: {},
      delete_index: "",
      csvresult: [["Email", "Price", "Created On"]],
    };
  }

  componentDidMount = async () => {
    this.fetchData();
  };

  handleBlockUser = async (element) => {
    const obj = { id: element.id, status: !element.status };
    try {
      console.log(obj);
      const result = await HttpClient.requestData(
        "admin/user/updateUserStatus",
        "POST",
        obj
      );
      if (result.status) {
        this.fetchData();
      }
    } catch (err) {
      console.log(err);
    }
  };

  fetchData = async () => {
    let result = await HttpClient.requestData("admin/subscription/subs-history", "GET");
    console.log("resultHistoryyyy", result);

    if (result && result.status > 0) {
      console.log("userResponse", result.data);
      // return false
      let data = [];
      let i = 1;
      this.setState({
        result: result.data.sort((a, b) => (a.id > b.id ? 1 : -1)),
      });
      this.state.result.forEach((element, index) => {
        this.state.csvresult.push([
          element.email,
          element.price && "$ " + element.price,
          moment(element.created_on).format("MM-DD-YYYY"),
        ]);
        console.log("csvresult", this.state.csvresult);
        let rows = {
          sl: i,
          email: element.userData.email,
          created_on: moment(element.created_on).format("MM-DD-YYYY"),
          // address: element.user_data.address,
          price: element.price && "$" + numberWithCommas(element.price) ,
          promocode: element.promocodeDetail?.name ?? "No promotion applied",
          discount: element.promocodeDetail?.percent
            ? `${element.promocodeDetail?.percent} %`
            : "-",

          // password: element.password,
          // image: (
          //     <img
          //         src={element.user_data.image ? element.user_data.image : ""}
          //         style={{ height: 100, width: 100, borderRadius: 10 }}
          //     />
          // ),
          // blocked: (
          //     <button className="btn btn-danger btn-sm" onClick={() => this.handleBlockUser(element)}>
          //         {element.status === false ? "unlock user" : "lock user"}
          //     </button>
          // )
        };
        i++;
        data.push(rows);
      });
      this.setState({
        data: data,
      });
      console.log("cc", this.state.csvresult);
    } else {
      this.setState({
        data: [],
      });
    }
  };

  // handleChange = (event) => {
  //     console.log(event.target.value);
  //     this.setState({ category_type: event.target.value });
  // };

  // imageUpload = async (e) => {
  //     console.log("e", e.target.files);
  //     let file = e.target.files;
  //     //   if(file.lenght>0)
  //     //   {
  //     let dataSend = { buckate: true, buckate_name: "appIcon" };

  //     let result = await HttpClient.newFileUpload(
  //         "image-upload/category",
  //         e.target.files[0],
  //         dataSend
  //     );
  //     // console.log("result", result);
  //     if (result && result.status) {
  //         this.setState({ img_url: result.url, image_select: true });
  //     }

  //     //   }
  // };

  render() {
    const data = {
      columns: [
        {
          label: "Sl.",
          field: "sl",
          sort: "asc",
          width: 150,
        },

        // {
        //   label: "Image",
        //   field: "image",
        //   sort: "asc",
        //   width: 270,
        // },
        {
          label: "Email Id",
          field: "email",
          sort: "asc",
          width: 270,
        },
        {
          label: "Promotion",
          field: "promocode",
          sort: "asc",
          width: 270,
        },
        {
          label: "Discount",
          field: "discount",
          sort: "asc",
          width: 270,
        },
        // {
        //     label: "Address",
        //     field: "address",
        //     sort: "asc",
        //     width: 270,
        // },
        {
          label: "Price",
          field: "price",
          sort: "asc",
          width: 270,
        },
        {
          label: "Created On",
          field: "created_on",
          sort: "asc",
          width: 270,
        },
        // {
        //     label: "Image",
        //     field: "image",
        //     sort: "asc",
        //     width: 270,
        // },
        // {
        //     label: "Lock/Unlock User",
        //     field: "blocked",
        //     sort: "asc",
        //     width: 270
        // },
        // {
        //     label: "Date Stamp",
        //     field: "created_on",
        //     sort: "asc",
        //     width: 270,
        // },

        // bank_ac
        // bvn
        // cert
        // dob
        // driver_license
        // email
        // emp_id
        // {
        //   label: "Action",
        //   field: "action",
        //   sort: "asc",
        //   width: 100,
        // },
      ],
      rows: this.state.data,
    };
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.state.breadcrumbItems[0].title}
              breadcrumbItems={this.state.breadcrumbItems}
            />

            <Row>
              <Col xs={12}>
                {this.state.confirm_both ? (
                  <SweetAlert
                    title="Are you sure?"
                    warning
                    showCancel
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    onConfirm={() =>
                      this.delete(
                        this.state.delete_item,
                        this.state.delete_index
                      )
                    }
                    onCancel={() =>
                      this.setState({
                        confirm_both: false,
                      })
                    }
                  >
                    You won't be able to revert this!
                  </SweetAlert>
                ) : null}
                {this.state.alert ? (
                  <UncontrolledAlert
                    color={this.state.type}
                    className="alert-dismissible fade show"
                    role="alert"
                  >
                    {this.state.type == "warning" ? (
                      <i className="mdi mdi-alert-outline mr-2"></i>
                    ) : this.state.type == "success" ? (
                      <i className="mdi mdi-check-all mr-2"></i>
                    ) : this.state.type == "danger" ? (
                      <i className="mdi mdi-block-helper mr-2"></i>
                    ) : null}
                    {this.state.message}
                  </UncontrolledAlert>
                ) : null}
              </Col>
            </Row>

            <Row>
              <Col xl={12}>
                <div className="card p-3">
                  <CSVLink
                    class="btn btn-info"
                    style={{ width: "10%" }}
                    data={this.state.csvresult}
                  >
                    Export CSV
                  </CSVLink>

                  <MDBDataTable responsive bordered data={data} />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default TransactionHistory;
