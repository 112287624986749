import React, { Component } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Button,
  UncontrolledAlert,
} from "reactstrap";
import "../SubscriptionList/SubscriptionList.css";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable } from "mdbreact";
import SweetAlert from "react-bootstrap-sweetalert";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import HttpClient from "./../../utils/HttpClient";
import { Link } from "react-router-dom";
import StartDate from "./StartDate";
import EndDate from "./EndDate";
import { numberWithCommas } from "../../helpers/global";
import { CSVLink } from "react-csv";


export class SubscriptionList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      breadcrumbItems: [
        { title: "Subscription History", link: "#" },
        { title: "Manage Subscription History", link: "#" },
      ],
      customchk: true,
      toggleSwitch: true,
      // page states
      name: "",
      desc: "",
      duration: "",
      price: "",
      data: [],
      alert: false,
      message: "",
      type: "",
      result: [],
      edit_enable: false,
      edit_item_id: "",
      image_select: false,
      img_url: "",
      category_type: "",
      confirm_both: false,
      delete_item: {},
      delete_index: "",
      new: [],
      csvresult: [["Start Date", "End Date", "First Name","Last Name","Email","Plan Name","Price"]],

    };
  }
  componentDidMount = async () => {
    this.fetchData();
  };

  fetchData = async () => {
    let result = await HttpClient.requestData(
      "admin/subscription/subs-history",
      "GET"
    );
    console.log(result);

    if (result && result.status > 0) {
      console.log("SubscriptionResponse", result.data);
      let data = [];
      let i = 1;
      this.setState({ result: result.data });

      this.state.result.forEach((element, index) => {
        this.state.csvresult.push([
          moment(element.start_date).format("lll") ,
          moment(element.end_date).format("lll") ,
          element.userData ? element.userData.firstname : "",
          element.userData ? element.userData.lastname : "",
          element.userData ? element.userData.email : "",
          element.planData ? element.planData.plan_name : "",
          "$" + numberWithCommas(element.price) ,
        ]);
        let rows = {
          sl: i,
          startDate:<StartDate id={element.id} defaultValue={element.start_date}/>,
          endDate:<EndDate id={element.id} defaultValue={element.end_date}/>,

          // endDate:element.expiredate ? element.expiredate!=="Invalid date"?moment(new Date(element.expiredate)).format("MM/DD/YYYY"):"Lifetime":"",
          fname: element.userData ? element.userData.firstname : "",
          lname: element.userData ? element.userData.lastname : "",

          email: element.userData ? element.userData.email : "",
          subplan_id: element.plan_id,
          name: element.planData ? element.planData.plan_name : "",
          subplan_price: "$" + numberWithCommas(element.price) ,
        };
        i++;
        data.push(rows);
      });
      this.setState({
        data: data,
      });
    } else {
      this.setState({
        data: [],
      });
    }
  };

  render() {
    const data = {
      columns: [
        {
          label: "Sl.",
          field: "sl",
          sort: "asc",
          width: 150,
        },
        {
          label: "Start Date",
          field: "startDate",
          sort: "asc",
          width: 150,
        },
        {
          label: "End Date",
          field: "endDate",
          sort: "asc",
          width: 150,
        },
        {
          label: "FirstName",
          field: "fname",
          sort: "asc",
          width: 270,
        },
        {
          label: "LastName",
          field: "lname",
          sort: "asc",
          width: 270,
        },

        {
          label: "Email",
          field: "email",
          sort: "asc",
          width: 270,
        },
        {
          label: "Plan Name",
          field: "name",
          sort: "asc",
          width: 270,
        },

        {
          label: "Plan Price",
          field: "subplan_price",
          sort: "asc",
          width: 270,
        },
        // bank_ac
        // bvn
        // cert
        // dob
        // driver_license
        // email
        // emp_id
        // {
        //   label: "Action",
        //   field: "action",
        //   sort: "asc",
        //   width: 100,
        // },
      ],
      rows: this.state.data,
    };
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.state.breadcrumbItems[0].title}
              breadcrumbItems={this.state.breadcrumbItems}
            />

            <Row>
              <Col xs={12}>
                {this.state.confirm_both ? (
                  <SweetAlert
                    title="Are you sure?"
                    warning
                    showCancel
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    onConfirm={() =>
                      this.delete(
                        this.state.delete_item,
                        this.state.delete_index
                      )
                    }
                    onCancel={() =>
                      this.setState({
                        confirm_both: false,
                      })
                    }
                  >
                    You won't be able to revert this!
                  </SweetAlert>
                ) : null}
                {this.state.alert ? (
                  <UncontrolledAlert
                    color={this.state.type}
                    className="alert-dismissible fade show"
                    role="alert"
                  >
                    {this.state.type == "warning" ? (
                      <i className="mdi mdi-alert-outline mr-2"></i>
                    ) : this.state.type == "success" ? (
                      <i className="mdi mdi-check-all mr-2"></i>
                    ) : this.state.type == "danger" ? (
                      <i className="mdi mdi-block-helper mr-2"></i>
                    ) : null}
                    {this.state.message}
                  </UncontrolledAlert>
                ) : null}
              </Col>
            </Row>
            <Row>
              <Col xl={12}>
                <div className="card p-3">
                <CSVLink
                    class="btn btn-info"
                    style={{ width: "10%" }}
                    data={this.state.csvresult}
                  >
                    Export CSV
                  </CSVLink>
                  <MDBDataTable
                  sortable={false}
                  responsive bordered data={data} />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default SubscriptionList;
