import axios from 'axios';


async function requestData(url, method, params = null) {
    // let bash_url = "https://admin.tesdad.com/api/";
    let bash_url = "https://admin.tesdad.com/api/";
    
    let apiUrl = bash_url + url;
    console.log('Url ' + method, apiUrl);
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append("Access-Control-Allow-Origin", "https://admin.tesdad.com");
    // myHeaders.append("Access-Control-Allow-Origin", "http://127.0.0.1:3004");
    const options = {
        method: method,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    };
    if (method === 'DELETE') {
        // options['body'] = none;
    } else if (method !== 'GET' ) {
        options['body'] = JSON.stringify(params);
    }
        return await fetch(apiUrl, options)
        .then(res => res.json())
        .then(result => {
            console.log("result", result);
            return result;
        },
        (error) => {
            // this.setState({ error });
        }
    )
}

async function fileUplode(url, method, file,option, object_get = {}, tokenCustom = null) {
    let bash_url = 'http://api.vintagebazaar.in/api/';
    let apiUrl = bash_url + url;
    const data = new FormData();
    if(option=='Single')
    {
        data.append('image', file);
    }
    else{
        let i =0;
        Object.keys(file).forEach(function (key) {
            data.append('image', file[i]);
            i++;
        });
    }

    Object.keys(object_get).forEach(function (key) {
        let ddd = object_get[key];
        if(key == 'dynamic_fields')
        {
            ddd = JSON.stringify(object_get[key]);
        }
        console.log(key,ddd);
        data.append(key, ddd);
    });

    // for (var pair of data.entries()) {
    //     console.log(pair[0]+ ', ' + pair[1]); 
    // }

    // console.log('data',data);
    let headers = {
        // 'Accept': 'application/json',
        // 'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': "http://api.vintagebazaar.in",
        // 'Authorization': 'Bearer ' + login_status,
    };
    console.log("data",data);

    return await fetch(apiUrl, {
        method: method,
        headers: headers,
        body: data,
    }).then(response => response.json())
        .then(result => {
            console.log(result);
            return result;
        },
    (error) => {
        return error;
    });
}

async function newFileUpload(url,file,object_get) {
    let bash_url = "https://admin.tesdad.com/api/";
    // let bash_url = "http://127.0.0.1:3004/api/";

    let apiUrl = bash_url + url;

    const data = new FormData();
    data.append("image", file);
    Object.keys(object_get).forEach(function (key) {
        let ddd = object_get[key];
        console.log(key, ddd);
        data.append(key, ddd);
      });
    console.log('apiUrl',apiUrl);
    console.log('formdata',data);
      // for (var pair of data.entries()) {
  //     console.log(pair[0]+ ', ' + pair[1]);
  // }

    var requestOptions = {
      method: 'POST',
      body: data,
      redirect: 'follow'
    };
    
    return await fetch(apiUrl, requestOptions)
      .then(response => response.json())
      .then((result) => {return result;})
      .catch((error) => {console.log('error', error); return error});
}

async function customFileUpload(url,file,object_get) {
    let bash_url = "https://admin.tesdad.com/api/";
    // let bash_url = "http://127.0.0.1:3004/api/";

    let apiUrl = bash_url + url;

    const data = new FormData();
    data.append("csv", file);
    Object.keys(object_get).forEach(function (key) {
        let ddd = object_get[key];
        console.log(key, ddd);
        data.append(key, ddd);
      });
    console.log('apiUrl',apiUrl);
    console.log('formdata',data);
      // for (var pair of data.entries()) {
  //     console.log(pair[0]+ ', ' + pair[1]);
  // }

    var requestOptions = {
      method: 'POST',
      body: data,
      redirect: 'follow'
    };
    
    return await fetch(apiUrl, requestOptions)
      .then(response => response.json())
      .then((result) => {return result;})
      .catch((error) => {console.log('error', error); return error});
}
async function fileUplodeDynamic(
    url,
    method,
    file,
    // object_get = {},
    tokenCustom = null
  ) {
    let bash_url = "https://admin.tesdad.com/api/";
    let apiUrl = bash_url + url;
    // let data = new FormData();
    // data.append("image", file);
    // Object.keys(object_get).forEach(function (key) {
    //   let ddd = object_get[key];
    //   data.append(key, ddd);
    // });
  
    let headers = {
      // 'Accept': 'application/json',
      "Content-Type": "multipart/form-data",
      "Access-Control-Allow-Origin": "https://admin.tesdad.com",
      // 'Authorization': 'Bearer ' + login_status,
    };
  
    // Display the key/value pairs
    for (var pair of file.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }
  
    return await fetch(apiUrl, {
      method: "POST",
      body: file,
      redirect: "follow",
    })
      .then((response) => response.json())
      .then(
        (result) => {
          console.log(result);
          return result;
        },
        (error) => {
          return error;
        }
      );
  }

  async function payStack(url, method, params = null) {
    // let bash_url = "https://admin.tesdad.com/api/";
    let bash_url = "https://api.paystack.co";
    let apiUrl = bash_url + url;
    console.log('Url ' + method, apiUrl);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer sk_test_58b285cd7e8a064814220a804033193f07b80e3a");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Cookie", "sails.sid=s%3AUShLHk96apewDgax7B_MauT5Hmwjs4PB.Viep2LiAAoNnUy7yHr%2B%2BX%2BRA4O61TeEnXLI0AxUzybc");
  const requestOptions = {
  method: 'GET',
  headers: myHeaders,
  redirect: 'follow'
};
    if (method === 'DELETE') {
        // options['body'] = none;
    } else if (method !== 'GET' ) {
       requestOptions['body'] = JSON.stringify(params);
    }
        return await fetch(apiUrl, requestOptions)
        .then(res => res.json())
        .then(result => {
            console.log("result", result);
            return result;
        },
        (error) => {
            // this.setState({ error });
        }
    )
}
export default {
    requestData,
    fileUplode,
    newFileUpload,
    customFileUpload,
    fileUplodeDynamic,
    payStack
    // get,
    // post,
    // put,
    // // delete,
    // upload,
}