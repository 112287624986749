import React, { Component } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  FormGroup,
  Label,
  Input,
  Button,
  Form,
  UncontrolledAlert,
} from "reactstrap";
import classnames from "classnames";
import { Link } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable } from "mdbreact";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import HttpClient from "./../../utils/HttpClient";
class CsvUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: "",
      fileCsv: null,
    };
  }
  componentDidMount = async () => {
    this.checkUser();
  };

  uploadImage = async (e, type) => {
    let file = e.target.files[0];
    console.log(file);
    this.setState({
      fileCsv: file,
    });
  };
  submitButton = async () => {
    // if (
    //   typeof this.state.userId != "undefined" &&
    //   this.state.userId == "" &&
    //   this.state.userId == null
    // ) {
      let dataSend = {
        buckate: true,
        buckate_name: "appIcon",
        admin_id: this.state.userId,
      };

      let result = await HttpClient.customFileUpload(
        "admin/report/addingcsv",
        this.state.fileCsv,
        dataSend
      );
      console.log("result", result);
      console.log("dataSend", dataSend);
      if (result && result.status) {
        this.setState({
          alert: true,
          message: "Upload and Adding Successfully",
          type: "success",
        });
      }
    // } else {
    //   this.setState({
    //     alert: true,
    //     message: "Pls login",
    //     type: "danger",
    //   });
    // }

    setTimeout(() => {
      this.setState({
        alert: false
      });
    }, 1000);
  };

  checkUser = async () => {
    let user =  reactLocalStorage.getObject("adminData");
    console.log("user", user);
    if (user != null && Object.keys(user).length > 0) {
      this.setState({ userId: user.id });
    } else {
    }
  };

  render() {
    const data = {
      columns: [
        {
          label: "Sl.",
          field: "sl",
          sort: "asc",
          width: 150,
        },
        {
          label: "Name",
          field: "name",
          sort: "asc",
          width: 270,
        },
        {
          label: "Email",
          field: "email",
          sort: "asc",
          width: 270,
        },
        {
          label: "Mobile",
          field: "mobile",
          sort: "asc",
          width: 150,
        },
        {
          label: "Status",
          field: "status",
          sort: "asc",
          width: 100,
        },
        {
          label: "Action",
          field: "action",
          sort: "asc",
          width: 100,
        },
      ],
      rows: this.state.data,
    };
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* <Breadcrumbs
              title={this.state.breadcrumbItems[0].title}
              breadcrumbItems={this.state.breadcrumbItems}
            /> */}
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <h4 className="card-title mb-4">Add Report CSV</h4>

                    <div id="progrss-wizard" className="twitter-bs-wizard">
                      <Form>
                        <h4 className="card-title">Upload CSV file</h4>
                        <FormGroup row>
                          <Label
                            htmlFor="example-text-input"
                            className="col-md-2 col-form-label"
                          >
                            Image
                          </Label>
                          <Col md={4}>
                            <Input
                              className="form-control"
                              type="file"
                              id="example-text-input"
                              onChange={(e) => {
                                this.uploadImage(e, "file");
                              }}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup>
                          <Button
                            onClick={() => {
                              this.submitButton();
                            }}
                          >
                            upload
                          </Button>
                        </FormGroup>
                        {this.state.alert ? (
                        <UncontrolledAlert
                          color={this.state.type}
                          className="alert-dismissible fade show"
                          role="alert"
                        >
                          {this.state.type == "warning" ? (
                            <i className="mdi mdi-alert-outline mr-2"></i>
                          ) : this.state.type == "success" ? (
                            <i className="mdi mdi-check-all mr-2"></i>
                          ) : this.state.type == "danger" ? (
                            <i className="mdi mdi-block-helper mr-2"></i>
                          ) : null}
                          {this.state.message}
                        </UncontrolledAlert>
                      ) : null}
                      </Form>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default CsvUpload;
