import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import HttpClient from "./../../utils/HttpClient";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//Import Components
import MiniWidgets from "./MiniWidgets";
import RevenueAnalytics from "./RevenueAnalytics";
import SalesAnalytics from "./SalesAnalytics";
import EarningReports from "./EarningReports";
import Sources from "./Sources";
import RecentlyActivity from "./RecentlyActivity";
import RevenueByLocations from "./RevenueByLocations";
import ChatBox from "./ChatBox";
import LatestTransactions from "./LatestTransactions";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Tesdata", link: "#" },
        { title: "Dashboard", link: "#" },
      ],
      reports: [
        {
          icon: "ri-stack-line",
          title: "Total Users",
          value: "0",
          rate: "2.4%",
          desc: "From previous period",
        },
        {
          icon: "ri-store-2-line",
          title: "Total Active Users",
          value: "0",
          rate: "2.4%",
          desc: "From previous period",
        },
        {
          icon: "ri-briefcase-4-line",
          title: "Total Report Revenue",
          value: "$ 0",
          rate: "2.4%",
          desc: "From previous period",
        },
        {
          icon: "ri-briefcase-4-line",
          title: "Total Subscription Revenue",
          value: "$ 0",
          rate: "2.4%",
          desc: "From previous period",
        },
      ],
    };
  }

  countData = async () => {
    console.log("pallab");
    let result = await HttpClient.requestData("admin/countData", "GET", {});
    console.log("countResult", result);
    if (result && result.status) {
      this.state.reports[0].value = this.numberWithCommas(result.totalUser);
      this.state.reports[1].value = this.numberWithCommas (result.totalActiveUser);
      this.state.reports[2].value = "$ " + this.numberWithCommas(result.totalRev) ;
      this.state.reports[3].value = "$ " + this.numberWithCommas(result.totalRevSub) ;

      this.setState({});
    }
  };

  componentDidMount = () => {
    this.countData();
  };

  numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title="Dashboard"
              breadcrumbItems={this.state.breadcrumbItems}
            />
            <Row>
              <Col xl={12}>
                <Row>
                  <MiniWidgets reports={this.state.reports} />
                </Row>

                {/* revenue Analytics */}
                {/* <RevenueAnalytics /> */}
              </Col>

              {/* <Col xl={4}> */}

              {/* sales Analytics */}
              {/* <SalesAnalytics /> */}

              {/* earning reports */}
              {/* <EarningReports /> */}

              {/* </Col> */}
            </Row>

            {/* <Row> */}
            {/* sources */}
            {/* <Sources /> */}

            {/* recent activity */}
            {/* <RecentlyActivity /> */}

            {/* revenue by locations */}
            {/* <RevenueByLocations /> */}

            {/* </Row> */}

            {/* <Row> */}
            {/* chat box */}
            {/* <ChatBox /> */}

            {/* latest transactions */}
            {/* <LatestTransactions /> */}
            {/* </Row> */}
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default Dashboard;
