import React from 'react'
import HttpClient from '../../utils/HttpClient';
import { useState } from 'react';

function EndDate({defaultValue,id=""}) {
    console.log("enddd",defaultValue);
    const [value,setValue] = useState(defaultValue?defaultValue:"")
    const updateDate = async()=>{
        let date = {
            end_date:value,
            id:parseInt(id) 
        }
        let result = await HttpClient.requestData("admin/subscription/update-sub","POST",date)
    }
  return (
    <input type="date" onBlur={updateDate} value={value } onChange={(val)=>setValue(val.target.value)}/>
  )
}

export default EndDate