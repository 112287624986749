import React, { Component } from "react";
import HttpClient from "../../utils/HttpClient";
import { reactLocalStorage } from "reactjs-localstorage";
import { Link } from "react-router-dom";
//import ReactJsAlert from "reactjs-alert";
import "./chatlist.css";
import Firebase from "./firebase1";
import { Row, Card, CardBody, Col, Input, Button, Spinner } from "reactstrap";
import SimpleBar from "simplebar-react";
import img from "../../assets/images/pro.jpeg";
import moment from "moment";
import img1 from "../../assets/images/mp4.jpeg";

export default class verifymobile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      type: "",
      status: false,
      title: "",
      msglist: [],
      message: "",
      modalStatus: this.props.status ? this.props.status : false,
      selectedFiles: [],
      filetype: "",
      firstFile: "",
      videoloading: false,
      adminCount: 0,
      userCount: 0,
    };
    this.messagesEndRef = React.createRef();
  }
  componentDidMount() {
    // console.log(this.props.id);
    // console.log(this.props.name);
    this.FetchMessageList();
    this.scrollToBottom();
  }

  FetchMessageList = async () => {
    const dbRef = Firebase.database().ref();
    Firebase.database()
      .ref()
      .child("chats")
      .child(this.props.id)
      .child("msglist")
      .on("value", (snapshot) => {
        let arr = [];
        if (snapshot.exists()) {
          console.log("IndividualResponseClick", snapshot.val());

          snapshot.forEach((ele) => {
            let dom = {
              id: ele.val().msgid,
              msg: ele.val().msgtext,
              msgby: ele.val().msgby,
              msgimg: ele.val().msgimg,
              msgvideo: ele.val().msgvideo,
              msgtime: ele.val().send_time,
            };
            console.log(dom);
            arr.push(dom);
            this.setState({
              msglist: arr,
            });
          });
        }
      });

    //Notification part when admin send msg--
    Firebase.database()
      .ref()
      .child("chats")
      .child(this.props.id)
      .on("value", (snapshot) => {
        if (snapshot.exists()) {
          console.log(snapshot.val().userCount);
          this.state.userCount = snapshot.val().userCount;
          this.state.adminCount = snapshot.val().adminCount;
          this.setState({});
          console.log("Count", this.state.userCount);
          console.log("CountNew", this.state.adminCount);
        }
      });

    //Notification part when user send a msg--

    Firebase.database()
      .ref()
      .child("chats")
      .child(this.props.id)
      .once("value")
      .then((snapshot) => {
        if (snapshot.exists()) {
          console.log(snapshot.val().userCount);
          this.state.userCount = snapshot.val().userCount;
          this.state.adminCount = snapshot.val().adminCount;
          this.setState({});
          console.log("Count", this.state.userCount);
          console.log("Count", this.state.adminCount);
          Firebase.database()
            .ref()
            .child("chats")
            .child(this.props.id)
            .update({ userCount: 0 });
        }
      });

    //End Noti---
  };

  onCloseMethod = () => {
    this.setState({
      modalStatus: false,
    });

    this.props.onCloseCallBack(false, "close", "");
  };

  SendMsg = async (url) => {
    // if (this.state.message != "") {
    //creating msg id using push
    //using .key we can get the pushed id in return
    let date = Date.now();
    let date1 = moment(date).format("YYYY-MM-DD");
    let date2 = moment(date).format("HH:mm:ssZ");
    let msgTime = date1 + "T" + date2;

    Firebase.database()
      .ref()
      .child("chats")
      .child(this.props.id)
      .update({ adminCount: this.state.adminCount + 1, lastMsgBy: "Admin" });
    let msgid = Firebase.database()
      .ref()
      .child("chats")
      .child(this.props.id)
      .child("msglist")
      .push().key;
    console.log(msgid);
    //now we setting the msg body into the perticular msg id
    Firebase.database()
      .ref()
      .child("chats")
      .child(this.props.id)
      .child("msglist")
      .child(msgid)
      .set(
        {
          msgby: "admin",
          msgtext: this.state.message,
          msgid: msgid,
          send_time: msgTime,
          msgimg:
            this.state.filetype == "image/jpeg" ||
              this.state.filetype == "image/png"
              ? url
              : "",
          msgvideo: this.state.filetype == "video/mp4" ? url : "",
          // send_time: ele.val().send_time,
          // msgimg:"",
          // msgvideo:""
        },
        (error) => {
          if (error) {
            alert("something went wrong");
          } else {
            this.scrollToBottom();
            //alert("message has been sent successfully");
            this.setState({
              message: "",
              firstFile: "",
              selectedFiles: [],
            });
          }
        }
      );
    //}
  };

  scrollToBottom = () => {
    const target = this.messagesEndRef.current;
    target.scrollIntoView({
      top: target.scrollHeight,
      behavior: "smooth",
    });
  };

  uploadFile = async () => {
    this.setState({
      videoloading: true,
    });
    let data = new FormData();
    data.append("image", this.state.firstFile);
    let result = await HttpClient.fileUplodeDynamic(
      "image-upload/chat",
      "POST",
      data
    );
    console.log(result);
    if (result && result.status) {
      this.setState({
        videoloading: false,
      });
      this.SendMsg(result.url);
    }
  };

  handlefilePick = async (event) => {
    let data = [];
    let file = {
      preview: URL.createObjectURL(event.target.files[0]),
    };
    this.setState({
      filetype: event.target.files[0].type,
      //firstFile: URL.createObjectURL(event.target.files[0]),
      firstFile: event.target.files[0],
      selectedFiles: [
        {
          preview: URL.createObjectURL(event.target.files[0]),
          type: event.target.files[0].type,
        },
      ],
    });
    console.log("selected files", this.state.selectedFiles);
    setTimeout(() => {
      console.log("selected files", this.state.firstFile);
    }, 3000);
  };

  render() {
    const { selectedOption } = this.state;
    return (
      <div
        className={this.state.modalStatus ? "modal show fade" : "modal fade"}
        id="add-new-task-modal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="add-new-task-modalLabel"
        aria-hidden="true"
        style={{ display: this.state.modalStatus ? "block" : "none" }}
      >
        <div className="modal-dialog modal-dialog-centered replyBox modal-lg">
          <div className="modal-content">
            <Card className="mb-0">
              {/* chat head area */}
              <CardBody className="border-bottom">
                <div className="user-chat-border">
                  <Row>
                    <Col md={9} xs={9}>
                      <div className="d-flex">
                        <div className="my-auto mr-3">
                          {this.props.image != "" ? (
                            <img
                              src={this.props.image}
                              className="img-fluid rounded-circle"
                              style={{ height: "30px", width: "30px" }}
                            />
                          ) : (
                            <img
                              src={img}
                              className="img-fluid rounded-circle"
                              style={{ height: "30px", width: "30px" }}
                            />
                          )}
                        </div>
                        <h5 className="font-size-15 my-auto mr-auto">
                          {this.props.name}
                        </h5>
                      </div>
                    </Col>
                    <Col md={3}>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-hidden="true"
                        onClick={() => this.onCloseMethod()}
                      >
                        ×
                      </button>
                    </Col>
                  </Row>
                </div>
              </CardBody>
              {/* chat list body */}
              <CardBody>
                <div className="chat-widget">
                  <div className="chat-conversation">
                    <SimpleBar style={{ maxHeight: "250px" }}>
                      <ul className="list-unstyled mb-0 pr-3">
                        {this.state.msglist.length > 0 &&
                          this.state.msglist.map((item, index) => {
                            return (
                              <li
                                className={item.msgby === "user" ? "" : "right"}
                                key={index}
                              >
                                {item.msg != "" && item.msgimg != "" ? (
                                  <div className="conversation-list">
                                    <div className="ctext-wrap">
                                      <div className="ctext-wrap-content py-1 px-2">
                                        <p className="mb-2">{item.msg}</p>
                                        {/* if image exist */}
                                        <img
                                          data-dz-thumbnail
                                          src={item.msgimg}
                                          className="avatar-md rounded bg-light"
                                          alt="preview"
                                        />
                                        <p className="mb-2">
                                          {moment(item.msgtime).format("MM-DD-YYYY")}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                ) : item.msg != "" && item.msgvideo != "" ? (
                                  <div className="conversation-list">
                                    <div className="ctext-wrap">
                                      <div className="ctext-wrap-content py-1 px-2">
                                        <p className="mb-2">{item.msg}</p>

                                        <video
                                          // width="50%"
                                          hight={300}
                                          width={300}
                                          controls
                                        // className="rounded-lg"
                                        >
                                          <source
                                            src={item.msgvideo}
                                            type="video/mp4"
                                          />
                                        </video>
                                        <p className="mb-2">
                                          {moment(item.msgtime).format("MM-DD-YYYY")}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                ) : item.msg == "" && item.msgimg != "" ? (
                                  <div className="conversation-list">
                                    <div className="ctext-wrap">
                                      <div className="ctext-wrap-content py-1 px-2">
                                        <img
                                          data-dz-thumbnail
                                          src={item.msgimg}
                                          className="avatar-md rounded bg-light"
                                          alt="preview"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                ) : item.msg == "" && item.msgvideo != "" ? (
                                  <div className="conversation-list">
                                    <div className="ctext-wrap">
                                      <div className="ctext-wrap-content py-1 px-2">
                                        <video
                                          // width="50%"
                                          controls
                                          hight={300}
                                          width={300}
                                        //className="rounded-lg"
                                        >
                                          <source
                                            src={item.msgvideo}
                                            type="video/mp4"
                                          />
                                        </video>

                                        <p className="mb-2">
                                          {moment(item.msgtime).format("MM-DD-YYYY")}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="conversation-list">
                                    <div className="ctext-wrap">
                                      <div className="ctext-wrap-content py-1 px-2">
                                        <p className="mb-2">{item.msg}</p>
                                        <p className="mb-2">
                                          {moment(item.msgtime).format("MM-DD-YYYY")}
                                        </p>
                                        {/* if image exist */}
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </li>
                            );
                          })}
                        <li ref={this.messagesEndRef}></li>
                      </ul>
                    </SimpleBar>
                  </div>
                </div>
              </CardBody>
              {/* chat write area */}
              <div className="p-3 chat-input-section border-top">
                <Row>
                  {/* upload image here */}
                  <Col md={12}>
                    <div>
                      {this.state.selectedFiles.length > 0 &&
                        this.state.selectedFiles.map((item, index) => {
                          return (
                            <div className="upld_img">
                              {item.type == "image/jpeg" ? (
                                <img
                                  data-dz-thumbnail
                                  src={item.preview}
                                  className="avatar-sm rounded bg-light"
                                  alt="preview"
                                />
                              ) : (
                                <img
                                  data-dz-thumbnail
                                  src={img1}
                                  className="avatar-sm rounded bg-light"
                                  alt="preview"
                                />
                              )}

                              <button
                                className="close border-0 bg-transparent p-0 img_remove"
                                onClick={() => {
                                  this.setState({
                                    firstFile: "",
                                    selectedFiles: [],
                                  });
                                }}
                              >
                                <i className="dripicons-cross" />
                              </button>
                            </div>
                          );
                        })}
                      {/* {this.state.videoloading?
                        <img
                        src="https://tenor.com/view/loading-waiting-please-wait-gif-14579561"
                        />:null} */}

                      {/* {this.state.videoloading ? (
                          <img
                            src="https://media.tenor.com/images/7d9cb36e95124fb829ff8f2450c3a567/loading-waiting-please-wait-gif-14579561"
                            style={{ height: 50, width: 50 }}
                          />
                        ) : null} */}
                    </div>
                  </Col>
                  {/* choose image */}
                  <Col md={1}>
                    <label htmlFor="fileUpload" className="my_fileUpload">
                      <i className="mdi mdi-folder-multiple-image"></i>
                      <input
                        type="file"
                        id="fileUpload"
                        className="fileUpload"
                        onChange={this.handlefilePick}
                      />
                    </label>
                  </Col>
                  {/* write message */}
                  <Col md={9}>
                    <div>
                      <Input
                        type="text"
                        className="form-control rounded chat-input pl-3"
                        placeholder="Enter Message..."
                        onChange={(val) =>
                          this.setState({
                            message: val.target.value,
                          })
                        }
                        value={this.state.message}
                      />
                    </div>
                  </Col>
                  {this.state.message != "" && this.state.firstFile != "" ? (
                    <Col md={2} xs={{ size: "auto" }}>
                      <Button
                        color="primary"
                        type="button"
                        onClick={this.uploadFile}
                        className="chat-send waves-effect waves-light font-size-12 w-100"
                      >
                        <span className="d-none d-sm-inline-block mr-1">
                          Send
                        </span>{" "}
                        <i className="mdi mdi-send"></i>
                      </Button>
                    </Col>
                  ) : this.state.message == "" && this.state.firstFile != "" ? (
                    <Col md={2} xs={{ size: "auto" }}>
                      <Button
                        color="primary"
                        type="button"
                        onClick={this.uploadFile}
                        className="chat-send waves-effect waves-light font-size-12 w-100"
                      >
                        <span className="d-none d-sm-inline-block mr-1">
                          Send
                        </span>{" "}
                        <i className="mdi mdi-send"></i>
                      </Button>
                    </Col>
                  ) : this.state.message != "" && this.state.firstFile == "" ? (
                    <Col md={2} xs={{ size: "auto" }}>
                      <Button
                        color="primary"
                        type="button"
                        onClick={() => this.SendMsg("")}
                        className="chat-send waves-effect waves-light font-size-12 w-100"
                      >
                        <span className="d-none d-sm-inline-block mr-1">
                          Send
                        </span>{" "}
                        <i className="mdi mdi-send"></i>
                      </Button>
                    </Col>
                  ) : (
                    //    this.state.videoloading?
                    //    <Col md={2} xs={{ size: "auto" }}>
                    //     <Spinner
                    //     color="primary"

                    //     className="chat-send waves-effect waves-light font-size-12 w-100"
                    //    >

                    //   </Spinner>
                    //  </Col>:
                    <Col md={2} xs={{ size: "auto" }}>
                      <Button
                        color="primary"
                        type="button"
                        onClick={() => {
                          alert("please enter message");
                        }}
                        className="chat-send waves-effect waves-light font-size-12 w-100"
                      >
                        <span className="d-none d-sm-inline-block mr-1">
                          Send
                        </span>{" "}
                        <i className="mdi mdi-send"></i>
                      </Button>
                    </Col>
                  )}
                </Row>
              </div>
            </Card>
          </div>
        </div>
      </div>
    );
  }
}
