import React, { Component } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Button,
  UncontrolledAlert,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  Form,
  Progress,
} from "reactstrap";
import classnames from "classnames";
import { Link } from "react-router-dom";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable } from "mdbreact";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import HttpClient from "./../../utils/HttpClient";
class FormElements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Report", link: "#" },
        { title: "Edit Report", link: "#" },
      ],
      customchk: true,
      toggleSwitch: true,
      // page states
      name: "",
      source: "",
      email: "",
      mobile: "",
      password: "",
      data: [],
      alert: false,
      message: "",
      type: "",
      result: [],
      edit_enable: true,
      edit_item_id: this.props.match.params.id,

      // page state
      name: "",
      bank_ac: "",
      bvn: "",
      cert: "",
      dob: "",
      driver_license: "",
      email: "",
      emp_id: "",
      local_id: "",
      national_id: "",
      passport: "",
      payroll: "",
      plate_reg: "",
      rc: "",
      social_media: "",
      state_id: "",
      student_id: "",
      tel: "",
      tin: "",
      vin: "",
      fingerprints: "",
      voter_reg: "",
      image: "",
      fname: "",
      mname: "",
      lname: "",
      selectfname: true,
      selectlname: true,

      report_419: "",
      report_419_desc: "",
      img_video_ref: "",
      image_repo: "",
      video_repo: "",

      academic_report: "",
      academic_report_desc: "",
      image_vid_ref_acd: "",
      image_acd: "",
      video_acd: "",

      behavioral_report: "",
      behavioral_report_desc: "",
      image_vid_ref_bhv: "",
      image_bhv: "",
      video_bhv: "",

      child_abuse: "",
      child_abuse_desc: "",
      image_vid_ref_child: "",
      image_child: "",
      video_child: "",

      credit_report: "",
      credit_report_desc: "",
      image_vid_ref_cred: "",
      image_cred: "",
      video_cred: "",

      criminal_report: "",
      criminal_report_desc: "",
      image_vid_ref_crim: "",
      image_crim: "",
      video_crim: "",

      death_report: "",
      death_report_desc: "",
      image_vid_ref_deat: "",
      image_deat: "",
      video_deat: "",

      domestic_violent: "",
      domestic_violent_desc: "",
      image_vid_ref_dome: "",
      image_dome: "",
      video_dome: "",

      fingerprint_report: "",
      fingerprint_report_desc: "",
      image_vid_ref_fing: "",
      image_fing: "",
      video_fing: "",

      fraud_alerts: "",
      fraud_alerts_desc: "",
      image_vid_ref_frau: "",
      image_frau: "",
      video_frau: "",

      ghost_worker: "",
      ghost_worker_desc: "",
      image_vid_ref_ghos: "",
      image_ghos: "",
      video_ghos: "",

      insurance_report: "",
      insurance_report_desc: "",
      image_vid_ref_insu: "",
      image_insu: "",
      video_insu: "",

      property_report: "",
      property_report_desc: "",
      image_vid_ref_prop: "",
      image_prop: "",
      video_prop: "",

      medical_report: "",
      medical_report_desc: "",
      image_vid_ref_medi: "",
      image_medi: "",
      video_medi: "",

      one_chance: "",
      one_chance_desc: "",
      image_vid_ref_onec: "",
      image_onec: "",
      video_onec: "",

      police_report: "",
      police_report_desc: "",
      image_vid_ref_poli: "",
      image_poli: "",
      video_poli: "",

      professional_report: "",
      professional_report_desc: "",
      image_vid_ref_prof: "",
      image_prof: "",
      video_prof: "",

      recommedations_report: "",
      recommedations_report_desc: "",
      image_vid_ref_reco: "",
      image_reco: "",
      video_reco: "",

      rental_report: "",
      rental_report_desc: "",
      image_vid_ref_rent: "",
      image_rent: "",
      video_rent: "",

      sexual_harrasement: "",
      sexual_harrasement_desc: "",
      image_vid_ref_sexu: "",
      image_sexu: "",
      video_sexu: "",

      social_capital: "",
      social_capital_desc: "",
      image_vid_ref_soci: "",
      image_soci: "",
      video_soci: "",

      tax_clearance: "",
      tax_clearance_desc: "",
      image_vid_ref_taxc: "",
      image_taxc: "",
      video_taxc: "",

      tax_compliance_report: "",
      tax_compliance_report_desc: "",
      image_vid_ref_taxco: "",
      image_taxco: "",
      video_taxco: "",

      terrorism_report: "",
      terrorism_report_desc: "",
      image_vid_ref_terr: "",
      image_terr: "",
      video_terr: "",

      traffic_offender_report: "",
      traffic_offender_report_desc: "",
      image_vid_ref_traf: "",
      image_traf: "",
      video_traf: "",

      utilities_report: "",
      utilities_report_desc: "",
      image_vid_ref_util: "",
      image_util: "",
      video_util: "",

      vehicle_report: "",
      vehicle_report_desc: "",
      image_vid_ref_vehi: "",
      image_vehi: "",
      video_vehi: "",

      visa_report: "",
      visa_report_desc: "",
      image_vid_ref_visa: "",
      image_visa: "",
      video_visa: "",

      yahoo_report: "",
      yahoo_report_desc: "",
      image_vid_ref_yaho: "",
      image_yaho: "",
      video_yaho: "",

      shoplifting_report: "",
      shoplifting_report_desc: "",
      image_vid_ref_shop: "",
      image_shop: "",
      video_shop: "",

      overInvoice_report: "",
      overInvoice_report_desc: "",
      image_vid_ref_over: "",
      image_over: "",
      video_over: "",

      falsification_report: "",
      falsification_report_desc: "",
      image_vid_ref_fals: "",
      image_fals: "",
      video_fals: "",

      userId: "",
      object_id: "",
      activeTab: 1,
      activeTabProgress: 1,
      progressValue: 25,
      uploading_trailer: false,
      uploading_trailer1: false,
      uploading_trailer2: false,
      uploading_trailer3: false,
      uploading_trailer4: false,
      uploading_trailer5: false,
      uploading_trailer6: false,
      uploading_trailer7: false,
      uploading_trailer8: false,
      uploading_trailer9: false,
      uploading_trailer10: false,
      uploading_trailer11: false,
      uploading_trailer12: false,
      uploading_trailer13: false,
      uploading_trailer14: false,
      uploading_trailer15: false,
      uploading_trailer16: false,
      uploading_trailer17: false,
      uploading_trailer18: false,
      uploading_trailer19: false,
      uploading_trailer20: false,
      uploading_trailer21: false,
      uploading_trailer22: false,
      uploading_trailer23: false,
      uploading_trailer24: false,
      uploading_trailer25: false,
      uploading_trailer26: false,
      uploading_trailer27: false,
      uploading_trailer28: false,
      uploading_trailer29: false,
      uploading_trailer30: false,
      uploading_trailer31: false,
    };
    // this.toggleTab.bind(this);
    this.toggleTabProgress.bind(this);
  }

  toggleTabProgress(tab) {
    if (this.state.activeTabProgress !== tab) {
      if (tab >= 1 && tab <= 4) {
        if (tab === 1) {
          this.setState({ progressValue: 25, activeTabProgress: tab });
        }
        if (tab === 2) {
          if (
            this.state.fname != "" &&
            this.state.lname != ""
            // this.state.bank_ac != "" &&
            // this.state.bvn != "" &&
            // this.state.cert != "" &&
            //this.state.dob != ""
            // this.state.driver_license != "" &&
            // this.state.email != "" &&
            // this.state.emp_id != "" &&
            // this.state.local_id != "" &&
            // this.state.national_id != "" &&
            // this.state.passport != "" &&
            // this.state.payroll != "" &&
            // this.state.plate_reg != "" &&
            // this.state.rc != "" &&
            // this.state.social_media != "" &&
            // this.state.state_id != "" &&
            // this.state.student_id != "" &&
            // this.state.tel != "" &&
            // this.state.tin != "" &&
            // this.state.vin != "" &&
            // this.state.fingerprints != "" &&
            // this.state.voter_reg != "" &&
            // this.state.image != ""
          ) {
            this.setState({
              activeTabProgress: tab,
            });
            this.setState({ progressValue: 50 });
          } else {
            this.setState({
              alert: true,
              message: "Please Fill Up All Mandatory Fields.",
              type: "warning",
            });
          }
        }
        if (tab === 3) {
          // if (
          //   this.state.report_419.details != "" &&
          //   this.state.academic_report.details != "" &&
          //   this.state.behavioral_report.details != "" &&
          //   this.state.child_abuse.details != "" &&
          //   this.state.credit_report.details != "" &&
          //   this.state.criminal_report.details != "" &&
          //   this.state.death_report.details != "" &&
          //   this.state.domestic_violent.details != "" &&
          //   this.state.fingerprint_report.details != "" &&
          //   this.state.fraud_alerts.details != "" &&
          //   this.state.ghost_worker.details != "" &&
          //   this.state.insurance_report.details != "" &&
          //   this.state.property_report.details != "" &&
          //   this.state.medical_report.details != ""
          // ) {
          this.setState({
            activeTabProgress: tab,
          });
          // } else {
          //   this.setState({
          //     alert: true,
          //     message: "Please Fill Up All Mandatory Fields.",
          //     type: "warning",
          //   });
          // }
          this.setState({ progressValue: 75 });
        }
        if (tab === 4) {
          // if (
          //   this.state.one_chance.details != "" &&
          //   this.state.police_report.details != "" &&
          //   this.state.professional_report.details != "" &&
          //   this.state.recommedations_report.details != "" &&
          //   this.state.rental_report.details != "" &&
          //   this.state.sexual_harrasement.details != "" &&
          //   this.state.social_capital.details != "" &&
          //   this.state.tax_clearance.details != "" &&
          //   this.state.tax_compliance_report.details != "" &&
          //   this.state.terrorism_report.details != "" &&
          //   this.state.traffic_offender_report.details != "" &&
          //   this.state.utilities_report.details != "" &&
          //   this.state.vehicle_report.details != "" &&
          //   this.state.visa_report.details != "" &&
          //   this.state.yahoo_report.details != "" &&
          //   this.state.shoplifting_report.details != "" &&
          //   this.state.overInvoice_report.details != "" &&
          //   this.state.falsification_report.details != ""
          // ) {

          this.submit();
          // } else {
          //   this.setState({
          //     alert: true,
          //     message: "Please Fill Up All Mandatory Fields.",
          //     type: "warning",
          //   });
          // }
          // this.setState({ progressValue: 100 });
        }
      }
    }
    setTimeout(() => {
      this.setState({
        alert: false,
        message: "",
        type: "",
      });
    }, 3000);
  }
  componentDidMount = async () => {
    // alert(this.state.edit_item_id);
    this.fetchData();
    this.checkUser();
  };

  handleClick = async () => {
    let data = {
      fname: this.state.fname,
      mname: this.state.mname,
      lname: this.state.lname,
      source:this.state.source,
      dob: this.state.dob,
      address: this.state.address,
      // bank_ac: this.state.bank_ac,
      bvn: this.state.bvn,
      emp_id: this.state.emp_id,
      // cert: this.state.cert,

      driver_license: this.state.driver_license,
      national_id: this.state.national_id,
      passport: this.state.passport,
      // payroll: this.state.payroll,
      plate_reg: this.state.plate_reg,
      rc: this.state.rc,
      social_media: this.state.social_media,
      // state_id: this.state.state_id,
      student_id: this.state.student_id,
      tel: this.state.tel,
      tin: this.state.tin,
      vin: this.state.vin,
      // fingerprints: this.state.fingerprints,
      voter_reg: this.state.voter_reg,
      image: this.state.image,
      report_419: this.state.report_419,
      report_419_desc: this.state.report_419_desc,
      img_video_ref: this.state.img_video_ref,
      image_repo: this.state.image_repo,
      video_repo: this.state.video_repo,
      academic_report: this.state.academic_report,
      academic_report_desc: this.state.academic_report_desc,
      image_vid_ref_acd: this.state.image_vid_ref_acd,
      image_acd: this.state.image_acd,
      video_acd: this.state.video_acd,
      behavioral_report: this.state.behavioral_report,
      behavioral_report_desc: this.state.behavioral_report_desc,
      image_vid_ref_bhv: this.state.image_vid_ref_bhv,
      image_bhv: this.state.image_bhv,
      video_bhv: this.state.video_bhv,
      child_abuse: this.state.child_abuse,
      child_abuse_desc: this.state.child_abuse_desc,
      image_vid_ref_child: this.state.image_vid_ref_child,
      image_child: this.state.image_child,
      video_child: this.state.video_child,
      credit_report: this.state.credit_report,
      credit_report_desc: this.state.credit_report_desc,
      image_vid_ref_cred: this.state.image_vid_ref_cred,
      image_cred: this.state.image_cred,
      video_cred: this.state.video_cred,
      criminal_report: this.state.criminal_report,
      criminal_report_desc: this.state.criminal_report_desc,
      image_vid_ref_crim: this.state.image_vid_ref_crim,
      image_crim: this.state.image_crim,
      video_crim: this.state.video_crim,
      death_report: this.state.death_report,
      death_report_desc: this.state.death_report_desc,
      image_vid_ref_deat: this.state.image_vid_ref_deat,
      image_deat: this.state.image_deat,
      video_deat: this.state.video_deat,
      domestic_violent: this.state.domestic_violent,
      domestic_violent_desc: this.state.domestic_violent_desc,
      image_vid_ref_dome: this.state.image_vid_ref_dome,
      image_dome: this.state.image_dome,
      video_dome: this.state.video_dome,
      fingerprint_report: this.state.fingerprint_report,
      fingerprint_report_desc: this.state.fingerprint_report_desc,
      image_vid_ref_fing: this.state.image_vid_ref_fing,
      image_fing: this.state.image_fing,
      video_fing: this.state.video_fing,
      fraud_alerts: this.state.fraud_alerts,
      fraud_alerts_desc: this.state.fraud_alerts_desc,
      image_vid_ref_frau: this.state.image_vid_ref_frau,
      image_frau: this.state.image_frau,
      video_frau: this.state.video_frau,
      ghost_worker: this.state.ghost_worker,
      ghost_worker_desc: this.state.ghost_worker_desc,
      image_vid_ref_ghos: this.state.image_vid_ref_ghos,
      image_ghos: this.state.image_ghos,
      video_ghos: this.state.video_ghos,
      insurance_report: this.state.insurance_report,
      insurance_report_desc: this.state.insurance_report_desc,
      image_vid_ref_insu: this.state.image_vid_ref_insu,
      image_insu: this.state.image_insu,
      video_insu: this.state.video_insu,
      property_report: this.state.property_report,
      property_report_desc: this.state.property_report_desc,
      image_vid_ref_prop: this.state.image_vid_ref_prop,
      image_prop: this.state.image_prop,
      video_prop: this.state.video_prop,
      medical_report: this.state.medical_report,
      medical_report_desc: this.state.medical_report_desc,
      image_vid_ref_medi: this.state.image_vid_ref_medi,
      image_medi: this.state.image_medi,
      video_medi: this.state.video_medi,
      one_chance: this.state.one_chance,
      one_chance_desc: this.state.one_chance_desc,
      image_vid_ref_onec: this.state.image_vid_ref_onec,
      image_onec: this.state.image_onec,
      video_onec: this.state.video_onec,
      police_report: this.state.police_report,
      police_report_desc: this.state.police_report_desc,
      image_vid_ref_poli: this.state.image_vid_ref_poli,
      image_poli: this.state.image_poli,
      video_poli: this.state.video_poli,
      professional_report: this.state.professional_report,
      professional_report_desc: this.state.professional_report_desc,
      image_vid_ref_prof: this.state.image_vid_ref_prof,
      image_prof: this.state.image_prof,
      video_prof: this.state.video_prof,
      recommedations_report: this.state.recommedations_report,
      recommedations_report_desc: this.state.recommedations_report_desc,
      image_vid_ref_reco: this.state.image_vid_ref_reco,
      image_reco: this.state.image_reco,
      video_reco: this.state.video_reco,
      rental_report: this.state.rental_report,
      rental_report_desc: this.state.rental_report_desc,
      image_vid_ref_rent: this.state.image_vid_ref_rent,
      image_rent: this.state.image_rent,
      video_rent: this.state.video_rent,
      sexual_harrasement: this.state.sexual_harrasement,
      sexual_harrasement_desc: this.state.sexual_harrasement_desc,
      image_vid_ref_sexu: this.state.image_vid_ref_sexu,
      image_sexu: this.state.image_sexu,
      video_sexu: this.state.video_sexu,
      social_capital: this.state.social_capital,
      social_capital_desc: this.state.social_capital_desc,
      image_vid_ref_soci: this.state.image_vid_ref_soci,
      image_soci: this.state.image_soci,
      video_soci: this.state.video_soci,
      tax_clearance: this.state.tax_clearance,
      tax_clearance_desc: this.state.tax_clearance_desc,
      image_vid_ref_taxc: this.state.image_vid_ref_taxc,
      image_taxc: this.state.image_taxc,
      video_taxc: this.state.video_taxc,
      tax_compliance_report: this.state.tax_compliance_report,
      tax_compliance_report_desc: this.state.tax_compliance_report_desc,
      image_vid_ref_taxco: this.state.image_vid_ref_taxco,
      image_taxco: this.state.image_taxco,
      video_taxco: this.state.video_taxco,
      terrorism_report: this.state.terrorism_report,
      terrorism_report_desc: this.state.terrorism_report_desc,
      image_vid_ref_terr: this.state.image_vid_ref_terr,
      image_terr: this.state.image_terr,
      video_terr: this.state.video_terr,
      traffic_offender_report: this.state.traffic_offender_report,
      traffic_offender_report_desc: this.state.traffic_offender_report_desc,
      image_vid_ref_traf: this.state.image_vid_ref_traf,
      image_traf: this.state.image_traf,
      video_traf: this.state.video_traf,
      utilities_report: this.state.utilities_report,
      utilities_report_desc: this.state.utilities_report_desc,
      image_vid_ref_util: this.state.image_vid_ref_util,
      image_util: this.state.image_util,
      video_util: this.state.video_util,
      vehicle_report: this.state.vehicle_report,
      vehicle_report_desc: this.state.vehicle_report_desc,
      image_vid_ref_vehi: this.state.image_vid_ref_vehi,
      image_vehi: this.state.image_vehi,
      video_vehi: this.state.video_vehi,
      visa_report: this.state.visa_report,
      visa_report_desc: this.state.visa_report_desc,
      image_vid_ref_visa: this.state.image_vid_ref_visa,
      image_visa: this.state.image_visa,
      video_visa: this.state.video_visa,
      yahoo_report: this.state.yahoo_report,
      yahoo_report_desc: this.state.yahoo_report_desc,
      image_vid_ref_yaho: this.state.image_vid_ref_yaho,
      image_yaho: this.state.image_yaho,
      video_yaho: this.state.video_yaho,
      shoplifting_report: this.state.shoplifting_report,
      shoplifting_report_desc: this.state.shoplifting_report_desc,
      image_vid_ref_shop: this.state.image_vid_ref_shop,
      image_shop: this.state.image_shop,
      video_shop: this.state.video_shop,
      overInvoice_report: this.state.overInvoice_report,
      overInvoice_report_desc: this.state.overInvoice_report_desc,
      image_vid_ref_over: this.state.image_vid_ref_over,
      image_over: this.state.image_over,
      video_over: this.state.video_over,
      falsification_report: this.state.falsification_report,
      falsification_report_desc: this.state.falsification_report_desc,
      image_vid_ref_fals: this.state.image_vid_ref_fals,
      image_fals: this.state.image_fals,
      video_fals: this.state.video_fals,
      admin_id: 2103041846164616,
      edit_id: this.state.object_id,
    }
    let result = await HttpClient.requestData("admin/report/update",
      "POST", data);
    console.log("savedata", result);
    if (result && result.status) {
      this.setState({
        alert: true,
        message: "Report Saved Succesfully",
        type: "success",
      })
    }

  }

  checkUser = async () => {
    let user = await reactLocalStorage.getObject("adminData");
    if (user != null && Object.keys(user).length > 0) {
      this.setState({ userId: user.id });
    } else {
    }
  };

  fetchData = async () => {
    let sendData = {
      id: this.state.edit_item_id,
    };
    let result = await HttpClient.requestData(
      "admin/report/edit",
      "POST",
      sendData
    );
    console.log("edit result", result);
    if (result && result.status) {
      this.setState({
        // page state
        object_id: result.data._id,
        fname: result.data.fname,
        mname: result.data.mname,
        source: result.data.source,
        address: result.data.address,
        lname: result.data.lname,
        bank_ac: result.data.bank_ac,
        bvn: result.data.bvn,
        cert: result.data.cert,
        dob: result.data.dob,
        driver_license: result.data.driver_license,
        email: result.data.email,
        emp_id: result.data.emp_id,
        local_id: result.data.local_id,
        national_id: result.data.national_id,
        passport: result.data.passport,
        payroll: result.data.payroll,
        plate_reg: result.data.plate_reg,
        rc: result.data.rc,
        social_media: result.data.social_media,
        state_id: result.data.state_id,
        student_id: result.data.student_id,
        tel: result.data.tel,
        tin: result.data.tin,
        vin: result.data.vin,
        fingerprints: result.data.fingerprints,
        voter_reg: result.data.voter_reg,
        image: result.data.image,
        report_419: result.data.report_419,
        report_419_desc: result.data.report_419_desc,
        img_video_ref: result.data.img_video_ref,
        image_repo: result.data.image_repo,
        video_repo: result.data.video_repo,
        academic_report: result.data.academic_report,
        academic_report_desc: result.data.academic_report_desc,
        image_vid_ref_acd: result.data.image_vid_ref_acd,
        image_acd: result.data.image_acd,
        video_acd: result.data.video_acd,
        behavioral_report: result.data.behavioral_report,
        behavioral_report_desc: result.data.behavioral_report_desc,
        image_vid_ref_bhv: result.data.image_vid_ref_bhv,
        image_bhv: result.data.image_bhv,
        video_bhv: result.data.video_bhv,
        child_abuse: result.data.child_abuse,
        child_abuse_desc: result.data.child_abuse_desc,
        image_vid_ref_child: result.data.image_vid_ref_child,
        image_child: result.data.image_child,
        video_child: result.data.video_child,
        credit_report: result.data.credit_report,
        credit_report_desc: result.data.credit_report_desc,
        image_vid_ref_cred: result.data.image_vid_ref_cred,
        image_cred: result.data.image_cred,
        video_cred: result.data.video_cred,
        criminal_report: result.data.criminal_report,
        criminal_report_desc: result.data.criminal_report_desc,
        image_vid_ref_crim: result.data.image_vid_ref_crim,
        image_crim: result.data.image_crim,
        video_crim: result.data.video_crim,
        death_report: result.data.death_report,
        death_report_desc: result.data.death_report_desc,
        image_vid_ref_deat: result.data.image_vid_ref_deat,
        image_deat: result.data.image_deat,
        video_deat: result.data.video_deat,
        domestic_violent: result.data.domestic_violent,
        domestic_violent_desc: result.data.domestic_violent_desc,
        image_vid_ref_dome: result.data.image_vid_ref_dome,
        image_dome: result.data.image_dome,
        video_dome: result.data.video_dome,
        fingerprint_report: result.data.fingerprint_report,
        fingerprint_report_desc: result.data.fingerprint_report_desc,
        image_vid_ref_fing: result.data.image_vid_ref_fing,
        image_fing: result.data.image_fing,
        video_fing: result.data.video_fing,
        fraud_alerts: result.data.fraud_alerts,
        fraud_alerts_desc: result.data.fraud_alerts_desc,
        image_vid_ref_frau: result.data.image_vid_ref_frau,
        image_frau: result.data.image_frau,
        video_frau: result.data.video_frau,
        ghost_worker: result.data.ghost_worker,
        ghost_worker_desc: result.data.ghost_worker_desc,
        image_vid_ref_ghos: result.data.image_vid_ref_ghos,
        image_ghos: result.data.image_ghos,
        video_ghos: result.data.video_ghos,
        insurance_report: result.data.insurance_report,
        insurance_report_desc: result.data.insurance_report_desc,
        image_vid_ref_insu: result.data.image_vid_ref_insu,
        image_insu: result.data.image_insu,
        video_insu: result.data.video_insu,
        property_report: result.data.property_report,
        property_report_desc: result.data.property_report_desc,
        image_vid_ref_prop: result.data.image_vid_ref_prop,
        image_prop: result.data.image_prop,
        video_prop: result.data.video_prop,
        medical_report: result.data.medical_report,
        medical_report_desc: result.data.medical_report_desc,
        image_vid_ref_medi: result.data.image_vid_ref_medi,
        image_medi: result.data.image_medi,
        video_medi: result.data.video_medi,
        one_chance: result.data.one_chance,
        one_chance_desc: result.data.one_chance_desc,
        image_vid_ref_onec: result.data.image_vid_ref_onec,
        image_onec: result.data.image_onec,
        video_onec: result.data.video_onec,
        police_report: result.data.police_report,
        police_report_desc: result.data.police_report_desc,
        image_vid_ref_poli: result.data.image_vid_ref_poli,
        image_poli: result.data.image_poli,
        video_poli: result.data.video_poli,
        professional_report: result.data.professional_report,
        professional_report_desc: result.data.professional_report_desc,
        image_vid_ref_prof: result.data.image_vid_ref_prof,
        image_prof: result.data.image_prof,
        video_prof: result.data.video_prof,
        recommedations_report: result.data.recommedations_report,
        recommedations_report_desc: result.data.recommedations_report_desc,
        image_vid_ref_reco: result.data.image_vid_ref_reco,
        image_reco: result.data.image_reco,
        video_reco: result.data.video_reco,
        rental_report: result.data.rental_report,
        rental_report_desc: result.data.rental_report_desc,
        image_vid_ref_rent: result.data.image_vid_ref_rent,
        image_rent: result.data.image_rent,
        video_rent: result.data.video_rent,
        sexual_harrasement: result.data.sexual_harrasement,
        sexual_harrasement_desc: result.data.sexual_harrasement_desc,
        image_vid_ref_sexu: result.data.image_vid_ref_sexu,
        image_sexu: result.data.image_sexu,
        video_sexu: result.data.video_sexu,
        social_capital: result.data.social_capital,
        social_capital_desc: result.data.social_capital_desc,
        image_vid_ref_soci: result.data.image_vid_ref_soci,
        image_soci: result.data.image_soci,
        video_soci: result.data.video_soci,
        tax_clearance: result.data.tax_clearance,
        tax_clearance_desc: result.data.tax_clearance_desc,
        image_vid_ref_taxc: result.data.image_vid_ref_taxc,
        image_taxc: result.data.image_taxc,
        video_taxc: result.data.video_taxc,
        tax_compliance_report: result.data.tax_compliance_report,
        tax_compliance_report_desc: result.data.tax_compliance_report_desc,
        image_vid_ref_taxco: result.data.image_vid_ref_taxco,
        image_taxco: result.data.image_taxco,
        video_taxco: result.data.video_taxco,
        terrorism_report: result.data.terrorism_report,
        terrorism_report_desc: result.data.terrorism_report_desc,
        image_vid_ref_terr: result.data.image_vid_ref_terr,
        image_terr: result.data.image_terr,
        video_terr: result.data.video_terr,
        traffic_offender_report: result.data.traffic_offender_report,
        traffic_offender_report_desc: result.data.traffic_offender_report_desc,
        image_vid_ref_traf: result.data.image_vid_ref_traf,
        image_traf: result.data.image_traf,
        video_traf: result.data.video_traf,
        utilities_report: result.data.utilities_report,
        utilities_report_desc: result.data.utilities_report_desc,
        image_vid_ref_util: result.data.image_vid_ref_util,
        image_util: result.data.image_util,
        video_util: result.data.video_util,
        vehicle_report: result.data.vehicle_report,
        vehicle_report_desc: result.data.vehicle_report_desc,
        image_vid_ref_vehi: result.data.image_vid_ref_vehi,
        image_vehi: result.data.image_vehi,
        video_vehi: result.data.video_vehi,
        visa_report: result.data.visa_report,
        visa_report_desc: result.data.visa_report_desc,
        image_vid_ref_visa: result.data.image_vid_ref_visa,
        image_visa: result.data.image_visa,
        video_visa: result.data.video_visa,
        yahoo_report: result.data.yahoo_report,
        yahoo_report_desc: result.data.yahoo_report_desc,
        image_vid_ref_yaho: result.data.image_vid_ref_yaho,
        image_yaho: result.data.image_yaho,
        video_yaho: result.data.video_yaho,
        shoplifting_report: result.data.shoplifting_report,
        shoplifting_report_desc: result.data.shoplifting_report_desc,
        image_vid_ref_shop: result.data.image_vid_ref_shop,
        image_shop: result.data.image_shop,
        video_shop: result.data.video_shop,

        overInvoice_report: result.data.overInvoice_report,
        overInvoice_report_desc: result.data.overInvoice_report_desc,
        image_vid_ref_over: result.data.image_vid_ref_over,
        image_over: result.data.image_over,
        video_over: result.data.video_over,

        falsification_report: result.data.falsification_report,
        falsification_report_desc: result.data.falsification_report_desc,
        image_vid_ref_fals: result.data.image_vid_ref_fals,
        image_fals: result.data.image_fals,
        video_fals: result.data.video_fals,

      });
    }
  };

  uploadImage = async (e, type) => {
    let file = e.target.files[0];
    console.log(file);
    let dataSend = { buckate: true, buckate_name: "appIcon" };

    let result = await HttpClient.newFileUpload(
      "image-upload/fromImage",
      file,
      dataSend
    );
    console.log("result", result);
    if (result && result.status) {
      if (type == "report_419") {
        this.state.image_repo = result.url;
      }
      if (type == "academic_report") {
        this.state.image_acd = result.url;
      }
      if (type == "behavioral_report") {
        this.state.image_bhv = result.url;
      }
      if (type == "child_abuse") {
        this.state.image_child = result.url;
      }
      if (type == "credit_report") {
        this.state.image_cred = result.url;
      }
      if (type == "criminal_report") {
        this.state.image_crim = result.url;
      }
      if (type == "death_report") {
        this.state.image_deat = result.url;
      }
      if (type == "domestic_violent") {
        this.state.image_dome = result.url;
      }
      if (type == "fingerprint_report") {
        this.state.image_fing = result.url;
      }
      if (type == "fraud_alerts") {
        this.state.image_frau = result.url;
      }
      if (type == "ghost_worker") {
        this.state.image_ghos = result.url;
      }
      if (type == "insurance_report") {
        this.state.image_insu = result.url;
      }
      if (type == "property_report") {
        this.state.image_prop = result.url;
      }
      if (type == "medical_report") {
        this.state.image_medi = result.url;
      }
      if (type == "one_chance") {
        this.state.image_onec = result.url;
      }
      if (type == "police_report") {
        this.state.image_poli = result.url;
      }
      if (type == "professional_report") {
        this.state.image_prof = result.url;
      }
      if (type == "recommedations_report") {
        this.state.image_reco = result.url;
      }
      if (type == "rental_report") {
        this.state.image_rent = result.url;
      }
      if (type == "sexual_harrasement") {
        this.state.image_sexu = result.url;
      }
      if (type == "social_capital") {
        this.state.image_soci = result.url;
      }
      if (type == "tax_clearance") {
        this.state.image_taxc = result.url;
      }
      if (type == "tax_compliance_report") {
        this.state.image_taxco = result.url;
      }
      if (type == "terrorism_report") {
        this.state.image_terr = result.url;
      }
      if (type == "traffic_offender_report") {
        this.state.image_traf = result.url;
      }
      if (type == "utilities_report") {
        this.state.image_util = result.url;
      }
      if (type == "vehicle_report") {
        this.state.image_vehi = result.url;
      }
      if (type == "visa_report") {
        this.state.image_visa = result.url;
      }
      if (type == "yahoo_report") {
        this.state.image_yaho = result.url;
      }
      if (type == "shoplifting_report") {
        this.state.image_shop = result.url;
      }
      if (type == "overInvoice_report") {
        this.state.image_over = result.url;
      }
      if (type == "falsification_report") {
        this.state.image_fals = result.url;
      }
      if (type == "image") {
        this.setState({ image: result.url });
        console.log("image", this.state.image);
      }

      this.setState({});
    }
  };

  uploadVideo = async (val, type) => {
    let file = val.target.files[0];
    console.log(file);
    if (type == "report_419") {
      this.state.uploading_trailer = true;
    }
    if (type == "academic_report") {
      this.state.uploading_trailer1 = true;
    }
    if (type == "behavioral_report") {
      this.state.uploading_trailer2 = true;
    }
    if (type == "child_abuse") {
      this.state.uploading_trailer3 = true;
    }
    if (type == "credit_report") {
      this.state.uploading_trailer4 = true;
    }
    if (type == "criminal_report") {
      this.state.uploading_trailer5 = true;
    }
    if (type == "death_report") {
      this.state.uploading_trailer6 = true;
    }
    if (type == "domestic_violent") {
      this.state.uploading_trailer7 = true;
    }
    if (type == "fingerprint_report") {
      this.state.uploading_trailer8 = true;
    }
    if (type == "fraud_alerts") {
      this.state.uploading_trailer9 = true;
    }
    if (type == "ghost_worker") {
      this.state.uploading_trailer10 = true;
    }
    if (type == "insurance_report") {
      this.state.uploading_trailer11 = true;
    }
    if (type == "property_report") {
      this.state.uploading_trailer12 = true;
    }
    if (type == "medical_report") {
      this.state.uploading_trailer13 = true;
    }
    if (type == "one_chance") {
      this.state.uploading_trailer14 = true;
    }
    if (type == "police_report") {
      this.state.uploading_trailer15 = true;
    }
    if (type == "professional_report") {
      this.state.uploading_trailer16 = true;
    }
    if (type == "recommedations_report") {
      this.state.uploading_trailer17 = true;
    }
    if (type == "rental_report") {
      this.state.uploading_trailer18 = true;
    }
    if (type == "sexual_harrasement") {
      this.state.uploading_trailer19 = true;
    }
    if (type == "social_capital") {
      this.state.uploading_trailer20 = true;
    }
    if (type == "tax_clearance") {
      this.state.uploading_trailer21 = true;
    }
    if (type == "tax_compliance_report") {
      this.state.uploading_trailer22 = true;
    }
    if (type == "terrorism_report") {
      this.state.uploading_trailer23 = true;
    }
    if (type == "traffic_offender_report") {
      this.state.uploading_trailer24 = true;
    }
    if (type == "utilities_report") {
      this.state.uploading_trailer25 = true;
    }
    if (type == "vehicle_report") {
      this.state.uploading_trailer26 = true;
    }
    if (type == "visa_report") {
      this.state.uploading_trailer27 = true;
    }
    if (type == "yahoo_report") {
      this.state.uploading_trailer28 = true;
    }
    if (type == "shoplifting_report") {
      this.state.uploading_trailer29 = true;
    }
    if (type == "overInvoice_report") {
      this.state.uploading_trailer30 = true;
    }
    if (type == "falsification_report") {
      this.state.uploading_trailer31 = true;
    }
    this.setState({});
    let dataSend = { buckate: true, buckate_name: "appIcon" };

    let result = await HttpClient.newFileUpload(
      "image-upload/fromVideo",
      file,
      dataSend
    );
    console.log("result", result);
    if (result && result.status) {
      if (type == "report_419") {
        this.state.video_repo = result.url;
        this.state.uploading_trailer = false;
      }
      if (type == "academic_report") {
        this.state.video_acd = result.url;
        this.state.uploading_trailer1 = false;
      }
      if (type == "behavioral_report") {
        this.state.video_bhv = result.url;
        this.state.uploading_trailer2 = false;
      }
      if (type == "child_abuse") {
        this.state.video_child = result.url;
        this.state.uploading_trailer3 = false;
      }
      if (type == "credit_report") {
        this.state.video_cred = result.url;
        this.state.uploading_trailer4 = false;
      }
      if (type == "criminal_report") {
        this.state.video_crim = result.url;
        this.state.uploading_trailer5 = false;
      }
      if (type == "death_report") {
        this.state.video_deat = result.url;
        this.state.uploading_trailer6 = false;
      }
      if (type == "domestic_violent") {
        this.state.video_dome = result.url;
        this.state.uploading_trailer7 = false;
      }
      if (type == "fingerprint_report") {
        this.state.video_fing = result.url;
        this.state.uploading_trailer8 = false;
      }
      if (type == "fraud_alerts") {
        this.state.video_frau = result.url;
        this.state.uploading_trailer9 = false;
      }
      if (type == "ghost_worker") {
        this.state.video_ghos = result.url;
        this.state.uploading_trailer10 = false;
      }
      if (type == "insurance_report") {
        this.state.video_insu = result.url;
        this.state.uploading_trailer11 = false;
      }
      if (type == "property_report") {
        this.state.video_prop = result.url;
        this.state.uploading_trailer12 = false;
      }
      if (type == "medical_report") {
        this.state.video_medi = result.url;
        this.state.uploading_trailer13 = false;
      }
      if (type == "one_chance") {
        this.state.video_onec = result.url;
        this.state.uploading_trailer14 = false;
      }
      if (type == "police_report") {
        this.state.video_poli = result.url;
        this.state.uploading_trailer15 = false;
      }
      if (type == "professional_report") {
        this.state.video_prof = result.url;
        this.state.uploading_trailer16 = false;
      }
      if (type == "recommedations_report") {
        this.state.video_reco = result.url;
        this.state.uploading_trailer17 = false;
      }
      if (type == "rental_report") {
        this.state.video_rent = result.url;
        this.state.uploading_trailer18 = false;
      }
      if (type == "sexual_harrasement") {
        this.state.video_sexu = result.url;
        this.state.uploading_trailer19 = false;
      }
      if (type == "social_capital") {
        this.state.video_soci = result.url;
        this.state.uploading_trailer20 = false;
      }
      if (type == "tax_clearance") {
        this.state.video_taxc = result.url;
        this.state.uploading_trailer21 = false;
      }
      if (type == "tax_compliance_report") {
        this.state.video_taxco = result.url;
        this.state.uploading_trailer22 = false;
      }
      if (type == "terrorism_report") {
        this.state.video_terr = result.url;
        this.state.uploading_trailer23 = false;
      }
      if (type == "traffic_offender_report") {
        this.state.video_traf = result.url;
        this.state.uploading_trailer24 = false;
      }
      if (type == "utilities_report") {
        this.state.video_util = result.url;
        this.state.uploading_trailer25 = false;
      }
      if (type == "vehicle_report") {
        this.state.video_vehi = result.url;
        this.state.uploading_trailer26 = false;
      }
      if (type == "visa_report") {
        this.state.video_visa = result.url;
        this.state.uploading_trailer27 = false;
      }
      if (type == "yahoo_report") {
        this.state.video_yaho = result.url;
        this.state.uploading_trailer28 = false;
      }
      if (type == "shoplifting_report") {
        this.state.video_shop = result.url;
        this.state.uploading_trailer29 = false;
      }
      if (type == "overInvoice_report") {
        this.state.video_over = result.url;
        this.state.uploading_trailer30 = false;
      }
      if (type == "falsification_report") {
        this.state.video_fals = result.url;
        this.state.uploading_trailer31 = false;
      }
      this.setState({});
    }
  };
  submit = async () => {
    if (
      this.state.fname != "" &&
      this.state.lname != ""
      // this.state.bank_ac != "" &&
      // this.state.bvn != "" &&
      // this.state.cert != "" &&
      //this.state.dob != "" &&
      // this.state.driver_license != "" &&
      // this.state.email != "" &&
      // this.state.emp_id != "" &&
      // this.state.local_id != "" &&
      // this.state.national_id != "" &&
      // this.state.passport != "" &&
      // this.state.payroll != "" &&
      // this.state.plate_reg != "" &&
      // this.state.rc != "" &&
      // this.state.social_media != "" &&
      // this.state.state_id != "" &&
      // this.state.student_id != "" &&
      // this.state.tel != "" &&
      // this.state.tin != "" &&
      // this.state.vin != "" &&
      // this.state.fingerprints != "" &&
      // this.state.voter_reg != "" &&
      // this.state.image != "" &&
      // this.state.report_419.details != "" &&
      // this.state.academic_report.details != "" &&
      // this.state.behavioral_report.details != "" &&
      // this.state.child_abuse.details != "" &&
      // this.state.credit_report.details != "" &&
      // this.state.criminal_report.details != "" &&
      // this.state.death_report.details != "" &&
      // this.state.domestic_violent.details != "" &&
      // this.state.fingerprint_report.details != "" &&
      // this.state.fraud_alerts.details != "" &&
      // this.state.ghost_worker.details != "" &&
      // this.state.insurance_report.details != "" &&
      // this.state.property_report.details != "" &&
      // this.state.medical_report.details != "" &&
      // this.state.one_chance.details != "" &&
      // this.state.police_report.details != "" &&
      // this.state.professional_report.details != "" &&
      // this.state.recommedations_report.details != "" &&
      // this.state.rental_report.details != "" &&
      // this.state.sexual_harrasement.details != "" &&
      // this.state.social_capital.details != "" &&
      // this.state.tax_clearance.details != "" &&
      // this.state.tax_compliance_report.details != "" &&
      // this.state.terrorism_report.details != "" &&
      // this.state.traffic_offender_report.details != "" &&
      // this.state.utilities_report.details != "" &&
      // this.state.vehicle_report.details != "" &&
      // this.state.visa_report.details != "" &&
      // this.state.yahoo_report.details != "" &&
      // this.state.shoplifting_report.details != "" &&
      // this.state.overInvoice_report.details != "" &&
      // this.state.falsification_report.details != ""
    ) {
      let data = null;
      let result = null;

      if (this.state.edit_enable == false) {
        data = {
          fname: this.state.fname,
          lname: this.state.lname,
          source:this.state.source,
          bank_ac: this.state.bank_ac,
          bvn: this.state.bvn,
          cert: this.state.cert,
          dob: this.state.dob,
          driver_license: this.state.driver_license,
          email: this.state.email,
          emp_id: this.state.emp_id,
          local_id: this.state.local_id,
          national_id: this.state.national_id,
          passport: this.state.passport,
          payroll: this.state.payroll,
          plate_reg: this.state.plate_reg,
          rc: this.state.rc,
          social_media: this.state.social_media,
          state_id: this.state.state_id,
          student_id: this.state.student_id,
          tel: this.state.tel,
          tin: this.state.tin,
          vin: this.state.vin,
          fingerprints: this.state.fingerprints,
          voter_reg: this.state.voter_reg,
          image: this.state.image,
          report_419: this.state.report_419,
          report_419_desc: this.state.report_419_desc,
          img_video_ref: this.state.img_video_ref,
          image_repo: this.state.image_repo,
          video_repo: this.state.video_repo,
          academic_report: this.state.academic_report,
          academic_report_desc: this.state.academic_report_desc,
          image_vid_ref_acd: this.state.image_vid_ref_acd,
          image_acd: this.state.image_acd,
          video_acd: this.state.video_acd,
          behavioral_report: this.state.behavioral_report,
          behavioral_report_desc: this.state.behavioral_report_desc,
          image_vid_ref_bhv: this.state.image_vid_ref_bhv,
          image_bhv: this.state.image_bhv,
          video_bhv: this.state.video_bhv,
          child_abuse: this.state.child_abuse,
          child_abuse_desc: this.state.child_abuse_desc,
          image_vid_ref_child: this.state.image_vid_ref_child,
          image_child: this.state.image_child,
          video_child: this.state.video_child,
          credit_report: this.state.credit_report,
          credit_report_desc: this.state.credit_report_desc,
          image_vid_ref_cred: this.state.image_vid_ref_cred,
          image_cred: this.state.image_cred,
          video_cred: this.state.video_cred,
          criminal_report: this.state.criminal_report,
          criminal_report_desc: this.state.criminal_report_desc,
          image_vid_ref_crim: this.state.image_vid_ref_crim,
          image_crim: this.state.image_crim,
          video_crim: this.state.video_crim,
          death_report: this.state.death_report,
          death_report_desc: this.state.death_report_desc,
          image_vid_ref_deat: this.state.image_vid_ref_deat,
          image_deat: this.state.image_deat,
          video_deat: this.state.video_deat,
          domestic_violent: this.state.domestic_violent,
          domestic_violent_desc: this.state.domestic_violent_desc,
          image_vid_ref_dome: this.state.image_vid_ref_dome,
          image_dome: this.state.image_dome,
          video_dome: this.state.video_dome,
          fingerprint_report: this.state.fingerprint_report,
          fingerprint_report_desc: this.state.fingerprint_report_desc,
          image_vid_ref_fing: this.state.image_vid_ref_fing,
          image_fing: this.state.image_fing,
          video_fing: this.state.video_fing,
          fraud_alerts: this.state.fraud_alerts,
          fraud_alerts_desc: this.state.fraud_alerts_desc,
          image_vid_ref_frau: this.state.image_vid_ref_frau,
          image_frau: this.state.image_frau,
          video_frau: this.state.video_frau,
          ghost_worker: this.state.ghost_worker,
          ghost_worker_desc: this.state.ghost_worker_desc,
          image_vid_ref_ghos: this.state.image_vid_ref_ghos,
          image_ghos: this.state.image_ghos,
          video_ghos: this.state.video_ghos,
          insurance_report: this.state.insurance_report,
          insurance_report_desc: this.state.insurance_report_desc,
          image_vid_ref_insu: this.state.image_vid_ref_insu,
          image_insu: this.state.image_insu,
          video_insu: this.state.video_insu,
          property_report: this.state.property_report,
          property_report_desc: this.state.property_report_desc,
          image_vid_ref_prop: this.state.image_vid_ref_prop,
          image_prop: this.state.image_prop,
          video_prop: this.state.video_prop,
          medical_report: this.state.medical_report,
          medical_report_desc: this.state.medical_report_desc,
          image_vid_ref_medi: this.state.image_vid_ref_medi,
          image_medi: this.state.image_medi,
          video_medi: this.state.video_medi,
          one_chance: this.state.one_chance,
          one_chance_desc: this.state.one_chance_desc,
          image_vid_ref_onec: this.state.image_vid_ref_onec,
          image_onec: this.state.image_onec,
          video_onec: this.state.video_onec,
          police_report: this.state.police_report,
          police_report_desc: this.state.police_report_desc,
          image_vid_ref_poli: this.state.image_vid_ref_poli,
          image_poli: this.state.image_poli,
          video_poli: this.state.video_poli,
          professional_report: this.state.professional_report,
          professional_report_desc: this.state.professional_report_desc,
          image_vid_ref_prof: this.state.image_vid_ref_prof,
          image_prof: this.state.image_prof,
          video_prof: this.state.video_prof,
          recommedations_report: this.state.recommedations_report,
          recommedations_report_desc: this.state.recommedations_report_desc,
          image_vid_ref_reco: this.state.image_vid_ref_reco,
          image_reco: this.state.image_reco,
          video_reco: this.state.video_reco,
          rental_report: this.state.rental_report,
          rental_report_desc: this.state.rental_report_desc,
          image_vid_ref_rent: this.state.image_vid_ref_rent,
          image_rent: this.state.image_rent,
          video_rent: this.state.video_rent,
          sexual_harrasement: this.state.sexual_harrasement,
          sexual_harrasement_desc: this.state.sexual_harrasement_desc,
          image_vid_ref_sexu: this.state.image_vid_ref_sexu,
          image_sexu: this.state.image_sexu,
          video_sexu: this.state.video_sexu,
          social_capital: this.state.social_capital,
          social_capital_desc: this.state.social_capital_desc,
          image_vid_ref_soci: this.state.image_vid_ref_soci,
          image_soci: this.state.image_soci,
          video_soci: this.state.video_soci,
          tax_clearance: this.state.tax_clearance,
          tax_clearance_desc: this.state.tax_clearance_desc,
          image_vid_ref_taxc: this.state.image_vid_ref_taxc,
          image_taxc: this.state.image_taxc,
          video_taxc: this.state.video_taxc,
          tax_compliance_report: this.state.tax_compliance_report,
          tax_compliance_report_desc: this.state.tax_compliance_report_desc,
          image_vid_ref_taxco: this.state.image_vid_ref_taxco,
          image_taxco: this.state.image_taxco,
          video_taxco: this.state.video_taxco,
          terrorism_report: this.state.terrorism_report,
          terrorism_report_desc: this.state.terrorism_report_desc,
          image_vid_ref_terr: this.state.image_vid_ref_terr,
          image_terr: this.state.image_terr,
          video_terr: this.state.video_terr,
          traffic_offender_report: this.state.traffic_offender_report,
          traffic_offender_report_desc: this.state.traffic_offender_report_desc,
          image_vid_ref_traf: this.state.image_vid_ref_traf,
          image_traf: this.state.image_traf,
          video_traf: this.state.video_traf,
          utilities_report: this.state.utilities_report,
          utilities_report_desc: this.state.utilities_report_desc,
          image_vid_ref_util: this.state.image_vid_ref_util,
          image_util: this.state.image_util,
          video_util: this.state.video_util,
          vehicle_report: this.state.vehicle_report,
          vehicle_report_desc: this.state.vehicle_report_desc,
          image_vid_ref_vehi: this.state.image_vid_ref_vehi,
          image_vehi: this.state.image_vehi,
          video_vehi: this.state.video_vehi,
          visa_report: this.state.visa_report,
          visa_report_desc: this.state.visa_report_desc,
          image_vid_ref_visa: this.state.image_vid_ref_visa,
          image_visa: this.state.image_visa,
          video_visa: this.state.video_visa,
          yahoo_report: this.state.yahoo_report,
          yahoo_report_desc: this.state.yahoo_report_desc,
          image_vid_ref_yaho: this.state.image_vid_ref_yaho,
          image_yaho: this.state.image_yaho,
          video_yaho: this.state.video_yaho,
          shoplifting_report: this.state.shoplifting_report,
          shoplifting_report_desc: this.state.shoplifting_report_desc,
          image_vid_ref_shop: this.state.image_vid_ref_shop,
          image_shop: this.state.image_shop,
          video_shop: this.state.video_shop,

          overInvoice_report: this.state.overInvoice_report,
          overInvoice_report_desc: this.state.overInvoice_report_desc,
          image_vid_ref_over: this.state.image_vid_ref_over,
          image_over: this.state.image_over,
          video_over: this.state.video_over,

          falsification_report: this.state.falsification_report,
          falsification_report_desc: this.state.falsification_report_desc,
          image_vid_ref_fals: this.state.image_vid_ref_fals,
          image_fals: this.state.image_fals,
          video_fals: this.state.video_fals,
          // report_419: this.state.report_419,
          // academic_report: this.state.academic_report,
          // behavioral_report: this.state.behavioral_report,
          // child_abuse: this.state.child_abuse,
          // credit_report: this.state.credit_report,

          // criminal_report: this.state.criminal_report,
          // death_report: this.state.death_report,
          // domestic_violent: this.state.domestic_violent,
          // fingerprint_report: this.state.fingerprint_report,
          // fraud_alerts: this.state.fraud_alerts,
          // ghost_worker: this.state.ghost_worker,
          // insurance_report: this.state.insurance_report,
          // property_report: this.state.property_report,
          // medical_report: this.state.medical_report,
          // one_chance: this.state.one_chance,
          // police_report: this.state.police_report,
          // professional_report: this.state.professional_report,
          // recommedations_report: this.state.recommedations_report,
          // rental_report: this.state.rental_report,
          // sexual_harrasement: this.state.sexual_harrasement,
          // social_capital: this.state.social_capital,
          // tax_clearance: this.state.tax_clearance,
          // tax_compliance_report: this.state.tax_compliance_report,
          // terrorism_report: this.state.terrorism_report,
          // traffic_offender_report: this.state.traffic_offender_report,
          // utilities_report: this.state.utilities_report,
          // vehicle_report: this.state.vehicle_report,
          // visa_report: this.state.visa_report,
          // yahoo_report: this.state.yahoo_report,
          // shoplifting_report: this.state.shoplifting_report,
          // overInvoice_report: this.state.overInvoice_report,
          // falsification_report: this.state.falsification_report,
          admin_id: this.state.userId,
        };
        console.log(data.source,"sourceeeee");
        result = await HttpClient.requestData("admin/report", "POST", data);
      } else {
        console.log(data.source,"sourceeeee");
        data = {
          fname: this.state.fname,
          mname: this.state.mname,
          lname: this.state.lname,
          source:this.state.source,
          dob: this.state.dob,
          address: this.state.address,
          // bank_ac: this.state.bank_ac,
          bvn: this.state.bvn,
          emp_id: this.state.emp_id,
          // cert: this.state.cert,

          driver_license: this.state.driver_license,
          // email: this.state.email,

          // local_id: this.state.local_id,
          national_id: this.state.national_id,
          passport: this.state.passport,
          // payroll: this.state.payroll,
          plate_reg: this.state.plate_reg,
          rc: this.state.rc,
          social_media: this.state.social_media,
          // state_id: this.state.state_id,
          student_id: this.state.student_id,
          tel: this.state.tel,
          tin: this.state.tin,
          vin: this.state.vin,
          // fingerprints: this.state.fingerprints,
          voter_reg: this.state.voter_reg,
          image: this.state.image,
          report_419: this.state.report_419,
          report_419_desc: this.state.report_419_desc,
          img_video_ref: this.state.img_video_ref,
          image_repo: this.state.image_repo,
          video_repo: this.state.video_repo,
          academic_report: this.state.academic_report,
          academic_report_desc: this.state.academic_report_desc,
          image_vid_ref_acd: this.state.image_vid_ref_acd,
          image_acd: this.state.image_acd,
          video_acd: this.state.video_acd,
          behavioral_report: this.state.behavioral_report,
          behavioral_report_desc: this.state.behavioral_report_desc,
          image_vid_ref_bhv: this.state.image_vid_ref_bhv,
          image_bhv: this.state.image_bhv,
          video_bhv: this.state.video_bhv,
          child_abuse: this.state.child_abuse,
          child_abuse_desc: this.state.child_abuse_desc,
          image_vid_ref_child: this.state.image_vid_ref_child,
          image_child: this.state.image_child,
          video_child: this.state.video_child,
          credit_report: this.state.credit_report,
          credit_report_desc: this.state.credit_report_desc,
          image_vid_ref_cred: this.state.image_vid_ref_cred,
          image_cred: this.state.image_cred,
          video_cred: this.state.video_cred,
          criminal_report: this.state.criminal_report,
          criminal_report_desc: this.state.criminal_report_desc,
          image_vid_ref_crim: this.state.image_vid_ref_crim,
          image_crim: this.state.image_crim,
          video_crim: this.state.video_crim,
          death_report: this.state.death_report,
          death_report_desc: this.state.death_report_desc,
          image_vid_ref_deat: this.state.image_vid_ref_deat,
          image_deat: this.state.image_deat,
          video_deat: this.state.video_deat,
          domestic_violent: this.state.domestic_violent,
          domestic_violent_desc: this.state.domestic_violent_desc,
          image_vid_ref_dome: this.state.image_vid_ref_dome,
          image_dome: this.state.image_dome,
          video_dome: this.state.video_dome,
          fingerprint_report: this.state.fingerprint_report,
          fingerprint_report_desc: this.state.fingerprint_report_desc,
          image_vid_ref_fing: this.state.image_vid_ref_fing,
          image_fing: this.state.image_fing,
          video_fing: this.state.video_fing,
          fraud_alerts: this.state.fraud_alerts,
          fraud_alerts_desc: this.state.fraud_alerts_desc,
          image_vid_ref_frau: this.state.image_vid_ref_frau,
          image_frau: this.state.image_frau,
          video_frau: this.state.video_frau,
          ghost_worker: this.state.ghost_worker,
          ghost_worker_desc: this.state.ghost_worker_desc,
          image_vid_ref_ghos: this.state.image_vid_ref_ghos,
          image_ghos: this.state.image_ghos,
          video_ghos: this.state.video_ghos,
          insurance_report: this.state.insurance_report,
          insurance_report_desc: this.state.insurance_report_desc,
          image_vid_ref_insu: this.state.image_vid_ref_insu,
          image_insu: this.state.image_insu,
          video_insu: this.state.video_insu,
          property_report: this.state.property_report,
          property_report_desc: this.state.property_report_desc,
          image_vid_ref_prop: this.state.image_vid_ref_prop,
          image_prop: this.state.image_prop,
          video_prop: this.state.video_prop,
          medical_report: this.state.medical_report,
          medical_report_desc: this.state.medical_report_desc,
          image_vid_ref_medi: this.state.image_vid_ref_medi,
          image_medi: this.state.image_medi,
          video_medi: this.state.video_medi,
          one_chance: this.state.one_chance,
          one_chance_desc: this.state.one_chance_desc,
          image_vid_ref_onec: this.state.image_vid_ref_onec,
          image_onec: this.state.image_onec,
          video_onec: this.state.video_onec,
          police_report: this.state.police_report,
          police_report_desc: this.state.police_report_desc,
          image_vid_ref_poli: this.state.image_vid_ref_poli,
          image_poli: this.state.image_poli,
          video_poli: this.state.video_poli,
          professional_report: this.state.professional_report,
          professional_report_desc: this.state.professional_report_desc,
          image_vid_ref_prof: this.state.image_vid_ref_prof,
          image_prof: this.state.image_prof,
          video_prof: this.state.video_prof,
          recommedations_report: this.state.recommedations_report,
          recommedations_report_desc: this.state.recommedations_report_desc,
          image_vid_ref_reco: this.state.image_vid_ref_reco,
          image_reco: this.state.image_reco,
          video_reco: this.state.video_reco,
          rental_report: this.state.rental_report,
          rental_report_desc: this.state.rental_report_desc,
          image_vid_ref_rent: this.state.image_vid_ref_rent,
          image_rent: this.state.image_rent,
          video_rent: this.state.video_rent,
          sexual_harrasement: this.state.sexual_harrasement,
          sexual_harrasement_desc: this.state.sexual_harrasement_desc,
          image_vid_ref_sexu: this.state.image_vid_ref_sexu,
          image_sexu: this.state.image_sexu,
          video_sexu: this.state.video_sexu,
          social_capital: this.state.social_capital,
          social_capital_desc: this.state.social_capital_desc,
          image_vid_ref_soci: this.state.image_vid_ref_soci,
          image_soci: this.state.image_soci,
          video_soci: this.state.video_soci,
          tax_clearance: this.state.tax_clearance,
          tax_clearance_desc: this.state.tax_clearance_desc,
          image_vid_ref_taxc: this.state.image_vid_ref_taxc,
          image_taxc: this.state.image_taxc,
          video_taxc: this.state.video_taxc,
          tax_compliance_report: this.state.tax_compliance_report,
          tax_compliance_report_desc: this.state.tax_compliance_report_desc,
          image_vid_ref_taxco: this.state.image_vid_ref_taxco,
          image_taxco: this.state.image_taxco,
          video_taxco: this.state.video_taxco,
          terrorism_report: this.state.terrorism_report,
          terrorism_report_desc: this.state.terrorism_report_desc,
          image_vid_ref_terr: this.state.image_vid_ref_terr,
          image_terr: this.state.image_terr,
          video_terr: this.state.video_terr,
          traffic_offender_report: this.state.traffic_offender_report,
          traffic_offender_report_desc: this.state.traffic_offender_report_desc,
          image_vid_ref_traf: this.state.image_vid_ref_traf,
          image_traf: this.state.image_traf,
          video_traf: this.state.video_traf,
          utilities_report: this.state.utilities_report,
          utilities_report_desc: this.state.utilities_report_desc,
          image_vid_ref_util: this.state.image_vid_ref_util,
          image_util: this.state.image_util,
          video_util: this.state.video_util,
          vehicle_report: this.state.vehicle_report,
          vehicle_report_desc: this.state.vehicle_report_desc,
          image_vid_ref_vehi: this.state.image_vid_ref_vehi,
          image_vehi: this.state.image_vehi,
          video_vehi: this.state.video_vehi,
          visa_report: this.state.visa_report,
          visa_report_desc: this.state.visa_report_desc,
          image_vid_ref_visa: this.state.image_vid_ref_visa,
          image_visa: this.state.image_visa,
          video_visa: this.state.video_visa,
          yahoo_report: this.state.yahoo_report,
          yahoo_report_desc: this.state.yahoo_report_desc,
          image_vid_ref_yaho: this.state.image_vid_ref_yaho,
          image_yaho: this.state.image_yaho,
          video_yaho: this.state.video_yaho,
          shoplifting_report: this.state.shoplifting_report,
          shoplifting_report_desc: this.state.shoplifting_report_desc,
          image_vid_ref_shop: this.state.image_vid_ref_shop,
          image_shop: this.state.image_shop,
          video_shop: this.state.video_shop,
          overInvoice_report: this.state.overInvoice_report,
          overInvoice_report_desc: this.state.overInvoice_report_desc,
          image_vid_ref_over: this.state.image_vid_ref_over,
          image_over: this.state.image_over,
          video_over: this.state.video_over,
          falsification_report: this.state.falsification_report,
          falsification_report_desc: this.state.falsification_report_desc,
          image_vid_ref_fals: this.state.image_vid_ref_fals,
          image_fals: this.state.image_fals,
          video_fals: this.state.video_fals,
          // report_419: this.state.report_419,
          // academic_report: this.state.academic_report,
          // behavioral_report: this.state.behavioral_report,
          // child_abuse: this.state.child_abuse,
          // credit_report: this.state.credit_report,
          // criminal_report: this.state.criminal_report,
          // death_report: this.state.death_report,
          // domestic_violent: this.state.domestic_violent,
          // fingerprint_report: this.state.fingerprint_report,
          // fraud_alerts: this.state.fraud_alerts,
          // ghost_worker: this.state.ghost_worker,
          // insurance_report: this.state.insurance_report,
          // property_report: this.state.property_report,
          // medical_report: this.state.medical_report,
          // one_chance: this.state.one_chance,
          // police_report: this.state.police_report,
          // professional_report: this.state.professional_report,
          // recommedations_report: this.state.recommedations_report,
          // rental_report: this.state.rental_report,
          // sexual_harrasement: this.state.sexual_harrasement,
          // social_capital: this.state.social_capital,
          // tax_clearance: this.state.tax_clearance,
          // tax_compliance_report: this.state.tax_compliance_report,
          // terrorism_report: this.state.terrorism_report,
          // traffic_offender_report: this.state.traffic_offender_report,
          // utilities_report: this.state.utilities_report,
          // vehicle_report: this.state.vehicle_report,
          // visa_report: this.state.visa_report,
          // yahoo_report: this.state.yahoo_report,
          // shoplifting_report: this.state.shoplifting_report,
          // overInvoice_report: this.state.overInvoice_report,
          // falsification_report: this.state.falsification_report,
          admin_id: 2103041846164616,
          edit_id: this.state.object_id,
        };
        console.log(data);
        result = await HttpClient.requestData(
          "admin/report/update",
          "POST",
          data
        );
      }
      console.log("result", result);
      if (result && result.status) {
        this.setState({
          alert: true,
          message: this.state.edit_enable
            ? "Report Updated Successfully"
            : "Report Added Successfully",
          type: "success",
          activeTabProgress: 4,
          progressValue: 100,
        });

        this.fetchData();
      } else {
        this.setState({
          alert: true,
          message:
            typeof result.error == "string"
              ? result.error
              : result.error[0].msg,
          type: "danger",
        });
      }
    } else {
      this.setState({
        alert: true,
        message: "Please Fill Up All Mandatory Fields.",
        type: "warning",
      });
    }
    setTimeout(() => {
      this.setState({
        alert: false,
        message: "",
        type: "",
      });
    }, 3000);
  };

  render() {
    const data = {
      columns: [
        {
          label: "Sl.",
          field: "sl",
          sort: "asc",
          width: 150,
        },
        {
          label: "Name",
          field: "name",
          sort: "asc",
          width: 270,
        },
        // {
        //   label: "Email",
        //   field: "email",
        //   sort: "asc",
        //   width: 270,
        // },
        {
          label: "Mobile",
          field: "mobile",
          sort: "asc",
          width: 150,
        },
        {
          label: "Status",
          field: "status",
          sort: "asc",
          width: 100,
        },
        {
          label: "Action",
          field: "action",
          sort: "asc",
          width: 100,
        },
      ],
      rows: this.state.data,
    };
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.state.breadcrumbItems[0].title}
              breadcrumbItems={this.state.breadcrumbItems}
            />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <h4 className="card-title mb-4">Add Report</h4>
                    <Link to="/manage-report"> <button style={{
                      backgroundColor: '#002145',
                      padding: '1px 7px 2px',
                      color: '#ffffff', float: 'right',
                      marginTop: '-45px'
                    }}>Manage Report</button></Link>
                    <div id="progrss-wizard" className="twitter-bs-wizard">
                      <ul className="twitter-bs-wizard-nav nav-justified nav nav-pills">
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeTabProgress === 1,
                            })}
                            onClick={() => {
                              this.toggleTabProgress(1);
                            }}
                          >
                            <span className="step-number">01</span>
                            <span className="step-title">Basic Details</span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeTabProgress === 2,
                            })}
                            onClick={() => {
                              this.toggleTabProgress(2);
                            }}
                          >
                            <span className="step-number">02</span>
                            <span className="step-title">
                              Report Document - I
                            </span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeTabProgress === 3,
                            })}
                            onClick={() => {
                              this.toggleTabProgress(3);
                            }}
                          >
                            <span className="step-number">03</span>
                            <span className="step-title">
                              Report Document - II
                            </span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeTabProgress === 4,
                            })}
                            onClick={() => {
                              this.toggleTabProgress(4);
                            }}
                          >
                            <span className="step-number">04</span>
                            <span className="step-title">Confirm Detail</span>
                          </NavLink>
                        </NavItem>
                      </ul>

                      <div id="bar" className="mt-4">
                        <Progress
                          color="success"
                          striped
                          animated
                          value={this.state.progressValue}
                        />
                      </div>
                      <TabContent
                        activeTab={this.state.activeTabProgress}
                        className="twitter-bs-wizard-tab-content"
                      >
                        <TabPane tabId={1}>
                          <Form>
                            <FormGroup row>
                              <Col md={4}>
                                <div className="d-flex">
                                  <Label
                                    htmlFor="example-text-input"
                                    className="col-form-label"
                                  >
                                    First Name:
                                  </Label>
                                  <Input
                                    className="form-control"
                                    type="text"
                                    value={this.state.fname}
                                    id="example-text-input"
                                    onChange={(val) => {
                                      this.setState({
                                        fname: val.target.value,
                                      });
                                    }}
                                  />
                                </div>
                                <div>
                                  {this.state.selectfname ? (
                                    <small style={{ color: "red" }}>
                                      {" "}
                                      Enter Firstname ****{" "}
                                    </small>
                                  ) : null}
                                </div>
                              </Col>

                              <Col md={4}>
                                <div className="d-flex">
                                  <Label
                                    htmlFor="example-text-input"
                                    className=" col-form-label"
                                  >
                                    Middle Name:
                                  </Label>
                                  <Input
                                    className="form-control"
                                    type="text"
                                    value={this.state.mname}
                                    id="example-text-input"
                                    onChange={(val) => {
                                      this.setState({
                                        mname: val.target.value,
                                      });
                                    }}
                                  />
                                </div>
                              </Col>

                              <Col md={4}>
                                <div className="d-flex">
                                  <Label
                                    htmlFor="example-text-input"
                                    className=" col-form-label"
                                  >
                                    Last Name:
                                  </Label>
                                  <Input
                                    className="form-control"
                                    type="text"
                                    value={this.state.lname}
                                    id="example-text-input"
                                    onChange={(val) => {
                                      this.setState({
                                        lname: val.target.value,
                                      });
                                    }}
                                  />
                                </div>
                                {this.state.selectlname ? (
                                  <small style={{ color: "red" }}>
                                    {" "}
                                    Enter Lastname ****{" "}
                                  </small>
                                ) : null}
                              </Col>
                            </FormGroup>

                            <FormGroup row>
                              <Label
                                htmlFor="example-text-input"
                                className="col-md-2 col-form-label"
                              >
                                Date of Birth:
                              </Label>
                              <Col md={4}>
                                <Input
                                  className="form-control"
                                  type="text"
                                  value={this.state.dob}
                                  id="example-text-input"
                                  onChange={(val) => {
                                    this.setState({ dob: val.target.value });
                                  }}
                                />
                              </Col>
                              <Label
                                htmlFor="example-text-input"
                                className="col-md-2 col-form-label"
                              >
                                Address:
                              </Label>
                              <Col md={4}>
                                <Input
                                  className="form-control"
                                  type="text"
                                  value={this.state.address}
                                  id="example-text-input"
                                  onChange={(val) => {
                                    this.setState({
                                      address: val.target.value,
                                    });
                                  }}
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup row>

                              <Label
                                htmlFor="example-text-input"
                                className="col-md-2 col-form-label"
                              >
                                Bank Verification # (BVN):
                              </Label>
                              <Col md={4}>
                                <Input
                                  className="form-control"
                                  type="text"
                                  value={this.state.bvn}
                                  id="example-text-input"
                                  onChange={(val) => {
                                    this.setState({ bvn: val.target.value });
                                  }}
                                />
                              </Col>
                              <Label
                                htmlFor="example-text-input"
                                className="col-md-2 col-form-label"
                              >
                                Company Name / Employee ID #:
                              </Label>
                              <Col md={4}>
                                <Input
                                  className="form-control"
                                  type="text"
                                  value={this.state.emp_id}
                                  id="example-text-input"
                                  onChange={(val) => {
                                    this.setState({ emp_id: val.target.value });
                                  }}
                                />
                              </Col>


                              {/* <Label
                                htmlFor="example-text-input"
                                className="col-md-2 col-form-label"
                              >
                                Cert:
                              </Label>
                              <Col md={4}>
                                <Input
                                  className="form-control"
                                  type="text"
                                  value={this.state.cert}
                                  id="example-text-input"
                                  onChange={(val) => {
                                    this.setState({ cert: val.target.value });
                                  }}
                                />
                              </Col> */}

                              <Label
                                htmlFor="example-text-input"
                                className="col-md-2 col-form-label"
                              >
                                Source:
                              </Label>
                              <Col md={4}>
                                <Input
                                  className="form-control"
                                  type="text"
                                  value={this.state.source}
                                  id="example-text-input"
                                  onChange={(val) => {
                                    this.setState({ source: val.target.value });
                                  }}
                                />
                              </Col>
                            </FormGroup>

                            <FormGroup row>
                              {/* <Label
                                htmlFor="example-text-input"
                                className="col-md-2 col-form-label"
                              >
                                Local ID :
                              </Label>
                              <Col md={4}>
                                <Input
                                  className="form-control"
                                  type="text"
                                  value={this.state.local_id}
                                  id="example-text-input"
                                  onChange={(val) => {
                                    this.setState({
                                      local_id: val.target.value,
                                    });
                                  }}
                                />
                              </Col> */}
                              <Label
                                htmlFor="example-text-input"
                                className="col-md-2 col-form-label"
                              >
                                Driver License #:
                              </Label>
                              <Col md={4}>
                                <Input
                                  className="form-control"
                                  type="text"
                                  value={this.state.driver_license}
                                  id="example-text-input"
                                  onChange={(val) => {
                                    this.setState({
                                      driver_license: val.target.value,
                                    });
                                  }}
                                />
                              </Col>
                              <Label
                                htmlFor="example-text-input"
                                className="col-md-2 col-form-label"
                              >
                                National ID # :
                              </Label>
                              <Col md={4}>
                                <Input
                                  className="form-control"
                                  type="text"
                                  value={this.state.national_id}
                                  id="example-text-input"
                                  onChange={(val) => {
                                    this.setState({
                                      national_id: val.target.value,
                                    });
                                  }}
                                />
                              </Col>
                            </FormGroup>

                            {/* <FormGroup row>
                              <Label
                                htmlFor="example-text-input"
                                className="col-md-2 col-form-label"
                              >
                                Bank A/c:
                              </Label>
                              <Col md={4}>
                                <Input
                                  className="form-control"
                                  type="text"
                                  value={this.state.bank_ac}
                                  id="example-text-input"
                                  onChange={(val) => {
                                    this.setState({
                                      bank_ac: val.target.value,
                                    });
                                  }}
                                />
                              </Col>
                            </FormGroup> */}

                            <FormGroup row>
                              <Label
                                htmlFor="example-text-input"
                                className="col-md-2 col-form-label"
                              >
                                Passport # :
                              </Label>
                              <Col md={4}>
                                <Input
                                  className="form-control"
                                  type="text"
                                  value={this.state.passport}
                                  id="example-text-input"
                                  onChange={(val) => {
                                    this.setState({
                                      passport: val.target.value,
                                    });
                                  }}
                                />
                              </Col>
                              <Label
                                htmlFor="example-text-input"
                                className="col-md-2 col-form-label"
                              >
                                Vehicle Plate #:
                              </Label>
                              <Col md={4}>
                                <Input
                                  className="form-control"
                                  type="text"
                                  value={this.state.plate_reg}
                                  id="example-text-input"
                                  onChange={(val) => {
                                    this.setState({
                                      plate_reg: val.target.value,
                                    });
                                  }}
                                />
                              </Col>
                              {/* <Label
                                htmlFor="example-text-input"
                                className="col-md-2 col-form-label"
                              >
                                Payroll :
                              </Label>
                              <Col md={4}>
                                <Input
                                  className="form-control"
                                  type="text"
                                  value={this.state.payroll}
                                  id="example-text-input"
                                  onChange={(val) => {
                                    this.setState({
                                      payroll: val.target.value,
                                    });
                                  }}
                                />
                              </Col> */}
                            </FormGroup>

                            {/* <FormGroup row>
                              <Label
                                htmlFor="example-text-input"
                                className="col-md-2 col-form-label"
                              >
                                Email
                              </Label>
                              <Col md={4}>
                                <Input
                                  className="form-control"
                                  type="text"
                                  value={this.state.email}
                                  id="example-text-input"
                                  onChange={(val) => {
                                    this.setState({ email: val.target.value });
                                  }}
                                />
                              </Col>
                              <Label
                                htmlFor="example-text-input"
                                className="col-md-2 col-form-label"
                              >
                                Employee ID:
                              </Label>
                              <Col md={4}>
                                <Input
                                  className="form-control"
                                  type="text"
                                  value={this.state.emp_id}
                                  id="example-text-input"
                                  onChange={(val) => {
                                    this.setState({ emp_id: val.target.value });
                                  }}
                                />
                              </Col>
                            </FormGroup> */}

                            <FormGroup row>
                              <Label
                                htmlFor="example-text-input"
                                className="col-md-2 col-form-label"
                              >
                                Registered Company Number (RC):
                              </Label>
                              <Col md={4}>
                                <Input
                                  className="form-control"
                                  type="text"
                                  value={this.state.rc}
                                  id="example-text-input"
                                  onChange={(val) => {
                                    this.setState({ rc: val.target.value });
                                  }}
                                />
                              </Col>
                              <Label
                                htmlFor="example-text-input"
                                className="col-md-2 col-form-label"
                              >
                                Social Media Profile Name(s):
                              </Label>
                              <Col md={4}>
                                <Input
                                  className="form-control"
                                  type="text"
                                  value={this.state.social_media}
                                  id="example-text-input"
                                  onChange={(val) => {
                                    this.setState({
                                      social_media: val.target.value,
                                    });
                                  }}
                                />
                              </Col>
                            </FormGroup>

                            <FormGroup row>
                              <Label
                                htmlFor="example-text-input"
                                className="col-md-2 col-form-label"
                              >
                                School Name / Student ID # :
                              </Label>
                              <Col md={4}>
                                <Input
                                  className="form-control"
                                  type="text"
                                  value={this.state.student_id}
                                  id="example-text-input"
                                  onChange={(val) => {
                                    this.setState({
                                      student_id: val.target.value,
                                    });
                                  }}
                                />
                              </Col>

                              <Label
                                htmlFor="example-text-input"
                                className="col-md-2 col-form-label"
                              >
                                Tax ID # (TIN) :
                              </Label>
                              <Col md={4}>
                                <Input
                                  className="form-control"
                                  type="text"
                                  value={this.state.tin}
                                  id="example-text-input"
                                  onChange={(val) => {
                                    this.setState({ tin: val.target.value });
                                  }}
                                />
                              </Col>
                            </FormGroup>

                            {/* <FormGroup row>
                              <Label
                                htmlFor="example-text-input"
                                className="col-md-2 col-form-label"
                              >
                                State ID :
                              </Label>
                              <Col md={4}>
                                <Input
                                  className="form-control"
                                  type="text"
                                  value={this.state.state_id}
                                  id="example-text-input"
                                  onChange={(val) => {
                                    this.setState({
                                      state_id: val.target.value,
                                    });
                                  }}
                                />
                              </Col>
                            </FormGroup> */}

                            <FormGroup row>
                              <Label
                                htmlFor="example-text-input"
                                className="col-md-2 col-form-label"
                              >
                                Telephone #:
                              </Label>
                              <Col md={4}>
                                <Input
                                  className="form-control"
                                  type="text"
                                  value={this.state.tel}
                                  id="example-text-input"
                                  onChange={(val) => {
                                    this.setState({ tel: val.target.value });
                                  }}
                                />
                              </Col>
                              <Label
                                htmlFor="example-text-input"
                                className="col-md-2 col-form-label"
                              >
                                Vehicle ID # (VIN):
                              </Label>
                              <Col md={4}>
                                <Input
                                  className="form-control"
                                  type="text"
                                  value={this.state.vin}
                                  id="example-text-input"
                                  onChange={(val) => {
                                    this.setState({ vin: val.target.value });
                                  }}
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              {/* <Label
                                htmlFor="example-text-input"
                                className="col-md-2 col-form-label"
                              >
                                Fingerprints :
                              </Label>
                              <Col md={4}>
                                <Input
                                  className="form-control"
                                  type="text"
                                  value={this.state.fingerprints}
                                  id="example-text-input"
                                  onChange={(val) => {
                                    this.setState({
                                      fingerprints: val.target.value,
                                    });
                                  }}
                                />
                              </Col> */}
                              <Label
                                htmlFor="example-text-input"
                                className="col-md-2 col-form-label"
                              >
                                Voter <br></br>Registration #:
                              </Label>
                              <Col md={4}>
                                <Input
                                  className="form-control"
                                  type="text"
                                  value={this.state.voter_reg}
                                  id="example-text-input"
                                  onChange={(val) => {
                                    this.setState({
                                      voter_reg: val.target.value,
                                    });
                                  }}
                                />
                              </Col>
                            </FormGroup>
                            <hr />
                            <h4 className="card-title">Upload Image</h4>
                            <FormGroup row>
                              <Label
                                htmlFor="example-text-input"
                                className="col-md-2 col-form-label"
                              >
                                Image
                              </Label>
                              <Col md={4}>
                                <Input
                                  className="form-control"
                                  type="file"
                                  id="example-text-input"
                                  onChange={(e) => {
                                    this.uploadImage(e, "image");
                                  }}
                                />
                              </Col>
                              {this.state.image == "" ? null : (
                                <Col md={4}>
                                  <img
                                    src={this.state.image}
                                    style={{ height: 200, width: 200 }}
                                  />
                                </Col>
                              )}
                            </FormGroup>
                          </Form>
                        </TabPane>
                        <TabPane tabId={2}>
                          <div>
                            <Form>
                              <h4 className="card-title">419 Report</h4>
                              <FormGroup row>
                                <Label
                                  htmlFor="example-text-input"
                                  className="col-md-2 col-form-label"
                                >
                                  {/* <small style={{ color: "red" }}> * </small> */}
                                  419 Report details
                                </Label>
                                <Col md={10}>
                                  <Input
                                    className="form-control"
                                    type="textarea"
                                    value={this.state.report_419_desc}
                                    id="example-text-input"
                                    onChange={(val) => {
                                      this.state.report_419_desc =
                                        val.target.value;
                                      this.setState({});
                                    }}
                                  />
                                </Col>
                              </FormGroup>
                              <FormGroup row>
                                <Label
                                  htmlFor="example-text-input"
                                  className="col-md-2 col-form-label"
                                >
                                  419 Report photo
                                </Label>
                                <Col md={4}>
                                  <Input
                                    className="form-control"
                                    type="file"
                                    id="example-text-input"
                                    onChange={(e) => {
                                      this.uploadImage(e, "report_419");
                                    }}
                                  />
                                </Col>
                                {this.state.image_repo == "" ? null : (
                                  <Col md={4}>
                                    <img
                                      src={this.state.image_repo}
                                      style={{ height: 200, width: 200 }}
                                    />
                                  </Col>
                                )}
                              </FormGroup>
                              <FormGroup row>
                                <Label
                                  htmlFor="example-text-input"
                                  className="col-md-2 col-form-label"
                                >
                                  419 Report video
                                </Label>
                                <Col md={4}>
                                  <Input
                                    className="form-control"
                                    type="file"
                                    id="example-text-input"
                                    onChange={(val) => {
                                      this.uploadVideo(val, "report_419");
                                    }}
                                  />
                                </Col>
                                {this.state.uploading_trailer ? (
                                  <img
                                    src="https://media.tenor.com/images/7d9cb36e95124fb829ff8f2450c3a567/tenor.gif"
                                    style={{ height: 150, width: 150 }}
                                  />
                                ) : (
                                  <>
                                    {this.state.video_repo != "" ? (
                                      <Col md={4}>
                                        <video
                                          width="100%"
                                          controls
                                          className="rounded-lg"
                                        >
                                          <source
                                            src={this.state.video_repo}
                                            type="video/mp4"
                                          />
                                        </video>
                                      </Col>
                                    ) : null}
                                  </>
                                )}
                              </FormGroup>
                              <hr />
                              <h4 className="card-title">Academic Report</h4>
                              <FormGroup row>
                                <Label
                                  htmlFor="example-text-input"
                                  className="col-md-2 col-form-label"
                                >
                                  {/* <small style={{ color: "red" }}> * </small> */}
                                  Academic Report details
                                </Label>
                                <Col md={10}>
                                  <Input
                                    className="form-control"
                                    type="textarea"
                                    value={this.state.academic_report_desc}
                                    id="example-text-input"
                                    onChange={(val) => {
                                      this.state.academic_report_desc =
                                        val.target.value;
                                      this.setState({});
                                    }}
                                  />
                                </Col>
                              </FormGroup>
                              <FormGroup row>
                                <Label
                                  htmlFor="example-text-input"
                                  className="col-md-2 col-form-label"
                                >
                                  Academic Report photo
                                </Label>
                                <Col md={4}>
                                  <Input
                                    className="form-control"
                                    type="file"
                                    id="example-text-input"
                                    onChange={(e) => {
                                      this.uploadImage(e, "academic_report");
                                    }}
                                  />
                                </Col>
                                {this.state.image_acd ==
                                  "" ? null : (
                                  <Col md={4}>
                                    <img
                                      src={this.state.image_acd}
                                      style={{ height: 200, width: 200 }}
                                    />
                                  </Col>
                                )}
                              </FormGroup>
                              <FormGroup row>
                                <Label
                                  htmlFor="example-text-input"
                                  className="col-md-2 col-form-label"
                                >
                                  Academic Report video
                                </Label>
                                <Col md={4}>
                                  <Input
                                    className="form-control"
                                    type="file"
                                    id="example-text-input"
                                    onChange={(val) => {
                                      this.uploadVideo(val, "academic_report");
                                    }}
                                  />
                                </Col>
                                {this.state.uploading_trailer1 ? (
                                  <img
                                    src="https://media.tenor.com/images/7d9cb36e95124fb829ff8f2450c3a567/tenor.gif"
                                    style={{ height: 150, width: 150 }}
                                  />
                                ) : (
                                  <>
                                    {this.state.video_acd != "" ? (
                                      <Col md={4}>
                                        <video
                                          width="100%"
                                          controls
                                          className="rounded-lg"
                                        >
                                          <source
                                            src={
                                              this.state.video_acd
                                            }
                                            type="video/mp4"
                                          />
                                        </video>
                                      </Col>
                                    ) : null}
                                  </>
                                )}
                              </FormGroup>
                              <hr />
                              <h4 className="card-title">Behavioral Report</h4>
                              <FormGroup row>
                                <Label
                                  htmlFor="example-text-input"
                                  className="col-md-2 col-form-label"
                                >
                                  {/* <small style={{ color: "red" }}> * </small> */}
                                  Behavioral Report details
                                </Label>
                                <Col md={10}>
                                  <Input
                                    className="form-control"
                                    type="textarea"
                                    value={this.state.behavioral_report_desc}
                                    id="example-text-input"
                                    onChange={(val) => {
                                      this.state.behavioral_report_desc =
                                        val.target.value;
                                      this.setState({});
                                    }}
                                  />
                                </Col>
                              </FormGroup>
                              <FormGroup row>
                                <Label
                                  htmlFor="example-text-input"
                                  className="col-md-2 col-form-label"
                                >
                                  Behavioral Report photo
                                </Label>
                                <Col md={4}>
                                  <Input
                                    className="form-control"
                                    type="file"
                                    id="example-text-input"
                                    onChange={(e) => {
                                      this.uploadImage(e, "behavioral_report");
                                    }}
                                  />
                                </Col>
                                {this.state.image_bhv ==
                                  "" ? null : (
                                  <Col md={4}>
                                    <img
                                      src={this.state.image_bhv}
                                      style={{ height: 200, width: 200 }}
                                    />
                                  </Col>
                                )}
                              </FormGroup>
                              <FormGroup row>
                                <Label
                                  htmlFor="example-text-input"
                                  className="col-md-2 col-form-label"
                                >
                                  Behavioral Report video
                                </Label>
                                <Col md={4}>
                                  <Input
                                    className="form-control"
                                    type="file"
                                    id="example-text-input"
                                    onChange={(val) => {
                                      this.uploadVideo(
                                        val,
                                        "behavioral_report"
                                      );
                                    }}
                                  />
                                </Col>
                                {this.state.uploading_trailer2 ? (
                                  <img
                                    src="https://media.tenor.com/images/7d9cb36e95124fb829ff8f2450c3a567/tenor.gif"
                                    style={{ height: 150, width: 150 }}
                                  />
                                ) : (
                                  <>
                                    {this.state.video_bhv !=
                                      "" ? (
                                      <Col md={4}>
                                        <video
                                          width="100%"
                                          controls
                                          className="rounded-lg"
                                        >
                                          <source
                                            src={
                                              this.state.video_bhv
                                            }
                                            type="video/mp4"
                                          />
                                        </video>
                                      </Col>
                                    ) : null}
                                  </>
                                )}
                              </FormGroup>
                              <hr />
                              <h4 className="card-title">Child Abuse</h4>
                              <FormGroup row>
                                <Label
                                  htmlFor="example-text-input"
                                  className="col-md-2 col-form-label"
                                >
                                  {/* <small style={{ color: "red" }}> * </small> */}
                                  Child Abuse details
                                </Label>
                                <Col md={10}>
                                  <Input
                                    className="form-control"
                                    type="textarea"
                                    value={this.state.child_abuse_desc}
                                    id="example-text-input"
                                    onChange={(val) => {
                                      this.state.child_abuse_desc =
                                        val.target.value;
                                      this.setState({});
                                    }}
                                  />
                                </Col>
                              </FormGroup>
                              <FormGroup row>
                                <Label
                                  htmlFor="example-text-input"
                                  className="col-md-2 col-form-label"
                                >
                                  Child Abuse photo
                                </Label>
                                <Col md={4}>
                                  <Input
                                    className="form-control"
                                    type="file"
                                    id="example-text-input"
                                    onChange={(e) => {
                                      this.uploadImage(e, "child_abuse");
                                    }}
                                  />
                                </Col>
                                {this.state.image_child == "" ? null : (
                                  <Col md={4}>
                                    <img
                                      src={this.state.image_child}
                                      style={{ height: 200, width: 200 }}
                                    />
                                  </Col>
                                )}
                              </FormGroup>
                              <FormGroup row>
                                <Label
                                  htmlFor="example-text-input"
                                  className="col-md-2 col-form-label"
                                >
                                  Child Abuse video
                                </Label>
                                <Col md={4}>
                                  <Input
                                    className="form-control"
                                    type="file"
                                    id="example-text-input"
                                    onChange={(val) => {
                                      this.uploadVideo(val, "child_abuse");
                                    }}
                                  />
                                </Col>
                                {this.state.uploading_trailer3 ? (
                                  <img
                                    src="https://media.tenor.com/images/7d9cb36e95124fb829ff8f2450c3a567/tenor.gif"
                                    style={{ height: 150, width: 150 }}
                                  />
                                ) : (
                                  <>
                                    {this.state.video_child != "" ? (
                                      <Col md={4}>
                                        <video
                                          width="100%"
                                          controls
                                          className="rounded-lg"
                                        >
                                          <source
                                            src={this.state.video_child}
                                            type="video/mp4"
                                          />
                                        </video>
                                      </Col>
                                    ) : null}
                                  </>
                                )}
                              </FormGroup>
                              <hr />
                              <h4 className="card-title">Credit Report</h4>
                              <FormGroup row>
                                <Label
                                  htmlFor="example-text-input"
                                  className="col-md-2 col-form-label"
                                >
                                  {/* <small style={{ color: "red" }}> * </small> */}
                                  Credit Report details
                                </Label>
                                <Col md={10}>
                                  <Input
                                    className="form-control"
                                    type="textarea"
                                    value={this.state.credit_report_desc}
                                    id="example-text-input"
                                    onChange={(val) => {
                                      this.state.credit_report_desc =
                                        val.target.value;
                                      this.setState({});
                                    }}
                                  />
                                </Col>
                              </FormGroup>
                              <FormGroup row>
                                <Label
                                  htmlFor="example-text-input"
                                  className="col-md-2 col-form-label"
                                >
                                  Credit Report photo
                                </Label>
                                <Col md={4}>
                                  <Input
                                    className="form-control"
                                    type="file"
                                    id="example-text-input"
                                    onChange={(e) => {
                                      this.uploadImage(e, "credit_report");
                                    }}
                                  />
                                </Col>
                                {this.state.image_cred == "" ? null : (
                                  <Col md={4}>
                                    <img
                                      src={this.state.image_cred}
                                      style={{ height: 200, width: 200 }}
                                    />
                                  </Col>
                                )}
                              </FormGroup>
                              <FormGroup row>
                                <Label
                                  htmlFor="example-text-input"
                                  className="col-md-2 col-form-label"
                                >
                                  Credit Report video
                                </Label>
                                <Col md={4}>
                                  <Input
                                    className="form-control"
                                    type="file"
                                    id="example-text-input"
                                    onChange={(val) => {
                                      this.uploadVideo(val, "credit_report");
                                    }}
                                  />
                                </Col>
                                {this.state.uploading_trailer4 ? (
                                  <img
                                    src="https://media.tenor.com/images/7d9cb36e95124fb829ff8f2450c3a567/tenor.gif"
                                    style={{ height: 150, width: 150 }}
                                  />
                                ) : (
                                  <>
                                    {this.state.video_cred != "" ? (
                                      <Col md={4}>
                                        <video
                                          width="100%"
                                          controls
                                          className="rounded-lg"
                                        >
                                          <source
                                            src={this.state.video_cred}
                                            type="video/mp4"
                                          />
                                        </video>
                                      </Col>
                                    ) : null}
                                  </>
                                )}
                              </FormGroup>
                              <hr />
                              <h4 className="card-title">Criminal Report</h4>
                              <FormGroup row>
                                <Label
                                  htmlFor="example-text-input"
                                  className="col-md-2 col-form-label"
                                >
                                  {/* <small style={{ color: "red" }}> * </small> */}
                                  Criminal Report details
                                </Label>
                                <Col md={10}>
                                  <Input
                                    className="form-control"
                                    type="textarea"
                                    value={this.state.criminal_report_desc}
                                    id="example-text-input"
                                    onChange={(val) => {
                                      this.state.criminal_report_desc =
                                        val.target.value;
                                      this.setState({});
                                    }}
                                  />
                                </Col>
                              </FormGroup>
                              <FormGroup row>
                                <Label
                                  htmlFor="example-text-input"
                                  className="col-md-2 col-form-label"
                                >
                                  Criminal Report photo
                                </Label>
                                <Col md={4}>
                                  <Input
                                    className="form-control"
                                    type="file"
                                    id="example-text-input"
                                    onChange={(e) => {
                                      this.uploadImage(e, "criminal_report");
                                    }}
                                  />
                                </Col>
                                {this.state.image_crim ==
                                  "" ? null : (
                                  <Col md={4}>
                                    <img
                                      src={this.state.image_crim}
                                      style={{ height: 200, width: 200 }}
                                    />
                                  </Col>
                                )}
                              </FormGroup>
                              <FormGroup row>
                                <Label
                                  htmlFor="example-text-input"
                                  className="col-md-2 col-form-label"
                                >
                                  Criminal Report video
                                </Label>
                                <Col md={4}>
                                  <Input
                                    className="form-control"
                                    type="file"
                                    id="example-text-input"
                                    onChange={(val) => {
                                      this.uploadVideo(val, "criminal_report");
                                    }}
                                  />
                                </Col>
                                {this.state.uploading_trailer5 ? (
                                  <img
                                    src="https://media.tenor.com/images/7d9cb36e95124fb829ff8f2450c3a567/tenor.gif"
                                    style={{ height: 150, width: 150 }}
                                  />
                                ) : (
                                  <>
                                    {this.state.video_crim != "" ? (
                                      <Col md={4}>
                                        <video
                                          width="100%"
                                          controls
                                          className="rounded-lg"
                                        >
                                          <source
                                            src={this.state.video_crim}
                                            type="video/mp4"
                                          />
                                        </video>
                                      </Col>
                                    ) : null}
                                  </>
                                )}
                              </FormGroup>
                              <hr />
                              <h4 className="card-title">Death Report</h4>
                              <FormGroup row>
                                <Label
                                  htmlFor="example-text-input"
                                  className="col-md-2 col-form-label"
                                >
                                  {/* <small style={{ color: "red" }}> * </small> */}
                                  Death Report details
                                </Label>
                                <Col md={10}>
                                  <Input
                                    className="form-control"
                                    type="textarea"
                                    value={this.state.death_report_desc}
                                    id="example-text-input"
                                    onChange={(val) => {
                                      this.state.death_report_desc =
                                        val.target.value;
                                      this.setState({});
                                    }}
                                  />
                                </Col>
                              </FormGroup>
                              <FormGroup row>
                                <Label
                                  htmlFor="example-text-input"
                                  className="col-md-2 col-form-label"
                                >
                                  Death Report photo
                                </Label>
                                <Col md={4}>
                                  <Input
                                    className="form-control"
                                    type="file"
                                    id="example-text-input"
                                    onChange={(e) => {
                                      this.uploadImage(e, "death_report");
                                    }}
                                  />
                                </Col>
                                {this.state.image_deat == "" ? null : (
                                  <Col md={4}>
                                    <img
                                      src={this.state.image_deat}
                                      style={{ height: 200, width: 200 }}
                                    />
                                  </Col>
                                )}
                              </FormGroup>
                              <FormGroup row>
                                <Label
                                  htmlFor="example-text-input"
                                  className="col-md-2 col-form-label"
                                >
                                  Death Report video
                                </Label>
                                <Col md={4}>
                                  <Input
                                    className="form-control"
                                    type="file"
                                    id="example-text-input"
                                    onChange={(val) => {
                                      this.uploadVideo(val, "death_report");
                                    }}
                                  />
                                </Col>
                                {this.state.uploading_trailer6 ? (
                                  <img
                                    src="https://media.tenor.com/images/7d9cb36e95124fb829ff8f2450c3a567/tenor.gif"
                                    style={{ height: 150, width: 150 }}
                                  />
                                ) : (
                                  <>
                                    {this.state.video_deat != "" ? (
                                      <Col md={4}>
                                        <video
                                          width="100%"
                                          controls
                                          className="rounded-lg"
                                        >
                                          <source
                                            src={this.state.video_deat}
                                            type="video/mp4"
                                          />
                                        </video>
                                      </Col>
                                    ) : null}
                                  </>
                                )}
                              </FormGroup>
                              <hr />
                              <h4 className="card-title">Domestic violent</h4>
                              <FormGroup row>
                                <Label
                                  htmlFor="example-text-input"
                                  className="col-md-2 col-form-label"
                                >
                                  {/* <small style={{ color: "red" }}> * </small> */}
                                  Domestic violent details
                                </Label>
                                <Col md={10}>
                                  <Input
                                    className="form-control"
                                    type="textarea"
                                    value={this.state.domestic_violent_desc}
                                    id="example-text-input"
                                    onChange={(val) => {
                                      this.state.domestic_violent_desc =
                                        val.target.value;
                                      this.setState({});
                                    }}
                                  />
                                </Col>
                              </FormGroup>
                              <FormGroup row>
                                <Label
                                  htmlFor="example-text-input"
                                  className="col-md-2 col-form-label"
                                >
                                  Domestic violent photo
                                </Label>
                                <Col md={4}>
                                  <Input
                                    className="form-control"
                                    type="file"
                                    id="example-text-input"
                                    onChange={(e) => {
                                      this.uploadImage(e, "domestic_violent");
                                    }}
                                  />
                                </Col>
                                {this.state.image_dome ==
                                  "" ? null : (
                                  <Col md={4}>
                                    <img
                                      src={this.state.image_dome}
                                      style={{ height: 200, width: 200 }}
                                    />
                                  </Col>
                                )}
                              </FormGroup>
                              <FormGroup row>
                                <Label
                                  htmlFor="example-text-input"
                                  className="col-md-2 col-form-label"
                                >
                                  Domestic violent video
                                </Label>
                                <Col md={4}>
                                  <Input
                                    className="form-control"
                                    type="file"
                                    id="example-text-input"
                                    onChange={(val) => {
                                      this.uploadVideo(val, "domestic_violent");
                                    }}
                                  />
                                </Col>
                                {this.state.uploading_trailer7 ? (
                                  <img
                                    src="https://media.tenor.com/images/7d9cb36e95124fb829ff8f2450c3a567/tenor.gif"
                                    style={{ height: 150, width: 150 }}
                                  />
                                ) : (
                                  <>
                                    {this.state.video_dome != "" ? (
                                      <Col md={4}>
                                        <video
                                          width="100%"
                                          controls
                                          className="rounded-lg"
                                        >
                                          <source
                                            src={
                                              this.state.video_dome
                                            }
                                            type="video/mp4"
                                          />
                                        </video>
                                      </Col>
                                    ) : null}
                                  </>
                                )}
                              </FormGroup>
                              <hr />
                              <h4 className="card-title">Fingerprint Report</h4>
                              <FormGroup row>
                                <Label
                                  htmlFor="example-text-input"
                                  className="col-md-2 col-form-label"
                                >
                                  {/* <small style={{ color: "red" }}> * </small> */}
                                  Fingerprint Report details
                                </Label>
                                <Col md={10}>
                                  <Input
                                    className="form-control"
                                    type="textarea"
                                    value={
                                      this.state.fingerprint_report_desc
                                    }
                                    id="example-text-input"
                                    onChange={(val) => {
                                      this.state.fingerprint_report_desc =
                                        val.target.value;
                                      this.setState({});
                                    }}
                                  />
                                </Col>
                              </FormGroup>
                              <FormGroup row>
                                <Label
                                  htmlFor="example-text-input"
                                  className="col-md-2 col-form-label"
                                >
                                  Fingerprint Report photo
                                </Label>
                                <Col md={4}>
                                  <Input
                                    className="form-control"
                                    type="file"
                                    id="example-text-input"
                                    onChange={(e) => {
                                      this.uploadImage(e, "fingerprint_report");
                                    }}
                                  />
                                </Col>
                                {this.state.image_fing ==
                                  "" ? null : (
                                  <Col md={4}>
                                    <img
                                      src={this.state.image_fing}
                                      style={{ height: 200, width: 200 }}
                                    />
                                  </Col>
                                )}
                              </FormGroup>
                              <FormGroup row>
                                <Label
                                  htmlFor="example-text-input"
                                  className="col-md-2 col-form-label"
                                >
                                  Fingerprint Report video
                                </Label>
                                <Col md={4}>
                                  <Input
                                    className="form-control"
                                    type="file"
                                    id="example-text-input"
                                    onChange={(val) => {
                                      this.uploadVideo(
                                        val,
                                        "fingerprint_report"
                                      );
                                    }}
                                  />
                                </Col>
                                {this.state.uploading_trailer8 ? (
                                  <img
                                    src="https://media.tenor.com/images/7d9cb36e95124fb829ff8f2450c3a567/tenor.gif"
                                    style={{ height: 150, width: 150 }}
                                  />
                                ) : (
                                  <>
                                    {this.state.video_fing !=
                                      "" ? (
                                      <Col md={4}>
                                        <video
                                          width="100%"
                                          controls
                                          className="rounded-lg"
                                        >
                                          <source
                                            src={
                                              this.state
                                                .video_fing
                                            }
                                            type="video/mp4"
                                          />
                                        </video>
                                      </Col>
                                    ) : null}
                                  </>
                                )}
                              </FormGroup>
                              <hr />
                              <h4 className="card-title">Fraud Alerts</h4>
                              <FormGroup row>
                                <Label
                                  htmlFor="example-text-input"
                                  className="col-md-2 col-form-label"
                                >
                                  {/* <small style={{ color: "red" }}> * </small> */}
                                  Fraud Alerts details
                                </Label>
                                <Col md={10}>
                                  <Input
                                    className="form-control"
                                    type="textarea"
                                    value={this.state.fraud_alerts_desc}
                                    id="example-text-input"
                                    onChange={(val) => {
                                      this.state.fraud_alerts_desc =
                                        val.target.value;
                                      this.setState({});
                                    }}
                                  />
                                </Col>
                              </FormGroup>
                              <FormGroup row>
                                <Label
                                  htmlFor="example-text-input"
                                  className="col-md-2 col-form-label"
                                >
                                  Fraud Alerts photo
                                </Label>
                                <Col md={4}>
                                  <Input
                                    className="form-control"
                                    type="file"
                                    id="example-text-input"
                                    onChange={(e) => {
                                      this.uploadImage(e, "fraud_alerts");
                                    }}
                                  />
                                </Col>
                                {this.state.image_frau == "" ? null : (
                                  <Col md={4}>
                                    <img
                                      src={this.state.image_frau}
                                      style={{ height: 200, width: 200 }}
                                    />
                                  </Col>
                                )}
                              </FormGroup>
                              <FormGroup row>
                                <Label
                                  htmlFor="example-text-input"
                                  className="col-md-2 col-form-label"
                                >
                                  Fraud Alerts video
                                </Label>
                                <Col md={4}>
                                  <Input
                                    className="form-control"
                                    type="file"
                                    id="example-text-input"
                                    onChange={(val) => {
                                      this.uploadVideo(val, "fraud_alerts");
                                    }}
                                  />
                                </Col>
                                {this.state.uploading_trailer9 ? (
                                  <img
                                    src="https://media.tenor.com/images/7d9cb36e95124fb829ff8f2450c3a567/tenor.gif"
                                    style={{ height: 150, width: 150 }}
                                  />
                                ) : (
                                  <>
                                    {this.state.video_frau != "" ? (
                                      <Col md={4}>
                                        <video
                                          width="100%"
                                          controls
                                          className="rounded-lg"
                                        >
                                          <source
                                            src={this.state.video_frau}
                                            type="video/mp4"
                                          />
                                        </video>
                                      </Col>
                                    ) : null}
                                  </>
                                )}
                              </FormGroup>
                              <hr />
                              <h4 className="card-title">
                                Ghost worker Report
                              </h4>
                              <FormGroup row>
                                <Label
                                  htmlFor="example-text-input"
                                  className="col-md-2 col-form-label"
                                >
                                  {/* <small style={{ color: "red" }}> * </small> */}
                                  Ghost worker Report details
                                </Label>
                                <Col md={10}>
                                  <Input
                                    className="form-control"
                                    type="textarea"
                                    value={this.state.ghost_worker_desc}
                                    id="example-text-input"
                                    onChange={(val) => {
                                      this.state.ghost_worker_desc =
                                        val.target.value;
                                      this.setState({});
                                    }}
                                  />
                                </Col>
                              </FormGroup>
                              <FormGroup row>
                                <Label
                                  htmlFor="example-text-input"
                                  className="col-md-2 col-form-label"
                                >
                                  Ghost worker Report photo
                                </Label>
                                <Col md={4}>
                                  <Input
                                    className="form-control"
                                    type="file"
                                    id="example-text-input"
                                    onChange={(e) => {
                                      this.uploadImage(e, "ghost_worker");
                                    }}
                                  />
                                </Col>
                                {this.state.image_ghos == "" ? null : (
                                  <Col md={4}>
                                    <img
                                      src={this.state.image_ghos}
                                      style={{ height: 200, width: 200 }}
                                    />
                                  </Col>
                                )}
                              </FormGroup>
                              <FormGroup row>
                                <Label
                                  htmlFor="example-text-input"
                                  className="col-md-2 col-form-label"
                                >
                                  Ghost worker Report video
                                </Label>
                                <Col md={4}>
                                  <Input
                                    className="form-control"
                                    type="file"
                                    id="example-text-input"
                                    onChange={(val) => {
                                      this.uploadVideo(val, "ghost_worker");
                                    }}
                                  />
                                </Col>
                                {this.state.uploading_trailer10 ? (
                                  <img
                                    src="https://media.tenor.com/images/7d9cb36e95124fb829ff8f2450c3a567/tenor.gif"
                                    style={{ height: 150, width: 150 }}
                                  />
                                ) : (
                                  <>
                                    {this.state.video_ghos != "" ? (
                                      <Col md={4}>
                                        <video
                                          width="100%"
                                          controls
                                          className="rounded-lg"
                                        >
                                          <source
                                            src={this.state.video_ghos}
                                            type="video/mp4"
                                          />
                                        </video>
                                      </Col>
                                    ) : null}
                                  </>
                                )}
                              </FormGroup>
                              <hr />
                              <h4 className="card-title">Insurance Report</h4>
                              <FormGroup row>
                                <Label
                                  htmlFor="example-text-input"
                                  className="col-md-2 col-form-label"
                                >
                                  {/* <small style={{ color: "red" }}> * </small> */}
                                  Insurance Report details
                                </Label>
                                <Col md={10}>
                                  <Input
                                    className="form-control"
                                    type="textarea"
                                    value={this.state.insurance_report_desc}
                                    id="example-text-input"
                                    onChange={(val) => {
                                      this.state.insurance_report_desc =
                                        val.target.value;
                                      this.setState({});
                                    }}
                                  />
                                </Col>
                              </FormGroup>
                              <FormGroup row>
                                <Label
                                  htmlFor="example-text-input"
                                  className="col-md-2 col-form-label"
                                >
                                  Insurance Report photo
                                </Label>
                                <Col md={4}>
                                  <Input
                                    className="form-control"
                                    type="file"
                                    id="example-text-input"
                                    onChange={(e) => {
                                      this.uploadImage(e, "insurance_report");
                                    }}
                                  />
                                </Col>
                                {this.state.image_insu ==
                                  "" ? null : (
                                  <Col md={4}>
                                    <img
                                      src={this.state.image_insu}
                                      style={{ height: 200, width: 200 }}
                                    />
                                  </Col>
                                )}
                              </FormGroup>
                              <FormGroup row>
                                <Label
                                  htmlFor="example-text-input"
                                  className="col-md-2 col-form-label"
                                >
                                  Insurance Report video
                                </Label>
                                <Col md={4}>
                                  <Input
                                    className="form-control"
                                    type="file"
                                    id="example-text-input"
                                    onChange={(val) => {
                                      this.uploadVideo(val, "insurance_report");
                                    }}
                                  />
                                </Col>
                                {this.state.uploading_trailer11 ? (
                                  <img
                                    src="https://media.tenor.com/images/7d9cb36e95124fb829ff8f2450c3a567/tenor.gif"
                                    style={{ height: 150, width: 150 }}
                                  />
                                ) : (
                                  <>
                                    {this.state.video_insu != "" ? (
                                      <Col md={4}>
                                        <video
                                          width="100%"
                                          controls
                                          className="rounded-lg"
                                        >
                                          <source
                                            src={
                                              this.state.video_insu
                                            }
                                            type="video/mp4"
                                          />
                                        </video>
                                      </Col>
                                    ) : null}
                                  </>
                                )}
                              </FormGroup>
                              <hr />
                              <h4 className="card-title">
                                Land/property Report
                              </h4>
                              <FormGroup row>
                                <Label
                                  htmlFor="example-text-input"
                                  className="col-md-2 col-form-label"
                                >
                                  {/* <small style={{ color: "red" }}> * </small> */}
                                  Land/property Report details
                                </Label>
                                <Col md={10}>
                                  <Input
                                    className="form-control"
                                    type="textarea"
                                    value={this.state.property_report_desc}
                                    id="example-text-input"
                                    onChange={(val) => {
                                      this.state.property_report_desc =
                                        val.target.value;
                                      this.setState({});
                                    }}
                                  />
                                </Col>
                              </FormGroup>
                              <FormGroup row>
                                <Label
                                  htmlFor="example-text-input"
                                  className="col-md-2 col-form-label"
                                >
                                  Land/property Report photo
                                </Label>
                                <Col md={4}>
                                  <Input
                                    className="form-control"
                                    type="file"
                                    id="example-text-input"
                                    onChange={(e) => {
                                      this.uploadImage(e, "property_report");
                                    }}
                                  />
                                </Col>
                                {this.state.image_prop ==
                                  "" ? null : (
                                  <Col md={4}>
                                    <img
                                      src={this.state.image_prop}
                                      style={{ height: 200, width: 200 }}
                                    />
                                  </Col>
                                )}
                              </FormGroup>
                              <FormGroup row>
                                <Label
                                  htmlFor="example-text-input"
                                  className="col-md-2 col-form-label"
                                >
                                  Land/property Report video
                                </Label>
                                <Col md={4}>
                                  <Input
                                    className="form-control"
                                    type="file"
                                    id="example-text-input"
                                    onChange={(val) => {
                                      this.uploadVideo(val, "property_report");
                                    }}
                                  />
                                </Col>
                                {this.state.uploading_trailer12 ? (
                                  <img
                                    src="https://media.tenor.com/images/7d9cb36e95124fb829ff8f2450c3a567/tenor.gif"
                                    style={{ height: 150, width: 150 }}
                                  />
                                ) : (
                                  <>
                                    {this.state.video_prop != "" ? (
                                      <Col md={4}>
                                        <video
                                          width="100%"
                                          controls
                                          className="rounded-lg"
                                        >
                                          <source
                                            src={
                                              this.state.video_prop
                                            }
                                            type="video/mp4"
                                          />
                                        </video>
                                      </Col>
                                    ) : null}
                                  </>
                                )}
                              </FormGroup>
                              <hr />
                              <h4 className="card-title">Medical Report</h4>
                              <FormGroup row>
                                <Label
                                  htmlFor="example-text-input"
                                  className="col-md-2 col-form-label"
                                >
                                  {/* <small style={{ color: "red" }}> * </small> */}
                                  Medical Report details
                                </Label>
                                <Col md={10}>
                                  <Input
                                    className="form-control"
                                    type="textarea"
                                    value={this.state.medical_report_desc}
                                    id="example-text-input"
                                    onChange={(val) => {
                                      this.state.medical_report_desc =
                                        val.target.value;
                                      this.setState({});
                                    }}
                                  />
                                </Col>
                              </FormGroup>
                              <FormGroup row>
                                <Label
                                  htmlFor="example-text-input"
                                  className="col-md-2 col-form-label"
                                >
                                  Medical Report photo
                                </Label>
                                <Col md={4}>
                                  <Input
                                    className="form-control"
                                    type="file"
                                    id="example-text-input"
                                    onChange={(e) => {
                                      this.uploadImage(e, "medical_report");
                                    }}
                                  />
                                </Col>
                                {this.state.image_medi ==
                                  "" ? null : (
                                  <Col md={4}>
                                    <img
                                      src={this.state.image_medi}
                                      style={{ height: 200, width: 200 }}
                                    />
                                  </Col>
                                )}
                              </FormGroup>
                              <FormGroup row>
                                <Label
                                  htmlFor="example-text-input"
                                  className="col-md-2 col-form-label"
                                >
                                  Medical Report video
                                </Label>
                                <Col md={4}>
                                  <Input
                                    className="form-control"
                                    type="file"
                                    id="example-text-input"
                                    onChange={(val) => {
                                      this.uploadVideo(val, "medical_report");
                                    }}
                                  />
                                </Col>
                                {this.state.uploading_trailer13 ? (
                                  <img
                                    src="https://media.tenor.com/images/7d9cb36e95124fb829ff8f2450c3a567/tenor.gif"
                                    style={{ height: 150, width: 150 }}
                                  />
                                ) : (
                                  <>
                                    {this.state.video_medi != "" ? (
                                      <Col md={4}>
                                        <video
                                          width="100%"
                                          controls
                                          className="rounded-lg"
                                        >
                                          <source
                                            src={
                                              this.state.video_medi
                                            }
                                            type="video/mp4"
                                          />
                                        </video>
                                      </Col>
                                    ) : null}
                                  </>
                                )}
                              </FormGroup>
                              <hr />
                            </Form>
                          </div>
                        </TabPane>
                        <TabPane tabId={3}>
                          <div>
                            <Form>
                              <h4 className="card-title">One Chance</h4>
                              <FormGroup row>
                                <Label
                                  htmlFor="example-text-input"
                                  className="col-md-2 col-form-label"
                                >
                                  {/* <small style={{ color: "red" }}> * </small> */}
                                  One Chance details
                                </Label>
                                <Col md={10}>
                                  <Input
                                    className="form-control"
                                    type="textarea"
                                    value={this.state.one_chance_desc}
                                    id="example-text-input"
                                    onChange={(val) => {
                                      this.state.one_chance_desc =
                                        val.target.value;
                                      this.setState({});
                                    }}
                                  />
                                </Col>
                              </FormGroup>
                              <FormGroup row>
                                <Label
                                  htmlFor="example-text-input"
                                  className="col-md-2 col-form-label"
                                >
                                  One Chance photo
                                </Label>
                                <Col md={4}>
                                  <Input
                                    className="form-control"
                                    type="file"
                                    id="example-text-input"
                                    onChange={(e) => {
                                      this.uploadImage(e, "one_chance");
                                    }}
                                  />
                                </Col>
                                {this.state.image_onec == "" ? null : (
                                  <Col md={4}>
                                    <img
                                      src={this.state.image_onec}
                                      style={{ height: 200, width: 200 }}
                                    />
                                  </Col>
                                )}
                              </FormGroup>
                              <FormGroup row>
                                <Label
                                  htmlFor="example-text-input"
                                  className="col-md-2 col-form-label"
                                >
                                  One Chance video
                                </Label>
                                <Col md={4}>
                                  <Input
                                    className="form-control"
                                    type="file"
                                    id="example-text-input"
                                    onChange={(val) => {
                                      this.uploadVideo(val, "one_chance");
                                    }}
                                  />
                                </Col>
                                {this.state.uploading_trailer14 ? (
                                  <img
                                    src="https://media.tenor.com/images/7d9cb36e95124fb829ff8f2450c3a567/tenor.gif"
                                    style={{ height: 150, width: 150 }}
                                  />
                                ) : (
                                  <>
                                    {this.state.video_onec != "" ? (
                                      <Col md={4}>
                                        <video
                                          width="100%"
                                          controls
                                          className="rounded-lg"
                                        >
                                          <source
                                            src={this.state.video_onec}
                                            type="video/mp4"
                                          />
                                        </video>
                                      </Col>
                                    ) : null}
                                  </>
                                )}
                              </FormGroup>
                              <hr />
                              <h4 className="card-title">Police Report</h4>
                              <FormGroup row>
                                <Label
                                  htmlFor="example-text-input"
                                  className="col-md-2 col-form-label"
                                >
                                  {/* <small style={{ color: "red" }}> * </small> */}
                                  Police Report details
                                </Label>
                                <Col md={10}>
                                  <Input
                                    className="form-control"
                                    type="textarea"
                                    value={this.state.police_report_desc}
                                    id="example-text-input"
                                    onChange={(val) => {
                                      this.state.police_report_desc =
                                        val.target.value;
                                      this.setState({});
                                    }}
                                  />
                                </Col>
                              </FormGroup>
                              <FormGroup row>
                                <Label
                                  htmlFor="example-text-input"
                                  className="col-md-2 col-form-label"
                                >
                                  Police Report photo
                                </Label>
                                <Col md={4}>
                                  <Input
                                    className="form-control"
                                    type="file"
                                    id="example-text-input"
                                    onChange={(e) => {
                                      this.uploadImage(e, "police_report");
                                    }}
                                  />
                                </Col>
                                {this.state.image_poli == "" ? null : (
                                  <Col md={4}>
                                    <img
                                      src={this.state.image_poli}
                                      style={{ height: 200, width: 200 }}
                                    />
                                  </Col>
                                )}
                              </FormGroup>
                              <FormGroup row>
                                <Label
                                  htmlFor="example-text-input"
                                  className="col-md-2 col-form-label"
                                >
                                  Police Report video
                                </Label>
                                <Col md={4}>
                                  <Input
                                    className="form-control"
                                    type="file"
                                    id="example-text-input"
                                    onChange={(val) => {
                                      this.uploadVideo(val, "police_report");
                                    }}
                                  />
                                </Col>
                                {this.state.uploading_trailer15 ? (
                                  <img
                                    src="https://media.tenor.com/images/7d9cb36e95124fb829ff8f2450c3a567/tenor.gif"
                                    style={{ height: 150, width: 150 }}
                                  />
                                ) : (
                                  <>
                                    {this.state.video_poli != "" ? (
                                      <Col md={4}>
                                        <video
                                          width="100%"
                                          controls
                                          className="rounded-lg"
                                        >
                                          <source
                                            src={this.state.video_poli}
                                            type="video/mp4"
                                          />
                                        </video>
                                      </Col>
                                    ) : null}
                                  </>
                                )}
                              </FormGroup>
                              <hr />
                              <h4 className="card-title">
                                Professional Report
                              </h4>
                              <FormGroup row>
                                <Label
                                  htmlFor="example-text-input"
                                  className="col-md-2 col-form-label"
                                >
                                  {/* <small style={{ color: "red" }}> * </small> */}
                                  Professional Report details
                                </Label>
                                <Col md={10}>
                                  <Input
                                    className="form-control"
                                    type="textarea"
                                    value={
                                      this.state.professional_report_desc
                                    }
                                    id="example-text-input"
                                    onChange={(val) => {
                                      this.state.professional_report_desc =
                                        val.target.value;
                                      this.setState({});
                                    }}
                                  />
                                </Col>
                              </FormGroup>
                              <FormGroup row>
                                <Label
                                  htmlFor="example-text-input"
                                  className="col-md-2 col-form-label"
                                >
                                  Professional Report photo
                                </Label>
                                <Col md={4}>
                                  <Input
                                    className="form-control"
                                    type="file"
                                    id="example-text-input"
                                    onChange={(e) => {
                                      this.uploadImage(
                                        e,
                                        "professional_report"
                                      );
                                    }}
                                  />
                                </Col>
                                {this.state.image_prof ==
                                  "" ? null : (
                                  <Col md={4}>
                                    <img
                                      src={this.state.image_prof}
                                      style={{ height: 200, width: 200 }}
                                    />
                                  </Col>
                                )}
                              </FormGroup>
                              <FormGroup row>
                                <Label
                                  htmlFor="example-text-input"
                                  className="col-md-2 col-form-label"
                                >
                                  Professional Report video
                                </Label>
                                <Col md={4}>
                                  <Input
                                    className="form-control"
                                    type="file"
                                    id="example-text-input"
                                    onChange={(val) => {
                                      this.uploadVideo(
                                        val,
                                        "professional_report"
                                      );
                                    }}
                                  />
                                </Col>
                                {this.state.uploading_trailer16 ? (
                                  <img
                                    src="https://media.tenor.com/images/7d9cb36e95124fb829ff8f2450c3a567/tenor.gif"
                                    style={{ height: 150, width: 150 }}
                                  />
                                ) : (
                                  <>
                                    {this.state.video_prof !=
                                      "" ? (
                                      <Col md={4}>
                                        <video
                                          width="100%"
                                          controls
                                          className="rounded-lg"
                                        >
                                          <source
                                            src={
                                              this.state
                                                .video_prof
                                            }
                                            type="video/mp4"
                                          />
                                        </video>
                                      </Col>
                                    ) : null}
                                  </>
                                )}
                              </FormGroup>
                              <hr />
                              <h4 className="card-title">Recommedations</h4>
                              <FormGroup row>
                                <Label
                                  htmlFor="example-text-input"
                                  className="col-md-2 col-form-label"
                                >
                                  {/* <small style={{ color: "red" }}> * </small> */}
                                  Recommedations details
                                </Label>
                                <Col md={10}>
                                  <Input
                                    className="form-control"
                                    type="textarea"
                                    value={
                                      this.state.recommedations_report_desc
                                    }
                                    id="example-text-input"
                                    onChange={(val) => {
                                      this.state.recommedations_report_desc =
                                        val.target.value;
                                      this.setState({});
                                    }}
                                  />
                                </Col>
                              </FormGroup>
                              <FormGroup row>
                                <Label
                                  htmlFor="example-text-input"
                                  className="col-md-2 col-form-label"
                                >
                                  Recommedations photo
                                </Label>
                                <Col md={4}>
                                  <Input
                                    className="form-control"
                                    type="file"
                                    id="example-text-input"
                                    onChange={(e) => {
                                      this.uploadImage(
                                        e,
                                        "recommedations_report"
                                      );
                                    }}
                                  />
                                </Col>
                                {this.state.image_reco ==
                                  "" ? null : (
                                  <Col md={4}>
                                    <img
                                      src={
                                        this.state.image_reco
                                      }
                                      style={{ height: 200, width: 200 }}
                                    />
                                  </Col>
                                )}
                              </FormGroup>
                              <FormGroup row>
                                <Label
                                  htmlFor="example-text-input"
                                  className="col-md-2 col-form-label"
                                >
                                  Recommedations video
                                </Label>
                                <Col md={4}>
                                  <Input
                                    className="form-control"
                                    type="file"
                                    id="example-text-input"
                                    onChange={(val) => {
                                      this.uploadVideo(
                                        val,
                                        "recommedations_report"
                                      );
                                    }}
                                  />
                                </Col>
                                {this.state.uploading_trailer17 ? (
                                  <img
                                    src="https://media.tenor.com/images/7d9cb36e95124fb829ff8f2450c3a567/tenor.gif"
                                    style={{ height: 150, width: 150 }}
                                  />
                                ) : (
                                  <>
                                    {this.state.video_reco !=
                                      "" ? (
                                      <Col md={4}>
                                        <video
                                          width="100%"
                                          controls
                                          className="rounded-lg"
                                        >
                                          <source
                                            src={
                                              this.state
                                                .video_reco
                                            }
                                            type="video/mp4"
                                          />
                                        </video>
                                      </Col>
                                    ) : null}
                                  </>
                                )}
                              </FormGroup>
                              <hr />
                              <h4 className="card-title">Rental Report</h4>
                              <FormGroup row>
                                <Label
                                  htmlFor="example-text-input"
                                  className="col-md-2 col-form-label"
                                >
                                  {/* <small style={{ color: "red" }}> * </small> */}
                                  Rental Report details
                                </Label>
                                <Col md={10}>
                                  <Input
                                    className="form-control"
                                    type="textarea"
                                    value={this.state.rental_report_desc}
                                    id="example-text-input"
                                    onChange={(val) => {
                                      this.state.rental_report_desc =
                                        val.target.value;
                                      this.setState({});
                                    }}
                                  />
                                </Col>
                              </FormGroup>
                              <FormGroup row>
                                <Label
                                  htmlFor="example-text-input"
                                  className="col-md-2 col-form-label"
                                >
                                  Rental Report photo
                                </Label>
                                <Col md={4}>
                                  <Input
                                    className="form-control"
                                    type="file"
                                    id="example-text-input"
                                    onChange={(e) => {
                                      this.uploadImage(e, "rental_report");
                                    }}
                                  />
                                </Col>
                                {this.state.image_rent == "" ? null : (
                                  <Col md={4}>
                                    <img
                                      src={this.state.image_rent}
                                      style={{ height: 200, width: 200 }}
                                    />
                                  </Col>
                                )}
                              </FormGroup>
                              <FormGroup row>
                                <Label
                                  htmlFor="example-text-input"
                                  className="col-md-2 col-form-label"
                                >
                                  Rental Report video
                                </Label>
                                <Col md={4}>
                                  <Input
                                    className="form-control"
                                    type="file"
                                    id="example-text-input"
                                    onChange={(val) => {
                                      this.uploadVideo(val, "rental_report");
                                    }}
                                  />
                                </Col>
                                {this.state.uploading_trailer18 ? (
                                  <img
                                    src="https://media.tenor.com/images/7d9cb36e95124fb829ff8f2450c3a567/tenor.gif"
                                    style={{ height: 150, width: 150 }}
                                  />
                                ) : (
                                  <>
                                    {this.state.video_rent != "" ? (
                                      <Col md={4}>
                                        <video
                                          width="100%"
                                          controls
                                          className="rounded-lg"
                                        >
                                          <source
                                            src={this.state.video_rent}
                                            type="video/mp4"
                                          />
                                        </video>
                                      </Col>
                                    ) : null}
                                  </>
                                )}
                              </FormGroup>
                              <hr />
                              <h4 className="card-title">Sexual Harrasement</h4>
                              <FormGroup row>
                                <Label
                                  htmlFor="example-text-input"
                                  className="col-md-2 col-form-label"
                                >
                                  {/* <small style={{ color: "red" }}> * </small> */}
                                  Sexual Harrasement details
                                </Label>
                                <Col md={10}>
                                  <Input
                                    className="form-control"
                                    type="textarea"
                                    value={
                                      this.state.sexual_harrasement_desc
                                    }
                                    id="example-text-input"
                                    onChange={(val) => {
                                      this.state.sexual_harrasement_desc =
                                        val.target.value;
                                      this.setState({});
                                    }}
                                  />
                                </Col>
                              </FormGroup>
                              <FormGroup row>
                                <Label
                                  htmlFor="example-text-input"
                                  className="col-md-2 col-form-label"
                                >
                                  Sexual Harrasement photo
                                </Label>
                                <Col md={4}>
                                  <Input
                                    className="form-control"
                                    type="file"
                                    id="example-text-input"
                                    onChange={(e) => {
                                      this.uploadImage(e, "sexual_harrasement");
                                    }}
                                  />
                                </Col>
                                {this.state.image_sexu ==
                                  "" ? null : (
                                  <Col md={4}>
                                    <img
                                      src={this.state.image_sexu}
                                      style={{ height: 200, width: 200 }}
                                    />
                                  </Col>
                                )}
                              </FormGroup>
                              <FormGroup row>
                                <Label
                                  htmlFor="example-text-input"
                                  className="col-md-2 col-form-label"
                                >
                                  Sexual Harrasement video
                                </Label>
                                <Col md={4}>
                                  <Input
                                    className="form-control"
                                    type="file"
                                    id="example-text-input"
                                    onChange={(val) => {
                                      this.uploadVideo(
                                        val,
                                        "sexual_harrasement"
                                      );
                                    }}
                                  />
                                </Col>
                                {this.state.uploading_trailer19 ? (
                                  <img
                                    src="https://media.tenor.com/images/7d9cb36e95124fb829ff8f2450c3a567/tenor.gif"
                                    style={{ height: 150, width: 150 }}
                                  />
                                ) : (
                                  <>
                                    {this.state.video_sexu !=
                                      "" ? (
                                      <Col md={4}>
                                        <video
                                          width="100%"
                                          controls
                                          className="rounded-lg"
                                        >
                                          <source
                                            src={
                                              this.state
                                                .video_sexu
                                            }
                                            type="video/mp4"
                                          />
                                        </video>
                                      </Col>
                                    ) : null}
                                  </>
                                )}
                              </FormGroup>
                              <hr />
                              <h4 className="card-title">Social Capital</h4>
                              <FormGroup row>
                                <Label
                                  htmlFor="example-text-input"
                                  className="col-md-2 col-form-label"
                                >
                                  {/* <small style={{ color: "red" }}> * </small> */}
                                  Social Capital details
                                </Label>
                                <Col md={10}>
                                  <Input
                                    className="form-control"
                                    type="textarea"
                                    value={this.state.social_capital_desc}
                                    id="example-text-input"
                                    onChange={(val) => {
                                      this.state.social_capital_desc =
                                        val.target.value;
                                      this.setState({});
                                    }}
                                  />
                                </Col>
                              </FormGroup>
                              <FormGroup row>
                                <Label
                                  htmlFor="example-text-input"
                                  className="col-md-2 col-form-label"
                                >
                                  Social Capital photo
                                </Label>
                                <Col md={4}>
                                  <Input
                                    className="form-control"
                                    type="file"
                                    id="example-text-input"
                                    onChange={(e) => {
                                      this.uploadImage(e, "social_capital");
                                    }}
                                  />
                                </Col>
                                {this.state.image_soci ==
                                  "" ? null : (
                                  <Col md={4}>
                                    <img
                                      src={this.state.image_soci}
                                      style={{ height: 200, width: 200 }}
                                    />
                                  </Col>
                                )}
                              </FormGroup>
                              <FormGroup row>
                                <Label
                                  htmlFor="example-text-input"
                                  className="col-md-2 col-form-label"
                                >
                                  Social Capital video
                                </Label>
                                <Col md={4}>
                                  <Input
                                    className="form-control"
                                    type="file"
                                    id="example-text-input"
                                    onChange={(val) => {
                                      this.uploadVideo(val, "social_capital");
                                    }}
                                  />
                                </Col>
                                {this.state.uploading_trailer20 ? (
                                  <img
                                    src="https://media.tenor.com/images/7d9cb36e95124fb829ff8f2450c3a567/tenor.gif"
                                    style={{ height: 150, width: 150 }}
                                  />
                                ) : (
                                  <>
                                    {this.state.video_soci != "" ? (
                                      <Col md={4}>
                                        <video
                                          width="100%"
                                          controls
                                          className="rounded-lg"
                                        >
                                          <source
                                            src={
                                              this.state.video_soci
                                            }
                                            type="video/mp4"
                                          />
                                        </video>
                                      </Col>
                                    ) : null}
                                  </>
                                )}
                              </FormGroup>
                              <hr />
                              <h4 className="card-title">Tax Clearance</h4>
                              <FormGroup row>
                                <Label
                                  htmlFor="example-text-input"
                                  className="col-md-2 col-form-label"
                                >
                                  {/* <small style={{ color: "red" }}> * </small> */}
                                  Tax Clearance details
                                </Label>
                                <Col md={10}>
                                  <Input
                                    className="form-control"
                                    type="textarea"
                                    value={this.state.tax_clearance_desc}
                                    id="example-text-input"
                                    onChange={(val) => {
                                      this.state.tax_clearance_desc =
                                        val.target.value;
                                      this.setState({});
                                    }}
                                  />
                                </Col>
                              </FormGroup>
                              <FormGroup row>
                                <Label
                                  htmlFor="example-text-input"
                                  className="col-md-2 col-form-label"
                                >
                                  Tax Clearance photo
                                </Label>
                                <Col md={4}>
                                  <Input
                                    className="form-control"
                                    type="file"
                                    id="example-text-input"
                                    onChange={(e) => {
                                      this.uploadImage(e, "tax_clearance");
                                    }}
                                  />
                                </Col>
                                {this.state.image_taxc == "" ? null : (
                                  <Col md={4}>
                                    <img
                                      src={this.state.image_taxc}
                                      style={{ height: 200, width: 200 }}
                                    />
                                  </Col>
                                )}
                              </FormGroup>
                              <FormGroup row>
                                <Label
                                  htmlFor="example-text-input"
                                  className="col-md-2 col-form-label"
                                >
                                  Tax Clearance video
                                </Label>
                                <Col md={4}>
                                  <Input
                                    className="form-control"
                                    type="file"
                                    id="example-text-input"
                                    onChange={(val) => {
                                      this.uploadVideo(val, "tax_clearance");
                                    }}
                                  />
                                </Col>
                                {this.state.uploading_trailer21 ? (
                                  <img
                                    src="https://media.tenor.com/images/7d9cb36e95124fb829ff8f2450c3a567/tenor.gif"
                                    style={{ height: 150, width: 150 }}
                                  />
                                ) : (
                                  <>
                                    {this.state.video_taxc != "" ? (
                                      <Col md={4}>
                                        <video
                                          width="100%"
                                          controls
                                          className="rounded-lg"
                                        >
                                          <source
                                            src={this.state.video_taxc}
                                            type="video/mp4"
                                          />
                                        </video>
                                      </Col>
                                    ) : null}
                                  </>
                                )}
                              </FormGroup>
                              <hr />
                              <h4 className="card-title">
                                Tax compliance Report
                              </h4>
                              <FormGroup row>
                                <Label
                                  htmlFor="example-text-input"
                                  className="col-md-2 col-form-label"
                                >
                                  Tax compliance Report details
                                </Label>
                                <Col md={10}>
                                  <Input
                                    className="form-control"
                                    type="textarea"
                                    value={
                                      this.state.tax_compliance_report_desc
                                    }
                                    id="example-text-input"
                                    onChange={(val) => {
                                      this.state.tax_compliance_report_desc =
                                        val.target.value;
                                      this.setState({});
                                    }}
                                  />
                                </Col>
                              </FormGroup>
                              <FormGroup row>
                                <Label
                                  htmlFor="example-text-input"
                                  className="col-md-2 col-form-label"
                                >
                                  Tax compliance Report photo
                                </Label>
                                <Col md={4}>
                                  <Input
                                    className="form-control"
                                    type="file"
                                    id="example-text-input"
                                    onChange={(e) => {
                                      this.uploadImage(
                                        e,
                                        "tax_compliance_report"
                                      );
                                    }}
                                  />
                                </Col>
                                {this.state.image_taxco ==
                                  "" ? null : (
                                  <Col md={4}>
                                    <img
                                      src={
                                        this.state.image_taxco
                                      }
                                      style={{ height: 200, width: 200 }}
                                    />
                                  </Col>
                                )}
                              </FormGroup>
                              <FormGroup row>
                                <Label
                                  htmlFor="example-text-input"
                                  className="col-md-2 col-form-label"
                                >
                                  Tax compliance Report video
                                </Label>
                                <Col md={4}>
                                  <Input
                                    className="form-control"
                                    type="file"
                                    id="example-text-input"
                                    onChange={(val) => {
                                      this.uploadVideo(
                                        val,
                                        "tax_compliance_report"
                                      );
                                    }}
                                  />
                                </Col>
                                {this.state.uploading_trailer22 ? (
                                  <img
                                    src="https://media.tenor.com/images/7d9cb36e95124fb829ff8f2450c3a567/tenor.gif"
                                    style={{ height: 150, width: 150 }}
                                  />
                                ) : (
                                  <>
                                    {this.state.video_taxco !=
                                      "" ? (
                                      <Col md={4}>
                                        <video
                                          width="100%"
                                          controls
                                          className="rounded-lg"
                                        >
                                          <source
                                            src={
                                              this.state
                                                .video_taxco
                                            }
                                            type="video/mp4"
                                          />
                                        </video>
                                      </Col>
                                    ) : null}
                                  </>
                                )}
                              </FormGroup>
                              <hr />
                              <h4 className="card-title">Terrorism</h4>
                              <FormGroup row>
                                <Label
                                  htmlFor="example-text-input"
                                  className="col-md-2 col-form-label"
                                >
                                  {/* <small style={{ color: "red" }}> * </small> */}
                                  Terrorism details
                                </Label>
                                <Col md={10}>
                                  <Input
                                    className="form-control"
                                    type="textarea"
                                    value={this.state.terrorism_report_desc}
                                    id="example-text-input"
                                    onChange={(val) => {
                                      this.state.terrorism_report_desc =
                                        val.target.value;
                                      this.setState({});
                                    }}
                                  />
                                </Col>
                              </FormGroup>
                              <FormGroup row>
                                <Label
                                  htmlFor="example-text-input"
                                  className="col-md-2 col-form-label"
                                >
                                  Terrorism photo
                                </Label>
                                <Col md={4}>
                                  <Input
                                    className="form-control"
                                    type="file"
                                    id="example-text-input"
                                    onChange={(e) => {
                                      this.uploadImage(e, "terrorism_report");
                                    }}
                                  />
                                </Col>
                                {this.state.image_terr ==
                                  "" ? null : (
                                  <Col md={4}>
                                    <img
                                      src={this.state.image_terr}
                                      style={{ height: 200, width: 200 }}
                                    />
                                  </Col>
                                )}
                              </FormGroup>
                              <FormGroup row>
                                <Label
                                  htmlFor="example-text-input"
                                  className="col-md-2 col-form-label"
                                >
                                  Terrorism video
                                </Label>
                                <Col md={4}>
                                  <Input
                                    className="form-control"
                                    type="file"
                                    id="example-text-input"
                                    onChange={(val) => {
                                      this.uploadVideo(val, "terrorism_report");
                                    }}
                                  />
                                </Col>
                                {this.state.uploading_trailer23 ? (
                                  <img
                                    src="https://media.tenor.com/images/7d9cb36e95124fb829ff8f2450c3a567/tenor.gif"
                                    style={{ height: 150, width: 150 }}
                                  />
                                ) : (
                                  <>
                                    {this.state.video_terr != "" ? (
                                      <Col md={4}>
                                        <video
                                          width="100%"
                                          controls
                                          className="rounded-lg"
                                        >
                                          <source
                                            src={
                                              this.state.video_terr
                                            }
                                            type="video/mp4"
                                          />
                                        </video>
                                      </Col>
                                    ) : null}
                                  </>
                                )}
                              </FormGroup>
                              <hr />
                              <h4 className="card-title">Traffic offender </h4>
                              <FormGroup row>
                                <Label
                                  htmlFor="example-text-input"
                                  className="col-md-2 col-form-label"
                                >
                                  {/* <small style={{ color: "red" }}> * </small> */}
                                  Traffic offender details
                                </Label>
                                <Col md={10}>
                                  <Input
                                    className="form-control"
                                    type="textarea"
                                    value={
                                      this.state.traffic_offender_report_desc
                                    }
                                    id="example-text-input"
                                    onChange={(val) => {
                                      this.state.traffic_offender_report_desc =
                                        val.target.value;
                                      this.setState({});
                                    }}
                                  />
                                </Col>
                              </FormGroup>
                              <FormGroup row>
                                <Label
                                  htmlFor="example-text-input"
                                  className="col-md-2 col-form-label"
                                >
                                  Traffic offender photo
                                </Label>
                                <Col md={4}>
                                  <Input
                                    className="form-control"
                                    type="file"
                                    id="example-text-input"
                                    onChange={(e) => {
                                      this.uploadImage(
                                        e,
                                        "traffic_offender_report"
                                      );
                                    }}
                                  />
                                </Col>
                                {this.state.image_traf ==
                                  "" ? null : (
                                  <Col md={4}>
                                    <img
                                      src={
                                        this.state.image_traf
                                      }
                                      style={{ height: 200, width: 200 }}
                                    />
                                  </Col>
                                )}
                              </FormGroup>
                              <FormGroup row>
                                <Label
                                  htmlFor="example-text-input"
                                  className="col-md-2 col-form-label"
                                >
                                  Traffic offender video
                                </Label>
                                <Col md={4}>
                                  <Input
                                    className="form-control"
                                    type="file"
                                    id="example-text-input"
                                    onChange={(val) => {
                                      this.uploadVideo(
                                        val,
                                        "traffic_offender_report"
                                      );
                                    }}
                                  />
                                </Col>
                                {this.state.uploading_trailer24 ? (
                                  <img
                                    src="https://media.tenor.com/images/7d9cb36e95124fb829ff8f2450c3a567/tenor.gif"
                                    style={{ height: 150, width: 150 }}
                                  />
                                ) : (
                                  <>
                                    {this.state.video_traf !=
                                      "" ? (
                                      <Col md={4}>
                                        <video
                                          width="100%"
                                          controls
                                          className="rounded-lg"
                                        >
                                          <source
                                            src={
                                              this.state
                                                .video_traf
                                            }
                                            type="video/mp4"
                                          />
                                        </video>
                                      </Col>
                                    ) : null}
                                  </>
                                )}
                              </FormGroup>
                              <hr />
                              <h4 className="card-title">Utilities Report</h4>
                              <FormGroup row>
                                <Label
                                  htmlFor="example-text-input"
                                  className="col-md-2 col-form-label"
                                >
                                  {/* <small style={{ color: "red" }}> * </small> */}
                                  Utilities Report details
                                </Label>
                                <Col md={10}>
                                  <Input
                                    className="form-control"
                                    type="textarea"
                                    value={this.state.utilities_report_desc}
                                    id="example-text-input"
                                    onChange={(val) => {
                                      this.state.utilities_report_desc =
                                        val.target.value;
                                      this.setState({});
                                    }}
                                  />
                                </Col>
                              </FormGroup>
                              <FormGroup row>
                                <Label
                                  htmlFor="example-text-input"
                                  className="col-md-2 col-form-label"
                                >
                                  Utilities Report photo
                                </Label>
                                <Col md={4}>
                                  <Input
                                    className="form-control"
                                    type="file"
                                    id="example-text-input"
                                    onChange={(e) => {
                                      this.uploadImage(e, "utilities_report");
                                    }}
                                  />
                                </Col>
                                {this.state.image_util ==
                                  "" ? null : (
                                  <Col md={4}>
                                    <img
                                      src={this.state.image_util}
                                      style={{ height: 200, width: 200 }}
                                    />
                                  </Col>
                                )}
                              </FormGroup>
                              <FormGroup row>
                                <Label
                                  htmlFor="example-text-input"
                                  className="col-md-2 col-form-label"
                                >
                                  Utilities Report video
                                </Label>
                                <Col md={4}>
                                  <Input
                                    className="form-control"
                                    type="file"
                                    id="example-text-input"
                                    onChange={(val) => {
                                      this.uploadVideo(val, "utilities_report");
                                    }}
                                  />
                                </Col>
                                {this.state.uploading_trailer25 ? (
                                  <img
                                    src="https://media.tenor.com/images/7d9cb36e95124fb829ff8f2450c3a567/tenor.gif"
                                    style={{ height: 150, width: 150 }}
                                  />
                                ) : (
                                  <>
                                    {this.state.video_util != "" ? (
                                      <Col md={4}>
                                        <video
                                          width="100%"
                                          controls
                                          className="rounded-lg"
                                        >
                                          <source
                                            src={
                                              this.state.video_util
                                            }
                                            type="video/mp4"
                                          />
                                        </video>
                                      </Col>
                                    ) : null}
                                  </>
                                )}
                              </FormGroup>
                              <hr />
                              <h4 className="card-title">Vehicle Report</h4>
                              <FormGroup row>
                                <Label
                                  htmlFor="example-text-input"
                                  className="col-md-2 col-form-label"
                                >
                                  {/* <small style={{ color: "red" }}> * </small> */}
                                  Vehicle Report details
                                </Label>
                                <Col md={10}>
                                  <Input
                                    className="form-control"
                                    type="textarea"
                                    value={this.state.vehicle_report_desc}
                                    id="example-text-input"
                                    onChange={(val) => {
                                      this.state.vehicle_report_desc =
                                        val.target.value;
                                      this.setState({});
                                    }}
                                  />
                                </Col>
                              </FormGroup>
                              <FormGroup row>
                                <Label
                                  htmlFor="example-text-input"
                                  className="col-md-2 col-form-label"
                                >
                                  Vehicle Report photo
                                </Label>
                                <Col md={4}>
                                  <Input
                                    className="form-control"
                                    type="file"
                                    id="example-text-input"
                                    onChange={(e) => {
                                      this.uploadImage(e, "vehicle_report");
                                    }}
                                  />
                                </Col>
                                {this.state.image_vehi ==
                                  "" ? null : (
                                  <Col md={4}>
                                    <img
                                      src={this.state.image_vehi}
                                      style={{ height: 200, width: 200 }}
                                    />
                                  </Col>
                                )}
                              </FormGroup>
                              <FormGroup row>
                                <Label
                                  htmlFor="example-text-input"
                                  className="col-md-2 col-form-label"
                                >
                                  Vehicle Report video
                                </Label>
                                <Col md={4}>
                                  <Input
                                    className="form-control"
                                    type="file"
                                    id="example-text-input"
                                    onChange={(val) => {
                                      this.uploadVideo(val, "vehicle_report");
                                    }}
                                  />
                                </Col>
                                {this.state.uploading_trailer26 ? (
                                  <img
                                    src="https://media.tenor.com/images/7d9cb36e95124fb829ff8f2450c3a567/tenor.gif"
                                    style={{ height: 150, width: 150 }}
                                  />
                                ) : (
                                  <>
                                    {this.state.video_vehi != "" ? (
                                      <Col md={4}>
                                        <video
                                          width="100%"
                                          controls
                                          className="rounded-lg"
                                        >
                                          <source
                                            src={
                                              this.state.video_vehi
                                            }
                                            type="video/mp4"
                                          />
                                        </video>
                                      </Col>
                                    ) : null}
                                  </>
                                )}
                              </FormGroup>
                              <hr />
                              <h4 className="card-title">Visa Report</h4>
                              <FormGroup row>
                                <Label
                                  htmlFor="example-text-input"
                                  className="col-md-2 col-form-label"
                                >
                                  {/* <small style={{ color: "red" }}> * </small> */}
                                  Visa Report details
                                </Label>
                                <Col md={10}>
                                  <Input
                                    className="form-control"
                                    type="textarea"
                                    value={this.state.visa_report_desc}
                                    id="example-text-input"
                                    onChange={(val) => {
                                      this.state.visa_report_desc =
                                        val.target.value;
                                      this.setState({});
                                    }}
                                  />
                                </Col>
                              </FormGroup>
                              <FormGroup row>
                                <Label
                                  htmlFor="example-text-input"
                                  className="col-md-2 col-form-label"
                                >
                                  Visa Report photo
                                </Label>
                                <Col md={4}>
                                  <Input
                                    className="form-control"
                                    type="file"
                                    id="example-text-input"
                                    onChange={(e) => {
                                      this.uploadImage(e, "visa_report");
                                    }}
                                  />
                                </Col>
                                {this.state.image_visa == "" ? null : (
                                  <Col md={4}>
                                    <img
                                      src={this.state.image_visa}
                                      style={{ height: 200, width: 200 }}
                                    />
                                  </Col>
                                )}
                              </FormGroup>
                              <FormGroup row>
                                <Label
                                  htmlFor="example-text-input"
                                  className="col-md-2 col-form-label"
                                >
                                  Visa Report video
                                </Label>
                                <Col md={4}>
                                  <Input
                                    className="form-control"
                                    type="file"
                                    id="example-text-input"
                                    onChange={(val) => {
                                      this.uploadVideo(val, "visa_report");
                                    }}
                                  />
                                </Col>
                                {this.state.uploading_trailer27 ? (
                                  <img
                                    src="https://media.tenor.com/images/7d9cb36e95124fb829ff8f2450c3a567/tenor.gif"
                                    style={{ height: 150, width: 150 }}
                                  />
                                ) : (
                                  <>
                                    {this.state.video_visa != "" ? (
                                      <Col md={4}>
                                        <video
                                          width="100%"
                                          controls
                                          className="rounded-lg"
                                        >
                                          <source
                                            src={this.state.video_visa}
                                            type="video/mp4"
                                          />
                                        </video>
                                      </Col>
                                    ) : null}
                                  </>
                                )}
                              </FormGroup>
                              <hr />
                              <h4 className="card-title">Yahoo report</h4>
                              <FormGroup row>
                                <Label
                                  htmlFor="example-text-input"
                                  className="col-md-2 col-form-label"
                                >
                                  {/* <small style={{ color: "red" }}> * </small> */}
                                  Yahoo report details
                                </Label>
                                <Col md={10}>
                                  <Input
                                    className="form-control"
                                    type="textarea"
                                    value={this.state.yahoo_report_desc}
                                    id="example-text-input"
                                    onChange={(val) => {
                                      this.state.yahoo_report_desc =
                                        val.target.value;
                                      this.setState({});
                                    }}
                                  />
                                </Col>
                              </FormGroup>
                              <FormGroup row>
                                <Label
                                  htmlFor="example-text-input"
                                  className="col-md-2 col-form-label"
                                >
                                  Yahoo report photo
                                </Label>
                                <Col md={4}>
                                  <Input
                                    className="form-control"
                                    type="file"
                                    id="example-text-input"
                                    onChange={(e) => {
                                      this.uploadImage(e, "yahoo_report");
                                    }}
                                  />
                                </Col>
                                {this.state.image_yaho == "" ? null : (
                                  <Col md={4}>
                                    <img
                                      src={this.state.image_yaho}
                                      style={{ height: 200, width: 200 }}
                                    />
                                  </Col>
                                )}
                              </FormGroup>
                              <FormGroup row>
                                <Label
                                  htmlFor="example-text-input"
                                  className="col-md-2 col-form-label"
                                >
                                  Yahoo report video
                                </Label>
                                <Col md={4}>
                                  <Input
                                    className="form-control"
                                    type="file"
                                    id="example-text-input"
                                    onChange={(val) => {
                                      this.uploadVideo(val, "yahoo_report");
                                    }}
                                  />
                                </Col>
                                {this.state.uploading_trailer28 ? (
                                  <img
                                    src="https://media.tenor.com/images/7d9cb36e95124fb829ff8f2450c3a567/tenor.gif"
                                    style={{ height: 150, width: 150 }}
                                  />
                                ) : (
                                  <>
                                    {this.state.video_yaho != "" ? (
                                      <Col md={4}>
                                        <video
                                          width="100%"
                                          controls
                                          className="rounded-lg"
                                        >
                                          <source
                                            src={this.state.video_yaho}
                                            type="video/mp4"
                                          />
                                        </video>
                                      </Col>
                                    ) : null}
                                  </>
                                )}
                              </FormGroup>
                              <hr />

                              <h4 className="card-title">
                                Shoplifting/Thief/Burglary Report
                              </h4>
                              <FormGroup row>
                                <Label
                                  htmlFor="example-text-input"
                                  className="col-md-2 col-form-label"
                                >
                                  {/* <small style={{ color: "red" }}> * </small> */}
                                  Shoplifting/Thief/Burglary Report details
                                </Label>
                                <Col md={10}>
                                  <Input
                                    className="form-control"
                                    type="textarea"
                                    value={
                                      this.state.shoplifting_report_desc
                                    }
                                    id="example-text-input"
                                    onChange={(val) => {
                                      this.state.shoplifting_report_desc =
                                        val.target.value;
                                      this.setState({});
                                    }}
                                  />
                                </Col>
                              </FormGroup>
                              <FormGroup row>
                                <Label
                                  htmlFor="example-text-input"
                                  className="col-md-2 col-form-label"
                                >
                                  Shoplifting/Thief/Burglary Report photo
                                </Label>
                                <Col md={4}>
                                  <Input
                                    className="form-control"
                                    type="file"
                                    id="example-text-input"
                                    onChange={(e) => {
                                      this.uploadImage(e, "shoplifting_report");
                                    }}
                                  />
                                </Col>
                                {this.state.image_shop ==
                                  "" ? null : (
                                  <Col md={4}>
                                    <img
                                      src={this.state.image_shop}
                                      style={{ height: 200, width: 200 }}
                                    />
                                  </Col>
                                )}
                              </FormGroup>
                              <FormGroup row>
                                <Label
                                  htmlFor="example-text-input"
                                  className="col-md-2 col-form-label"
                                >
                                  Shoplifting/Thief/Burglary Report video
                                </Label>
                                <Col md={4}>
                                  <Input
                                    className="form-control"
                                    type="file"
                                    id="example-text-input"
                                    onChange={(val) => {
                                      this.uploadVideo(
                                        val,
                                        "shoplifting_report"
                                      );
                                    }}
                                  />
                                </Col>
                                {this.state.uploading_trailer29 ? (
                                  <img
                                    src="https://media.tenor.com/images/7d9cb36e95124fb829ff8f2450c3a567/tenor.gif"
                                    style={{ height: 150, width: 150 }}
                                  />
                                ) : (
                                  <>
                                    {this.state.video_shop !=
                                      "" ? (
                                      <Col md={4}>
                                        <video
                                          width="100%"
                                          controls
                                          className="rounded-lg"
                                        >
                                          <source
                                            src={
                                              this.state
                                                .video_shop
                                            }
                                            type="video/mp4"
                                          />
                                        </video>
                                      </Col>
                                    ) : null}
                                  </>
                                )}
                              </FormGroup>
                              <hr />
                              <h4 className="card-title">Over Invoicing</h4>
                              <FormGroup row>
                                <Label
                                  htmlFor="example-text-input"
                                  className="col-md-2 col-form-label"
                                >
                                  {/* <small style={{ color: "red" }}> * </small> */}
                                  Over Invoicing Report details
                                </Label>
                                <Col md={10}>
                                  <Input
                                    className="form-control"
                                    type="textarea"
                                    value={
                                      this.state.overInvoice_report_desc
                                    }
                                    id="example-text-input"
                                    onChange={(val) => {
                                      this.state.overInvoice_report_desc =
                                        val.target.value;
                                      this.setState({});
                                    }}
                                  />
                                </Col>
                              </FormGroup>
                              <FormGroup row>
                                <Label
                                  htmlFor="example-text-input"
                                  className="col-md-2 col-form-label"
                                >
                                  Over Invoicing Report photo
                                </Label>
                                <Col md={4}>
                                  <Input
                                    className="form-control"
                                    type="file"
                                    id="example-text-input"
                                    onChange={(e) => {
                                      this.uploadImage(e, "overInvoice_report");
                                    }}
                                  />
                                </Col>
                                {this.state.image_over ==
                                  "" ? null : (
                                  <Col md={4}>
                                    <img
                                      src={this.state.image_over}
                                      style={{ height: 200, width: 200 }}
                                    />
                                  </Col>
                                )}
                              </FormGroup>
                              <FormGroup row>
                                <Label
                                  htmlFor="example-text-input"
                                  className="col-md-2 col-form-label"
                                >
                                  Over Invoicing Report video
                                </Label>
                                <Col md={4}>
                                  <Input
                                    className="form-control"
                                    type="file"
                                    id="example-text-input"
                                    onChange={(val) => {
                                      this.uploadVideo(
                                        val,
                                        "overInvoice_report"
                                      );
                                    }}
                                  />
                                </Col>
                                {this.state.uploading_trailer30 ? (
                                  <img
                                    src="https://media.tenor.com/images/7d9cb36e95124fb829ff8f2450c3a567/tenor.gif"
                                    style={{ height: 150, width: 150 }}
                                  />
                                ) : (
                                  <>
                                    {this.state.video_over !=
                                      "" ? (
                                      <Col md={4}>
                                        <video
                                          width="100%"
                                          controls
                                          className="rounded-lg"
                                        >
                                          <source
                                            src={
                                              this.state
                                                .video_over
                                            }
                                            type="video/mp4"
                                          />
                                        </video>
                                      </Col>
                                    ) : null}
                                  </>
                                )}
                              </FormGroup>
                              <hr />
                              <h4 className="card-title">
                                Document Falsification{" "}
                              </h4>
                              <FormGroup row>
                                <Label
                                  htmlFor="example-text-input"
                                  className="col-md-2 col-form-label"
                                >
                                  {/* <small style={{ color: "red" }}> * </small> */}
                                  Document Falsification Report Details
                                </Label>
                                <Col md={10}>
                                  <Input
                                    className="form-control"
                                    type="textarea"
                                    value={
                                      this.state.falsification_report_desc
                                    }
                                    id="example-text-input"
                                    onChange={(val) => {
                                      this.state.falsification_report_desc =
                                        val.target.value;
                                      this.setState({});
                                    }}
                                  />
                                </Col>
                              </FormGroup>
                              <FormGroup row>
                                <Label
                                  htmlFor="example-text-input"
                                  className="col-md-2 col-form-label"
                                >
                                  Document Falsification Report photo
                                </Label>
                                <Col md={4}>
                                  <Input
                                    className="form-control"
                                    type="file"
                                    id="example-text-input"
                                    onChange={(e) => {
                                      this.uploadImage(
                                        e,
                                        "falsification_report"
                                      );
                                    }}
                                  />
                                </Col>
                                {this.state.image_fals ==
                                  "" ? null : (
                                  <Col md={4}>
                                    <img
                                      src={
                                        this.state.image_fals
                                      }
                                      style={{ height: 200, width: 200 }}
                                    />
                                  </Col>
                                )}
                              </FormGroup>
                              <FormGroup row>
                                <Label
                                  htmlFor="example-text-input"
                                  className="col-md-2 col-form-label"
                                >
                                  Document Falsification Report video
                                </Label>
                                <Col md={4}>
                                  <Input
                                    className="form-control"
                                    type="file"
                                    id="example-text-input"
                                    onChange={(val) => {
                                      this.uploadVideo(
                                        val,
                                        "falsification_report"
                                      );
                                    }}
                                  />
                                </Col>
                                {this.state.uploading_trailer31 ? (
                                  <img
                                    src="https://media.tenor.com/images/7d9cb36e95124fb829ff8f2450c3a567/tenor.gif"
                                    style={{ height: 150, width: 150 }}
                                  />
                                ) : (
                                  <>
                                    {this.state.video_fals !=
                                      "" ? (
                                      <Col md={4}>
                                        <video
                                          width="100%"
                                          controls
                                          className="rounded-lg"
                                        >
                                          <source
                                            src={
                                              this.state
                                                .video_fals
                                            }
                                            type="video/mp4"
                                          />
                                        </video>
                                      </Col>
                                    ) : null}
                                  </>
                                )}
                              </FormGroup>
                              <hr />
                            </Form>
                          </div>
                        </TabPane>
                        <TabPane tabId={4}>
                          <div className="row justify-content-center">
                            <Col lg="6">
                              <div className="text-center">
                                <div className="mb-4">
                                  <i className="mdi mdi-check-circle-outline text-success display-4"></i>
                                </div>
                                <div>
                                  <h5>Confirm Detail</h5>
                                  <p className="text-muted">
                                    Report Updated Successfully
                                  </p>
                                </div>
                              </div>
                            </Col>
                          </div>
                        </TabPane>
                      </TabContent>
                      {this.state.alert ? (
                        <UncontrolledAlert
                          color={this.state.type}
                          className="alert-dismissible fade show"
                          role="alert"
                          onClick={() => this.setState({ alert: false })}
                        >
                          {this.state.type == "warning" ? (
                            <i className="mdi mdi-alert-outline mr-2"></i>
                          ) : this.state.type == "success" ? (
                            <i className="mdi mdi-check-all mr-2"></i>
                          ) : this.state.type == "danger" ? (
                            <i className="mdi mdi-block-helper mr-2"></i>
                          ) : null}
                          {this.state.message}
                        </UncontrolledAlert>
                      ) : null}
                      <ul className="pager wizard twitter-bs-wizard-pager-link">
                        <li
                          className={
                            this.state.activeTabProgress === 1
                              ? "previous disabled"
                              : "previous"
                          }
                        >
                          <Link
                            to="#"
                            onClick={() => {
                              this.toggleTabProgress(
                                this.state.activeTabProgress - 1
                              );
                            }}
                          >
                            Previous
                          </Link>
                          {" "}
                          <button onClick={this.handleClick}
                            style={{ display: 'inline-block', padding: '0.47rem 0.75rem', backgroundColor: '#5664d2', color: '#fff', borderRadius: '0.25rem' }}
                          >Save</button>
                        </li>
                        <li
                          className={
                            this.state.activeTabProgress === 4
                              ? "next disabled"
                              : "next"
                          }
                        >
                          <Link
                            to="#"
                            onClick={() => {
                              this.toggleTabProgress(
                                this.state.activeTabProgress + 1
                              );
                            }}
                          >
                            Next
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            {/* <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Name:
                      </Label>
                      <Col md={4}>
                        <Input
                          className="form-control"
                          type="text"
                          value={this.state.name}
                          id="example-text-input"
                          onChange={(val) => {
                            this.setState({ name: val.target.value });
                          }}
                        />
                      </Col>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Bank A/c:
                      </Label>
                      <Col md={4}>
                        <Input
                          className="form-control"
                          type="text"
                          value={this.state.bank_ac}
                          id="example-text-input"
                          onChange={(val) => {
                            this.setState({ bank_ac: val.target.value });
                          }}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        BVN:
                      </Label>
                      <Col md={4}>
                        <Input
                          className="form-control"
                          type="text"
                          value={this.state.bvn}
                          id="example-text-input"
                          onChange={(val) => {
                            this.setState({ bvn: val.target.value });
                          }}
                        />
                      </Col>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Cert:
                      </Label>
                      <Col md={4}>
                        <Input
                          className="form-control"
                          type="text"
                          value={this.state.cert}
                          id="example-text-input"
                          onChange={(val) => {
                            this.setState({ cert: val.target.value });
                          }}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        DOB:
                      </Label>
                      <Col md={4}>
                        <Input
                          className="form-control"
                          type="text"
                          value={this.state.dob}
                          id="example-text-input"
                          onChange={(val) => {
                            this.setState({ dob: val.target.value });
                          }}
                        />
                      </Col>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Driver license:
                      </Label>
                      <Col md={4}>
                        <Input
                          className="form-control"
                          type="text"
                          value={this.state.driver_license}
                          id="example-text-input"
                          onChange={(val) => {
                            this.setState({ driver_license: val.target.value });
                          }}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Email
                      </Label>
                      <Col md={4}>
                        <Input
                          className="form-control"
                          type="text"
                          value={this.state.email}
                          id="example-text-input"
                          onChange={(val) => {
                            this.setState({ email: val.target.value });
                          }}
                        />
                      </Col>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Employee ID:
                      </Label>
                      <Col md={4}>
                        <Input
                          className="form-control"
                          type="text"
                          value={this.state.emp_id}
                          id="example-text-input"
                          onChange={(val) => {
                            this.setState({ emp_id: val.target.value });
                          }}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Local ID :
                      </Label>
                      <Col md={4}>
                        <Input
                          className="form-control"
                          type="text"
                          value={this.state.local_id}
                          id="example-text-input"
                          onChange={(val) => {
                            this.setState({ local_id: val.target.value });
                          }}
                        />
                      </Col>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        National ID :
                      </Label>
                      <Col md={4}>
                        <Input
                          className="form-control"
                          type="text"
                          value={this.state.national_id}
                          id="example-text-input"
                          onChange={(val) => {
                            this.setState({ national_id: val.target.value });
                          }}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Passport :
                      </Label>
                      <Col md={4}>
                        <Input
                          className="form-control"
                          type="text"
                          value={this.state.passport}
                          id="example-text-input"
                          onChange={(val) => {
                            this.setState({ passport: val.target.value });
                          }}
                        />
                      </Col>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Payroll :
                      </Label>
                      <Col md={4}>
                        <Input
                          className="form-control"
                          type="text"
                          value={this.state.payroll}
                          id="example-text-input"
                          onChange={(val) => {
                            this.setState({ payroll: val.target.value });
                          }}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Plate Reg :
                      </Label>
                      <Col md={4}>
                        <Input
                          className="form-control"
                          type="text"
                          value={this.state.plate_reg}
                          id="example-text-input"
                          onChange={(val) => {
                            this.setState({ plate_reg: val.target.value });
                          }}
                        />
                      </Col>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        RC :
                      </Label>
                      <Col md={4}>
                        <Input
                          className="form-control"
                          type="text"
                          value={this.state.rc}
                          id="example-text-input"
                          onChange={(val) => {
                            this.setState({ rc: val.target.value });
                          }}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Social Media :
                      </Label>
                      <Col md={4}>
                        <Input
                          className="form-control"
                          type="text"
                          value={this.state.social_media}
                          id="example-text-input"
                          onChange={(val) => {
                            this.setState({ social_media: val.target.value });
                          }}
                        />
                      </Col>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        State ID :
                      </Label>
                      <Col md={4}>
                        <Input
                          className="form-control"
                          type="text"
                          value={this.state.state_id}
                          id="example-text-input"
                          onChange={(val) => {
                            this.setState({ state_id: val.target.value });
                          }}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Student Id :
                      </Label>
                      <Col md={4}>
                        <Input
                          className="form-control"
                          type="text"
                          value={this.state.student_id}
                          id="example-text-input"
                          onChange={(val) => {
                            this.setState({ student_id: val.target.value });
                          }}
                        />
                      </Col>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Tel :
                      </Label>
                      <Col md={4}>
                        <Input
                          className="form-control"
                          type="text"
                          value={this.state.tel}
                          id="example-text-input"
                          onChange={(val) => {
                            this.setState({ tel: val.target.value });
                          }}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        TIN :
                      </Label>
                      <Col md={4}>
                        <Input
                          className="form-control"
                          type="text"
                          value={this.state.tin}
                          id="example-text-input"
                          onChange={(val) => {
                            this.setState({ tin: val.target.value });
                          }}
                        />
                      </Col>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Vin :
                      </Label>
                      <Col md={4}>
                        <Input
                          className="form-control"
                          type="text"
                          value={this.state.vin}
                          id="example-text-input"
                          onChange={(val) => {
                            this.setState({ vin: val.target.value });
                          }}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Fingerprints :
                      </Label>
                      <Col md={4}>
                        <Input
                          className="form-control"
                          type="text"
                          value={this.state.fingerprints}
                          id="example-text-input"
                          onChange={(val) => {
                            this.setState({ fingerprints: val.target.value });
                          }}
                        />
                      </Col>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Voter reg :
                      </Label>
                      <Col md={4}>
                        <Input
                          className="form-control"
                          type="text"
                          value={this.state.voter_reg}
                          id="example-text-input"
                          onChange={(val) => {
                            this.setState({ voter_reg: val.target.value });
                          }}
                        />
                      </Col>
                    </FormGroup>
                    <hr />
                    <h4 className="card-title">Upload Image</h4>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Image
                      </Label>
                      <Col md={4}>
                        <Input
                          className="form-control"
                          type="file"
                          id="example-text-input"
                          onChange={(e) => {
                            this.uploadImage(e, "image");
                          }}
                        />
                      </Col>
                      {this.state.image == "" ? null : (
                        <Col md={4}>
                          <img
                            src={this.state.image}
                            style={{ height: 200, width: 200 }}
                          />
                        </Col>
                      )}
                    </FormGroup>

                    <hr />
                    <h4 className="card-title">419 Report</h4>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        419 Report details
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="textarea"
                          value={this.state.report_419.details}
                          id="example-text-input"
                          onChange={(val) => {
                            this.state.report_419.details = val.target.value;
                            this.setState({});
                          }}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        419 Report photo
                      </Label>
                      <Col md={4}>
                        <Input
                          className="form-control"
                          type="file"
                          id="example-text-input"
                          onChange={(e) => {
                            this.uploadImage(e, "report_419");
                          }}
                        />
                      </Col>
                      {this.state.report_419.image == "" ? null : (
                        <Col md={4}>
                          <img
                            src={this.state.report_419.image}
                            style={{ height: 200, width: 200 }}
                          />
                        </Col>
                      )}
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        419 Report video
                      </Label>
                      <Col md={4}>
                        <Input
                          className="form-control"
                          type="file"
                          id="example-text-input"
                          onChange={(val) => {}}
                        />
                      </Col>
                    </FormGroup>
                    <hr />
                    <h4 className="card-title">Academic Report</h4>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Academic Report details
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="textarea"
                          value={this.state.academic_report.details}
                          id="example-text-input"
                          onChange={(val) => {
                            this.state.academic_report.details =
                              val.target.value;
                            this.setState({});
                          }}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Academic Report photo
                      </Label>
                      <Col md={4}>
                        <Input
                          className="form-control"
                          type="file"
                          id="example-text-input"
                          onChange={(e) => {
                            this.uploadImage(e, "academic_report");
                          }}
                        />
                      </Col>
                      {this.state.academic_report.image == "" ? null : (
                        <Col md={4}>
                          <img
                            src={this.state.academic_report.image}
                            style={{ height: 200, width: 200 }}
                          />
                        </Col>
                      )}
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Academic Report video
                      </Label>
                      <Col md={4}>
                        <Input
                          className="form-control"
                          type="file"
                          id="example-text-input"
                          onChange={(val) => {}}
                        />
                      </Col>
                    </FormGroup>
                    <hr />
                    <h4 className="card-title">Behavioral Report</h4>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Behavioral Report details
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="textarea"
                          value={this.state.behavioral_report.details}
                          id="example-text-input"
                          onChange={(val) => {
                            this.state.behavioral_report.details =
                              val.target.value;
                            this.setState({});
                          }}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Behavioral Report photo
                      </Label>
                      <Col md={4}>
                        <Input
                          className="form-control"
                          type="file"
                          id="example-text-input"
                          onChange={(e) => {
                            this.uploadImage(e, "behavioral_report");
                          }}
                        />
                      </Col>
                      {this.state.behavioral_report.image == "" ? null : (
                        <Col md={4}>
                          <img
                            src={this.state.behavioral_report.image}
                            style={{ height: 200, width: 200 }}
                          />
                        </Col>
                      )}
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Behavioral Report video
                      </Label>
                      <Col md={4}>
                        <Input
                          className="form-control"
                          type="file"
                          id="example-text-input"
                          onChange={(val) => {}}
                        />
                      </Col>
                    </FormGroup>
                    <hr />
                    <h4 className="card-title">Child Abuse</h4>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Child Abuse details
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="textarea"
                          value={this.state.child_abuse.details}
                          id="example-text-input"
                          onChange={(val) => {
                            this.state.child_abuse.details = val.target.value;
                            this.setState({});
                          }}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Child Abuse photo
                      </Label>
                      <Col md={4}>
                        <Input
                          className="form-control"
                          type="file"
                          id="example-text-input"
                          onChange={(e) => {
                            this.uploadImage(e, "child_abuse");
                          }}
                        />
                      </Col>
                      {this.state.child_abuse.image == "" ? null : (
                        <Col md={4}>
                          <img
                            src={this.state.child_abuse.image}
                            style={{ height: 200, width: 200 }}
                          />
                        </Col>
                      )}
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Child Abuse video
                      </Label>
                      <Col md={4}>
                        <Input
                          className="form-control"
                          type="file"
                          id="example-text-input"
                          onChange={(val) => {}}
                        />
                      </Col>
                    </FormGroup>
                    <hr />
                    <h4 className="card-title">Credit Report</h4>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Credit Report details
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="textarea"
                          value={this.state.credit_report.details}
                          id="example-text-input"
                          onChange={(val) => {
                            this.state.credit_report.details = val.target.value;
                            this.setState({});
                          }}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Credit Report photo
                      </Label>
                      <Col md={4}>
                        <Input
                          className="form-control"
                          type="file"
                          id="example-text-input"
                          onChange={(e) => {
                            this.uploadImage(e, "credit_report");
                          }}
                        />
                      </Col>
                      {this.state.credit_report.image == "" ? null : (
                        <Col md={4}>
                          <img
                            src={this.state.credit_report.image}
                            style={{ height: 200, width: 200 }}
                          />
                        </Col>
                      )}
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Credit Report video
                      </Label>
                      <Col md={4}>
                        <Input
                          className="form-control"
                          type="file"
                          id="example-text-input"
                          onChange={(val) => {}}
                        />
                      </Col>
                    </FormGroup>
                    <hr />
                    <h4 className="card-title">Criminal Report</h4>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Criminal Report details
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="textarea"
                          value={this.state.criminal_report.details}
                          id="example-text-input"
                          onChange={(val) => {
                            this.state.criminal_report.details =
                              val.target.value;
                            this.setState({});
                          }}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Criminal Report photo
                      </Label>
                      <Col md={4}>
                        <Input
                          className="form-control"
                          type="file"
                          id="example-text-input"
                          onChange={(e) => {
                            this.uploadImage(e, "criminal_report");
                          }}
                        />
                      </Col>
                      {this.state.criminal_report.image == "" ? null : (
                        <Col md={4}>
                          <img
                            src={this.state.criminal_report.image}
                            style={{ height: 200, width: 200 }}
                          />
                        </Col>
                      )}
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Criminal Report video
                      </Label>
                      <Col md={4}>
                        <Input
                          className="form-control"
                          type="file"
                          id="example-text-input"
                          onChange={(val) => {}}
                        />
                      </Col>
                    </FormGroup>
                    <hr />
                    <h4 className="card-title">Death Report</h4>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Death Report details
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="textarea"
                          value={this.state.death_report.details}
                          id="example-text-input"
                          onChange={(val) => {
                            this.state.death_report.details = val.target.value;
                            this.setState({});
                          }}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Death Report photo
                      </Label>
                      <Col md={4}>
                        <Input
                          className="form-control"
                          type="file"
                          id="example-text-input"
                          onChange={(e) => {
                            this.uploadImage(e, "death_report");
                          }}
                        />
                      </Col>
                      {this.state.death_report.image == "" ? null : (
                        <Col md={4}>
                          <img
                            src={this.state.death_report.image}
                            style={{ height: 200, width: 200 }}
                          />
                        </Col>
                      )}
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Death Report video
                      </Label>
                      <Col md={4}>
                        <Input
                          className="form-control"
                          type="file"
                          id="example-text-input"
                          onChange={(val) => {}}
                        />
                      </Col>
                    </FormGroup>
                    <hr />
                    <h4 className="card-title">Domestic violent</h4>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Domestic violent details
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="textarea"
                          value={this.state.domestic_violent.details}
                          id="example-text-input"
                          onChange={(val) => {
                            this.state.domestic_violent.details =
                              val.target.value;
                            this.setState({});
                          }}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Domestic violent photo
                      </Label>
                      <Col md={4}>
                        <Input
                          className="form-control"
                          type="file"
                          id="example-text-input"
                          onChange={(e) => {
                            this.uploadImage(e, "domestic_violent");
                          }}
                        />
                      </Col>
                      {this.state.domestic_violent.image == "" ? null : (
                        <Col md={4}>
                          <img
                            src={this.state.domestic_violent.image}
                            style={{ height: 200, width: 200 }}
                          />
                        </Col>
                      )}
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Domestic violent video
                      </Label>
                      <Col md={4}>
                        <Input
                          className="form-control"
                          type="file"
                          id="example-text-input"
                          onChange={(val) => {}}
                        />
                      </Col>
                    </FormGroup>
                    <hr />
                    <h4 className="card-title">Fingerprint Report</h4>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Fingerprint Report details
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="textarea"
                          value={this.state.fingerprint_report.details}
                          id="example-text-input"
                          onChange={(val) => {
                            this.state.fingerprint_report.details =
                              val.target.value;
                            this.setState({});
                          }}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Fingerprint Report photo
                      </Label>
                      <Col md={4}>
                        <Input
                          className="form-control"
                          type="file"
                          id="example-text-input"
                          onChange={(e) => {
                            this.uploadImage(e, "fingerprint_report");
                          }}
                        />
                      </Col>
                      {this.state.fingerprint_report.image == "" ? null : (
                        <Col md={4}>
                          <img
                            src={this.state.fingerprint_report.image}
                            style={{ height: 200, width: 200 }}
                          />
                        </Col>
                      )}
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Fingerprint Report video
                      </Label>
                      <Col md={4}>
                        <Input
                          className="form-control"
                          type="file"
                          id="example-text-input"
                          onChange={(val) => {}}
                        />
                      </Col>
                    </FormGroup>
                    <hr />
                    <h4 className="card-title">Fraud Alerts</h4>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Fraud Alerts details
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="textarea"
                          value={this.state.fraud_alerts.details}
                          id="example-text-input"
                          onChange={(val) => {
                            this.state.fraud_alerts.details = val.target.value;
                            this.setState({});
                          }}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Fraud Alerts photo
                      </Label>
                      <Col md={4}>
                        <Input
                          className="form-control"
                          type="file"
                          id="example-text-input"
                          onChange={(e) => {
                            this.uploadImage(e, "fraud_alerts");
                          }}
                        />
                      </Col>
                      {this.state.fraud_alerts.image == "" ? null : (
                        <Col md={4}>
                          <img
                            src={this.state.fraud_alerts.image}
                            style={{ height: 200, width: 200 }}
                          />
                        </Col>
                      )}
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Fraud Alerts video
                      </Label>
                      <Col md={4}>
                        <Input
                          className="form-control"
                          type="file"
                          id="example-text-input"
                          onChange={(val) => {}}
                        />
                      </Col>
                    </FormGroup>
                    <hr />
                    <h4 className="card-title">Ghost worker Report</h4>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Ghost worker Report details
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="textarea"
                          value={this.state.ghost_worker.details}
                          id="example-text-input"
                          onChange={(val) => {
                            this.state.ghost_worker.details = val.target.value;
                            this.setState({});
                          }}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Ghost worker Report photo
                      </Label>
                      <Col md={4}>
                        <Input
                          className="form-control"
                          type="file"
                          id="example-text-input"
                          onChange={(e) => {
                            this.uploadImage(e, "ghost_worker");
                          }}
                        />
                      </Col>
                      {this.state.ghost_worker.image == "" ? null : (
                        <Col md={4}>
                          <img
                            src={this.state.ghost_worker.image}
                            style={{ height: 200, width: 200 }}
                          />
                        </Col>
                      )}
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Ghost worker Report video
                      </Label>
                      <Col md={4}>
                        <Input
                          className="form-control"
                          type="file"
                          id="example-text-input"
                          onChange={(val) => {}}
                        />
                      </Col>
                    </FormGroup>
                    <hr />
                    <h4 className="card-title">Insurance Report</h4>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Insurance Report details
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="textarea"
                          value={this.state.insurance_report.details}
                          id="example-text-input"
                          onChange={(val) => {
                            this.state.insurance_report.details =
                              val.target.value;
                            this.setState({});
                          }}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Insurance Report photo
                      </Label>
                      <Col md={4}>
                        <Input
                          className="form-control"
                          type="file"
                          id="example-text-input"
                          onChange={(e) => {
                            this.uploadImage(e, "insurance_report");
                          }}
                        />
                      </Col>
                      {this.state.insurance_report.image == "" ? null : (
                        <Col md={4}>
                          <img
                            src={this.state.insurance_report.image}
                            style={{ height: 200, width: 200 }}
                          />
                        </Col>
                      )}
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Insurance Report video
                      </Label>
                      <Col md={4}>
                        <Input
                          className="form-control"
                          type="file"
                          id="example-text-input"
                          onChange={(val) => {}}
                        />
                      </Col>
                    </FormGroup>
                    <hr />
                    <h4 className="card-title">Land/property Report</h4>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Land/property Report details
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="textarea"
                          value={this.state.property_report.details}
                          id="example-text-input"
                          onChange={(val) => {
                            this.state.property_report.details =
                              val.target.value;
                            this.setState({});
                          }}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Land/property Report photo
                      </Label>
                      <Col md={4}>
                        <Input
                          className="form-control"
                          type="file"
                          id="example-text-input"
                          onChange={(e) => {
                            this.uploadImage(e, "property_report");
                          }}
                        />
                      </Col>
                      {this.state.property_report.image == "" ? null : (
                        <Col md={4}>
                          <img
                            src={this.state.property_report.image}
                            style={{ height: 200, width: 200 }}
                          />
                        </Col>
                      )}
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Land/property Report video
                      </Label>
                      <Col md={4}>
                        <Input
                          className="form-control"
                          type="file"
                          id="example-text-input"
                          onChange={(val) => {}}
                        />
                      </Col>
                    </FormGroup>
                    <hr />
                    <h4 className="card-title">Medical Report</h4>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Medical Report details
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="textarea"
                          value={this.state.medical_report.details}
                          id="example-text-input"
                          onChange={(val) => {
                            this.state.medical_report.details =
                              val.target.value;
                            this.setState({});
                          }}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Medical Report photo
                      </Label>
                      <Col md={4}>
                        <Input
                          className="form-control"
                          type="file"
                          id="example-text-input"
                          onChange={(e) => {
                            this.uploadImage(e, "medical_report");
                          }}
                        />
                      </Col>
                      {this.state.medical_report.image == "" ? null : (
                        <Col md={4}>
                          <img
                            src={this.state.medical_report.image}
                            style={{ height: 200, width: 200 }}
                          />
                        </Col>
                      )}
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Medical Report video
                      </Label>
                      <Col md={4}>
                        <Input
                          className="form-control"
                          type="file"
                          id="example-text-input"
                          onChange={(val) => {}}
                        />
                      </Col>
                    </FormGroup>
                    <hr />
                    <h4 className="card-title">One Chance</h4>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        One Chance details
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="textarea"
                          value={this.state.one_chance.details}
                          id="example-text-input"
                          onChange={(val) => {
                            this.state.one_chance.details = val.target.value;
                            this.setState({});
                          }}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        One Chance photo
                      </Label>
                      <Col md={4}>
                        <Input
                          className="form-control"
                          type="file"
                          id="example-text-input"
                          onChange={(e) => {
                            this.uploadImage(e, "one_chance");
                          }}
                        />
                      </Col>
                      {this.state.one_chance.image == "" ? null : (
                        <Col md={4}>
                          <img
                            src={this.state.one_chance.image}
                            style={{ height: 200, width: 200 }}
                          />
                        </Col>
                      )}
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        One Chance video
                      </Label>
                      <Col md={4}>
                        <Input
                          className="form-control"
                          type="file"
                          id="example-text-input"
                          onChange={(val) => {}}
                        />
                      </Col>
                    </FormGroup>
                    <hr />
                    <h4 className="card-title">Police Report</h4>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Police Report details
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="textarea"
                          value={this.state.police_report.details}
                          id="example-text-input"
                          onChange={(val) => {
                            this.state.police_report.details = val.target.value;
                            this.setState({});
                          }}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Police Report photo
                      </Label>
                      <Col md={4}>
                        <Input
                          className="form-control"
                          type="file"
                          id="example-text-input"
                          onChange={(e) => {
                            this.uploadImage(e, "police_report");
                          }}
                        />
                      </Col>
                      {this.state.police_report.image == "" ? null : (
                        <Col md={4}>
                          <img
                            src={this.state.police_report.image}
                            style={{ height: 200, width: 200 }}
                          />
                        </Col>
                      )}
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Police Report video
                      </Label>
                      <Col md={4}>
                        <Input
                          className="form-control"
                          type="file"
                          id="example-text-input"
                          onChange={(val) => {}}
                        />
                      </Col>
                    </FormGroup>
                    <hr />
                    <h4 className="card-title">Professional Report</h4>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Professional Report details
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="textarea"
                          value={this.state.professional_report.details}
                          id="example-text-input"
                          onChange={(val) => {
                            this.state.professional_report.details =
                              val.target.value;
                            this.setState({});
                          }}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Professional Report photo
                      </Label>
                      <Col md={4}>
                        <Input
                          className="form-control"
                          type="file"
                          id="example-text-input"
                          onChange={(e) => {
                            this.uploadImage(e, "professional_report");
                          }}
                        />
                      </Col>
                      {this.state.professional_report.image == "" ? null : (
                        <Col md={4}>
                          <img
                            src={this.state.professional_report.image}
                            style={{ height: 200, width: 200 }}
                          />
                        </Col>
                      )}
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Professional Report video
                      </Label>
                      <Col md={4}>
                        <Input
                          className="form-control"
                          type="file"
                          id="example-text-input"
                          onChange={(val) => {}}
                        />
                      </Col>
                    </FormGroup>
                    <hr />
                    <h4 className="card-title">Recommedations</h4>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Recommedations details
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="textarea"
                          value={this.state.recommedations_report.details}
                          id="example-text-input"
                          onChange={(val) => {
                            this.state.recommedations_report.details =
                              val.target.value;
                            this.setState({});
                          }}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Recommedations photo
                      </Label>
                      <Col md={4}>
                        <Input
                          className="form-control"
                          type="file"
                          id="example-text-input"
                          onChange={(e) => {
                            this.uploadImage(e, "recommedations_report");
                          }}
                        />
                      </Col>
                      {this.state.recommedations_report.image == "" ? null : (
                        <Col md={4}>
                          <img
                            src={this.state.recommedations_report.image}
                            style={{ height: 200, width: 200 }}
                          />
                        </Col>
                      )}
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Recommedations video
                      </Label>
                      <Col md={4}>
                        <Input
                          className="form-control"
                          type="file"
                          id="example-text-input"
                          onChange={(val) => {}}
                        />
                      </Col>
                    </FormGroup>
                    <hr />
                    <h4 className="card-title">Rental Report</h4>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Rental Report details
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="textarea"
                          value={this.state.rental_report.details}
                          id="example-text-input"
                          onChange={(val) => {
                            this.state.rental_report.details = val.target.value;
                            this.setState({});
                          }}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Rental Report photo
                      </Label>
                      <Col md={4}>
                        <Input
                          className="form-control"
                          type="file"
                          id="example-text-input"
                          onChange={(e) => {
                            this.uploadImage(e, "rental_report");
                          }}
                        />
                      </Col>
                      {this.state.rental_report.image == "" ? null : (
                        <Col md={4}>
                          <img
                            src={this.state.rental_report.image}
                            style={{ height: 200, width: 200 }}
                          />
                        </Col>
                      )}
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Rental Report video
                      </Label>
                      <Col md={4}>
                        <Input
                          className="form-control"
                          type="file"
                          id="example-text-input"
                          onChange={(val) => {}}
                        />
                      </Col>
                    </FormGroup>
                    <hr />
                    <h4 className="card-title">Sexual Harrasement</h4>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Sexual Harrasement details
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="textarea"
                          value={this.state.sexual_harrasement.details}
                          id="example-text-input"
                          onChange={(val) => {
                            this.state.sexual_harrasement.details =
                              val.target.value;
                            this.setState({});
                          }}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Sexual Harrasement photo
                      </Label>
                      <Col md={4}>
                        <Input
                          className="form-control"
                          type="file"
                          id="example-text-input"
                          onChange={(e) => {
                            this.uploadImage(e, "sexual_harrasement");
                          }}
                        />
                      </Col>
                      {this.state.sexual_harrasement.image == "" ? null : (
                        <Col md={4}>
                          <img
                            src={this.state.sexual_harrasement.image}
                            style={{ height: 200, width: 200 }}
                          />
                        </Col>
                      )}
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Sexual Harrasement video
                      </Label>
                      <Col md={4}>
                        <Input
                          className="form-control"
                          type="file"
                          id="example-text-input"
                          onChange={(val) => {}}
                        />
                      </Col>
                    </FormGroup>
                    <hr />
                    <h4 className="card-title">Social Capital</h4>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Social Capital details
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="textarea"
                          value={this.state.social_capital.details}
                          id="example-text-input"
                          onChange={(val) => {
                            this.state.social_capital.details =
                              val.target.value;
                            this.setState({});
                          }}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Social Capital photo
                      </Label>
                      <Col md={4}>
                        <Input
                          className="form-control"
                          type="file"
                          id="example-text-input"
                          onChange={(e) => {
                            this.uploadImage(e, "social_capital");
                          }}
                        />
                      </Col>
                      {this.state.social_capital.image == "" ? null : (
                        <Col md={4}>
                          <img
                            src={this.state.social_capital.image}
                            style={{ height: 200, width: 200 }}
                          />
                        </Col>
                      )}
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Social Capital video
                      </Label>
                      <Col md={4}>
                        <Input
                          className="form-control"
                          type="file"
                          id="example-text-input"
                          onChange={(val) => {}}
                        />
                      </Col>
                    </FormGroup>
                    <hr />
                    <h4 className="card-title">Tax Clearance</h4>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Tax Clearance details
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="textarea"
                          value={this.state.tax_clearance.details}
                          id="example-text-input"
                          onChange={(val) => {
                            this.state.tax_clearance.details = val.target.value;
                            this.setState({});
                          }}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Tax Clearance photo
                      </Label>
                      <Col md={4}>
                        <Input
                          className="form-control"
                          type="file"
                          id="example-text-input"
                          onChange={(e) => {
                            this.uploadImage(e, "tax_clearance");
                          }}
                        />
                      </Col>
                      {this.state.tax_clearance.image == "" ? null : (
                        <Col md={4}>
                          <img
                            src={this.state.tax_clearance.image}
                            style={{ height: 200, width: 200 }}
                          />
                        </Col>
                      )}
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Tax Clearance video
                      </Label>
                      <Col md={4}>
                        <Input
                          className="form-control"
                          type="file"
                          id="example-text-input"
                          onChange={(val) => {}}
                        />
                      </Col>
                    </FormGroup>
                    <hr />
                    <h4 className="card-title">Tax compliance Report</h4>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Tax compliance Report details
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="textarea"
                          value={this.state.tax_compliance_report.details}
                          id="example-text-input"
                          onChange={(val) => {
                            this.state.tax_compliance_report.details =
                              val.target.value;
                            this.setState({});
                          }}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Tax compliance Report photo
                      </Label>
                      <Col md={4}>
                        <Input
                          className="form-control"
                          type="file"
                          id="example-text-input"
                          onChange={(e) => {
                            this.uploadImage(e, "tax_compliance_report");
                          }}
                        />
                      </Col>
                      {this.state.tax_compliance_report.image == "" ? null : (
                        <Col md={4}>
                          <img
                            src={this.state.tax_compliance_report.image}
                            style={{ height: 200, width: 200 }}
                          />
                        </Col>
                      )}
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Tax compliance Report video
                      </Label>
                      <Col md={4}>
                        <Input
                          className="form-control"
                          type="file"
                          id="example-text-input"
                          onChange={(val) => {}}
                        />
                      </Col>
                    </FormGroup>
                    <hr />
                    <h4 className="card-title">Terrorism</h4>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Terrorism details
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="textarea"
                          value={this.state.terrorism_report.details}
                          id="example-text-input"
                          onChange={(val) => {
                            this.state.terrorism_report.details =
                              val.target.value;
                            this.setState({});
                          }}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Terrorism photo
                      </Label>
                      <Col md={4}>
                        <Input
                          className="form-control"
                          type="file"
                          id="example-text-input"
                          onChange={(e) => {
                            this.uploadImage(e, "terrorism_report");
                          }}
                        />
                      </Col>
                      {this.state.terrorism_report.image == "" ? null : (
                        <Col md={4}>
                          <img
                            src={this.state.terrorism_report.image}
                            style={{ height: 200, width: 200 }}
                          />
                        </Col>
                      )}
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Terrorism video
                      </Label>
                      <Col md={4}>
                        <Input
                          className="form-control"
                          type="file"
                          id="example-text-input"
                          onChange={(val) => {}}
                        />
                      </Col>
                    </FormGroup>
                    <hr />
                    <h4 className="card-title">Traffic offender </h4>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Traffic offender details
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="textarea"
                          value={this.state.traffic_offender_report.details}
                          id="example-text-input"
                          onChange={(val) => {
                            this.state.traffic_offender_report.details =
                              val.target.value;
                            this.setState({});
                          }}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Traffic offender photo
                      </Label>
                      <Col md={4}>
                        <Input
                          className="form-control"
                          type="file"
                          id="example-text-input"
                          onChange={(e) => {
                            this.uploadImage(e, "traffic_offender_report");
                          }}
                        />
                      </Col>
                      {this.state.traffic_offender_report.image == "" ? null : (
                        <Col md={4}>
                          <img
                            src={this.state.traffic_offender_report.image}
                            style={{ height: 200, width: 200 }}
                          />
                        </Col>
                      )}
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Traffic offender video
                      </Label>
                      <Col md={4}>
                        <Input
                          className="form-control"
                          type="file"
                          id="example-text-input"
                          onChange={(val) => {}}
                        />
                      </Col>
                    </FormGroup>
                    <hr />
                    <h4 className="card-title">Utilities Report</h4>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Utilities Report details
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="textarea"
                          value={this.state.utilities_report.details}
                          id="example-text-input"
                          onChange={(val) => {
                            this.state.utilities_report.details =
                              val.target.value;
                            this.setState({});
                          }}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Utilities Report photo
                      </Label>
                      <Col md={4}>
                        <Input
                          className="form-control"
                          type="file"
                          id="example-text-input"
                          onChange={(e) => {
                            this.uploadImage(e, "utilities_report");
                          }}
                        />
                      </Col>
                      {this.state.utilities_report.image == "" ? null : (
                        <Col md={4}>
                          <img
                            src={this.state.utilities_report.image}
                            style={{ height: 200, width: 200 }}
                          />
                        </Col>
                      )}
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Utilities Report video
                      </Label>
                      <Col md={4}>
                        <Input
                          className="form-control"
                          type="file"
                          id="example-text-input"
                          onChange={(val) => {}}
                        />
                      </Col>
                    </FormGroup>
                    <hr />
                    <h4 className="card-title">Vehicle Report</h4>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Vehicle Report details
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="textarea"
                          value={this.state.vehicle_report.details}
                          id="example-text-input"
                          onChange={(val) => {
                            this.state.vehicle_report.details =
                              val.target.value;
                            this.setState({});
                          }}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Vehicle Report photo
                      </Label>
                      <Col md={4}>
                        <Input
                          className="form-control"
                          type="file"
                          id="example-text-input"
                          onChange={(e) => {
                            this.uploadImage(e, "vehicle_report");
                          }}
                        />
                      </Col>
                      {this.state.vehicle_report.image == "" ? null : (
                        <Col md={4}>
                          <img
                            src={this.state.vehicle_report.image}
                            style={{ height: 200, width: 200 }}
                          />
                        </Col>
                      )}
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Vehicle Report video
                      </Label>
                      <Col md={4}>
                        <Input
                          className="form-control"
                          type="file"
                          id="example-text-input"
                          onChange={(val) => {}}
                        />
                      </Col>
                    </FormGroup>
                    <hr />
                    <h4 className="card-title">Visa Report</h4>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Visa Report details
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="textarea"
                          value={this.state.visa_report.details}
                          id="example-text-input"
                          onChange={(val) => {
                            this.state.visa_report.details = val.target.value;
                            this.setState({});
                          }}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Visa Report photo
                      </Label>
                      <Col md={4}>
                        <Input
                          className="form-control"
                          type="file"
                          id="example-text-input"
                          onChange={(e) => {
                            this.uploadImage(e, "visa_report");
                          }}
                        />
                      </Col>
                      {this.state.visa_report.image == "" ? null : (
                        <Col md={4}>
                          <img
                            src={this.state.visa_report.image}
                            style={{ height: 200, width: 200 }}
                          />
                        </Col>
                      )}
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Visa Report video
                      </Label>
                      <Col md={4}>
                        <Input
                          className="form-control"
                          type="file"
                          id="example-text-input"
                          onChange={(val) => {}}
                        />
                      </Col>
                    </FormGroup>
                    <hr />
                    <h4 className="card-title">Yahoo report</h4>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Yahoo report details
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="textarea"
                          value={this.state.yahoo_report.details}
                          id="example-text-input"
                          onChange={(val) => {
                            this.state.yahoo_report.details = val.target.value;
                            this.setState({});
                          }}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Yahoo report photo
                      </Label>
                      <Col md={4}>
                        <Input
                          className="form-control"
                          type="file"
                          id="example-text-input"
                          onChange={(e) => {
                            this.uploadImage(e, "yahoo_report");
                          }}
                        />
                      </Col>
                      {this.state.yahoo_report.image == "" ? null : (
                        <Col md={4}>
                          <img
                            src={this.state.yahoo_report.image}
                            style={{ height: 200, width: 200 }}
                          />
                        </Col>
                      )}
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Yahoo report video
                      </Label>
                      <Col md={4}>
                        <Input
                          className="form-control"
                          type="file"
                          id="example-text-input"
                          onChange={(val) => {}}
                        />
                      </Col>
                    </FormGroup>
                    <hr />
                    {this.state.alert ? (
                      <UncontrolledAlert
                        color={this.state.type}
                        className="alert-dismissible fade show"
                        role="alert"
                      >
                        {this.state.type == "warning" ? (
                          <i className="mdi mdi-alert-outline mr-2"></i>
                        ) : this.state.type == "success" ? (
                          <i className="mdi mdi-check-all mr-2"></i>
                        ) : this.state.type == "danger" ? (
                          <i className="mdi mdi-block-helper mr-2"></i>
                        ) : null}
                        {this.state.message}
                      </UncontrolledAlert>
                    ) : null}
                    <FormGroup className="mb-0">
                      <div className="button-items pt-4">
                        <Button
                          color="primary"
                          type="button"
                          className="waves-effect waves-light mr-1"
                          onClick={() => {
                            this.submit();
                          }}
                        >
                          {this.state.edit_enable ? "Update" : "Submit"}{" "}
                          <i className="ri-arrow-right-line align-middle ml-1"></i>
                        </Button>
                      </div>
                    </FormGroup>
                  </CardBody>
                </Card>
              </Col>
            </Row> */}
            {/* <Row>
              <Col xl={12}>
                <div className="card p-3">
                  <MDBDataTable responsive bordered data={data} />
                </div>
              </Col>
            </Row> */}
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default FormElements;
